import React, { useState, useEffect, useContext } from 'react';
import { Context } from "../../commons/utils/store";
import { Form, Modal, Button } from 'react-bootstrap';
import './modalDocuments.css';
import { Grid, Label } from 'semantic-ui-react';
import { FaDownload, FaSpinner } from 'react-icons/fa'
import pdf from '../../api/informe_PDF_Adquisicion.pdf'
import pdfCertificado from '../../api/Modelo-Certificado-Eficiencia-Energetica.pdf'
import pdfInforme from '../../api/Declaración responsable DNSH.docx'
import PopOver from '../../commons/popOver/popOver'
import { MdAddCircle } from "react-icons/md"
// import S3 from 'react-aws-s3';
import taxoApi from '../../api/taxoApi';
import ModalWarning from '../../commons/modals/modalWarning'
import { HiOutlineTrash } from 'react-icons/hi'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import { useNavigate } from 'react-router-dom';

export default function ModalDocuments({ show, handle, evaluationId, showUpload }) {

    const [ficheroSeleccionado, setFicheroSeleccionado] = useState("")
    const [openUpload, setOpenUpload] = useState(false)
    const [error, setError] = useState(false)
    const navigate = useNavigate();

    const [state, dispatch] = useContext(Context);

    const [documentTypeName, setdocumentTypeName] = useState({})
    const [documentNameType, setdocumentNameType] = useState({})

    const [fields, setFields] = useState([])

    const [errorFile, setErrorFile] = useState(false)
    const handleErrorFile = (click) => setErrorFile(click)

    const [fileType, setFileType] = useState(0)

    const [errorMsg, setErrorMsg] = useState()

    const [overwrite, setOverwrite] = useState(false)

    const [deleteFile, setDeleteFile] = useState(false)
    const [fileToDelete, setFileToDelete] = useState(0)
    const [indexToDelete, setIndexToDelete] = useState(0)

    const [noDocuments, setNoDocuments] = useState(false)

    const handleClose = () => {
        handle(false)
        setOpenUpload(false)
    }

    const handleDiscard = () => {
        handle(false)
        setOpenUpload(false)
    }

    var fieldsAux = []
    var queries = []
    useEffect(() => {
        handleSetDocumentTypes()
        handleSetFields()

    }, [])

    const handleSetDocumentTypes = () => {
        dispatch({ type: "SET_HIDDEN", payload: false });
        taxoApi.getTaxoDocumentTypes().then((response) => {
            let aux = {}
            let aux2 = {}
            response.data.forEach(item => {
                aux[item.name] = item.typeId
                aux2[item.typeId] = item.name
            })

            // setdocumentTypeOptions(queries)
            let dictionary = Object.fromEntries(queries.map(x => [x.name, x.typeId]))
            setdocumentNameType(aux)
            dictionary = Object.fromEntries(queries.map(x => [x.typeId, x.name]))
            setdocumentTypeName(aux2)
            // queries = []
            dispatch({ type: "SET_HIDDEN", payload: true });
        })
    }

    const handleSetFields = () => {
        dispatch({ type: "SET_HIDDEN", payload: false });
        taxoApi.getTaxoDocuments(evaluationId).then((response) => {
            fieldsAux = {}
            response.data.forEach(item => {
                fieldsAux[item.fileId] = item
            })
            setFields(fieldsAux)
            if (Object.keys(fieldsAux).length === 0) {
                setNoDocuments(true)
            }
            else{
                setNoDocuments(false)
            }
            fieldsAux = {}
            dispatch({ type: "SET_HIDDEN", payload: true });
        })
    }

    const handleSubmit = (data) => {
        data.preventDefault()
        handle(false)
        
    }

    const handleChange = (elem) => {
        setFileType(documentNameType[elem.target.value])
    }

    const handleOverwrite = () => {
        addFile()

    }
    const handleShowOw = (v) => {
        setOverwrite(v)
    }


    const handleDeleteClick = (fileId) => {
        setDeleteFile(true)
        setFileToDelete(fileId)
        setIndexToDelete(fileId)
    }

    const handleDeleteFile = () => {
        dispatch({ type: "SET_HIDDEN", payload: false });

        taxoApi.deleteTaxoDocument(fileToDelete, evaluationId).then((response) => {
            const aux = Object.assign({}, fields)
            delete aux[indexToDelete]
            setFields(aux)
            dispatch({ type: "SET_HIDDEN", payload: true });
        })

    }

    const handleShowDelete = (v) => {
        setDeleteFile(v)
    }

    const handleAddFile = () => {
        let fieldList = []
        fieldList = (Array.isArray(fields) ? fields : Object.values(fields))

        let fileAlreadyExists = fieldList.some(item => item.fileName === ficheroSeleccionado.name)

        fileAlreadyExists ? setOverwrite(true) : addFile()

    }

    const addFile = () => {
        if (ficheroSeleccionado !== "") {

            try {
                dispatch({ type: "SET_HIDDEN", payload: false });

                //TODO: tipo 
                taxoApi.getTaxoUploadFile(evaluationId, ficheroSeleccionado, fileType).then((response) => {

                    if (response.data) {
                        let id = response.data.fileId
                        if(fields === undefined || Object.keys(fields).length === 0){
                            handleSetFields();
                        }
                        let items = fields
                        let item = { ...items[id] };
                        // 3. Replace the property you're intested in
                        item = response.data
                        // 4. Put it back into our array. N.B. we *are* mutating the array here, 
                        //    but that's why we made a copy first
                        items[id] = item;

                        setFields(items)
                        setFicheroSeleccionado("")
                    }

                    else {
                        setErrorFile(true)
                        setErrorMsg("No se ha podido subir el fichero")
                        // setErrorMsg(response.error.message) //TODO: el mensaje sale en inglés
                    }
                    dispatch({ type: "SET_HIDDEN", payload: true });
                    setFileType(0)
                })

            }
            catch (err) {
                console.error(err)
            }
            setOpenUpload(false)
        }
        else {
            setError(true)
        }
    }

    const handleDownload = (path) => {
        try {
            // endpoint points to Lambda that generates workbook and presigned URL
            dispatch({ type: "SET_HIDDEN", payload: false });
            taxoApi.getTaxoDownloadFile(path,"",0,navigate).then((response) => {
                if (response.status === '200') {
                    window.location.assign(response.data)
                } else {
                    setErrorFile(true)
                    setErrorMsg("No se ha podido descargar el fichero")
                }
                dispatch({ type: "SET_HIDDEN", payload: true });
            })
        }
        catch (err) {
            console.error(err)
        }
    }

    const handleStopUpload = () => {
        setOpenUpload(false)
        setFicheroSeleccionado("")
    }

    const handlePreUpload = (e) => {
        e.preventDefault()
        setFicheroSeleccionado(e.target.files[0])
    }

    const columns = [
        {
            dataField: 'date',
            text: 'Fecha',
            sort: false
        },

        {
            dataField: 'fileName',
            text: 'Nombre del documento',
            sort: false,
        },
        {
            dataField: 'fileTypeDesc',
            text: 'Tipo de documento',
            sort: false,
            // formatter: (value, row) => (
            //     <span>{documentTypeName[row.fileTypeDesc]}</span>
            // )
        },
        {
            dataField: 'actions',
            text: '',
            sort: false,
            align: 'center',
            style: { width: '6.25rem' },
            formatter: (value, row) => (
                <span>
                    <PopOver title={
                        <FaDownload className='cursorPointer color71' onClick={() => (handleDownload(row.filePath))} />}
                        message={`Descargar documento`} />

                    {showUpload &&
                        <PopOver title={
                            <HiOutlineTrash className='ms-3 cursorPointer color71' onClick={() => (handleDeleteClick(row.fileId, row.fileId))} />}
                            message={`Eliminar documento`} />
                    }
                </span>
            )
        }
    ];

    return (<>
        <ModalWarning header={<span className='section-title-modal'>Error</span>} message={errorMsg} show={errorFile} handle={handleErrorFile} onlyButtonContinue> </ModalWarning>
        <ModalWarning header={<span className='section-title-modal'>Sobreescribir documento</span>} message={"Ya hay un fichero con este nombre, ¿desea sobreescribir el anterior?"} show={overwrite} handleContinue={handleOverwrite} handle={handleShowOw} error={false}></ModalWarning>
        <ModalWarning header={<span className='section-title-modal'>Borrar documento</span>} message={"¿Está seguro de que quiere eliminar el fichero?"} show={deleteFile} handleContinue={handleDeleteFile} handle={handleShowDelete} error={false}></ModalWarning>


        <Modal show={show} onHide={handleDiscard} centered size="lg">
            <Modal.Header className="docsTittle" closeButton>
                <div className='docsCabecera'>
                    <span>Documentación adjunta</span>
                    {showUpload && <label onClick={() => setOpenUpload(true)} className='btn-new upbtn' >
                        <MdAddCircle className='me-1 mb-0-2' />Subir Documento
                    </label>}
                </div>
            </Modal.Header>

            <Modal.Body>

                <Form onSubmit={handleSubmit}>

                    <div /* className="docs" */>
                        {
                            noDocuments ? <div className='mb-2'>No existen documentos </div> :
                                Object.keys(fields).length === 0 ? <></> :
                                    <BootstrapTable responsive
                                        classes='table-basic table-hover table-borderless'
                                        bootstrap4
                                        keyField='fileId'
                                        data={Object.values(fields)}
                                        columns={columns}
                                    // defaultSorted={defaultSorted}
                                    // pagination={pagination} 
                                    />

                        }
                        <Grid>
                            {openUpload &&
                                <Grid.Row className='docsRow'>
                                    <Grid.Column>
                                        {Object.keys(fields).length === 0 && <div> <br></br> </div>}
                                        {/* <Form.Group controlId="formFileSm" className='answer py-2'> */}
                                        <label className='col-3'>Tipo de documento</label>
                                        {/* <Form.Control type="file" className='upload-inputFile mb-2' onChange={(event) => handlePreUpload(event)} /> */}
                                        <Form.Select onChange={handleChange} className='input-form-typeahead2 docsInput docsSelect col-pers' aria-label="Default select example" style={{ marginRight: "1rem" }}>{Object.entries(documentNameType).map(item => (<option>{item[0]}</option>))} 					   </Form.Select>
                                        {/* </Form.Group> */}
                                    </Grid.Column>
                                </Grid.Row>
                            }
                            {openUpload &&
                                <Grid.Row className='docsRow'>
                                    <Grid.Column>
                                        <Form.Group controlId="formFileSm" className='answer py-2'>
                                            <label className='col-3'>Adjuntar documento</label>
                                            <label for="formFileSm" className='fakeInput-document col-6'>{ficheroSeleccionado ? ficheroSeleccionado.name : "Seleccionar documento"}</label>
                                            <Button onClick={() => handleStopUpload()} className='btnX-document col-1'>
                                                <img alt="" src="data:image/svg+xml;base64,PHN2ZyBzdHlsZT0iY29sb3I6IHJnYig4NywgODksIDk4KTsiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2IiBmaWxsPSJjdXJyZW50Q29sb3IiIGNsYXNzPSJiaSBiaS14IiB2aWV3Qm94PSIwIDAgMTYgMTYiPiA8cGF0aCBkPSJNNC42NDYgNC42NDZhLjUuNSAwIDAgMSAuNzA4IDBMOCA3LjI5M2wyLjY0Ni0yLjY0N2EuNS41IDAgMCAxIC43MDguNzA4TDguNzA3IDhsMi42NDcgMi42NDZhLjUuNSAwIDAgMS0uNzA4LjcwOEw4IDguNzA3bC0yLjY0NiAyLjY0N2EuNS41IDAgMCAxLS43MDgtLjcwOEw3LjI5MyA4IDQuNjQ2IDUuMzU0YS41LjUgMCAwIDEgMC0uNzA4eiIgZmlsbD0iIzU3NTk2MiI+PC9wYXRoPiA8L3N2Zz4=" />
                                            </Button>
                                            <Form.Control type="file" className='upload-inputFile mb-2' onChange={(event) => handlePreUpload(event)} />

                                            <Button variant="primary" className="btn btn-primary col-1" onClick={() => handleAddFile()}>Subir</Button>
                                        </Form.Group>
                                    </Grid.Column>
                                </Grid.Row>
                            }

                            <Grid.Row>
                                <Grid.Column textAlign='right'>
                                    <div className='options'>
                                        {/* <Button className='marginRight5px' onClick={handleDiscard} variant="secondary">Salir</Button> */}
                                        {Object.keys(fields).length > 0 &&
                                            <Button /* variant="primary" */ className='btn-new upbtn' type='submit'>Aceptar</Button>
                                        }
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    </>
    )
}