import "./login.css";
import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import { UsuarioAutenticadoContext } from "../../context/authenticatedUser";
import CircularProgress from '@material-ui/core/CircularProgress';
import ojoAbierto from "../../commons/images/open-eye.svg";
import ojoCerrado from "../../commons/images/close-eye.svg";
// import { userAuth } from "../../api/user"
import { Button } from 'react-bootstrap';
import taxoApi from '../../api/taxoApi';
import { Context } from "../../commons/utils/store";

function Login() {
  const [mostrarMensajeTokenCaducado, setMostrarMensajeTokenCaducado] = useState(false);

  if (!mostrarMensajeTokenCaducado && localStorage.getItem("sesion") === "caducada") {
    setMostrarMensajeTokenCaducado(true);
  }

  const [state, dispatch] = useContext(Context);
  const { usuarioAutenticadoChange } = useContext(UsuarioAutenticadoContext);
  const [usuario, setUsuario] = useState();
  const [password, setPassword] = useState();
  const [mostrandoPassword, setMostrandoPassword] = useState(false);
  const [mensajeError, setMensajeError] = useState(null);
  const [cargando, setCargando] = useState(false);
  const history = useNavigate();

  useEffect(() => {
    dispatch({ type: "SET_HIDDEN", payload: true });
  }, state === false);

  const setWithExpiry = (key, value, ttl) => {
    const now = new Date()
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    }
    localStorage.setItem(key, JSON.stringify(item))
  }

  const ejecutarInicioDeSesion = async (e) => {
    setMensajeError(null);

    setCargando(true)
    e.preventDefault();
    /*const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({ user: usuario, pwd: password });*/
    try {
      /* const requestOptions = {
         method: "POST",
         headers: myHeaders,
         redirect: "follow",
         body: raw,
       };
       let response = await fetch(
         "https://12ed8hr3f0.execute-api.eu-west-1.amazonaws.com/user-login",
         requestOptions
       );
       let result = await response.json();
       if (!response.ok) {
         throw result.error;
       }*/
      try {
        let response = await taxoApi.authLogin({ user: usuario, password: password });
        let result = await response;

        if (response.authorizationToken !== undefined && response.authorizationToken !== null) {
          //localStorage.setItem("token-taxo",result.authorizationToken);
          setWithExpiry("token-taxo",result.authorizationToken,5400000)
          localStorage.setItem("sesion","activa");
          localStorage.setItem("id_usuario",result.id);
          localStorage.setItem("desc_usuario",result.name);
          localStorage.setItem("desc_entidad",result.desc_entidad);
          localStorage.setItem("id_entidad",result.id_entidad);
          localStorage.setItem("alias",result.alias);
          localStorage.setItem("PERMISO_VER_DASHBOARD",result.PERMISO_VER_DASHBOARD);
          usuarioAutenticadoChange(result.authorizationToken, usuario, password)
          history("/");
        }
        else {
          localStorage.removeItem('token-taxo')
          setMensajeError(response.error);
        }

      }
      catch (error) {
        setMensajeError(error);
      }



      //  localStorage.setItem(
      //    "token-taxo",
      //    "WWWW"
      //  );

      //usuarioAutenticadoChange()
      //history("/");
    } catch (error) {
      console.log(error)
      setMensajeError(error);
    }
    setCargando(false)
  };

  return (
    <div className="flex-box">
      <div className="login-box px-md-0">
        <div className="login-form">
          <form
            onSubmit={ejecutarInicioDeSesion} >
            <div className="sign-in-header">
              <div style={{ marginTop: '1rem' }}>
                <h1 style={{ color: 'lightgrey', }}>Iniciar sesión</h1>
              </div>
            </div>
            <div className="sign-in-fields">
              <input
                className="form-control special-form-control gris-principal"
                type="text"
                placeholder="Usuario"
                onChange={(e) => setUsuario(e.target.value)}
              />
              <div className="passwd-box">
                <img
                  src={mostrandoPassword ? ojoCerrado : ojoAbierto}
                  className='toggle_pwd'
                  alt="Mostrar/No mostrar contraseña"
                  onClick={(e) => setMostrandoPassword((previous, props) => !previous)}
                />
                <input
                  className="form-control special-form-control gris-principal"
                  type={mostrandoPassword ? "text" : "password"}
                  placeholder="Contraseña"
                  onChange={(e) => setPassword(e.target.value)}
                /></div>
            </div>
            {!cargando && (
              <Button
                className='btn btn-primary mid-button' type="submit"

                disabled={!usuario || !password} >Iniciar sesión</Button>
            )}
            {cargando && (
              <CircularProgress />
            )}

            {mensajeError && <Alert severity="error">{mensajeError}</Alert>}
            {mostrarMensajeTokenCaducado && <Alert className='mt-2' severity="warning">Se ha caducado su sesión por seguridad. Por favor, vuelva a iniciar sesión. </Alert>}
          </form>
        </div>
        {/* <div className="image-box"></div> */}
      </div>
    </div>
  );
}

export default Login;
