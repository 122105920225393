import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css'
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css'
import { Form, Button } from 'react-bootstrap';
import { Typeahead, Menu, MenuItem, Highlighter } from 'react-bootstrap-typeahead';
import { BsCheckCircleFill } from "react-icons/bs"
import Spinner from 'react-bootstrap/Spinner'
import taxoApi from '../../../api/taxoApi';
import PopOver from '../../../commons/popOver/popOver'
import './componentFormNewAction.css';
import { MdOutlineHelpOutline } from "react-icons/md"
import { Context } from "../../../commons/utils/store";

export default function ComponentFormNewAction() {
    const data = [{ "id": "radio1", "label": "Por Sector o Actividad económica", "result": "" }, { "id": "radio2", "label": "Por CNAE", "result": "" }, { "id": "radio3", "label": "Por Finalidad de la Operación", "result": "" }];
    const [windowSize, setWindowSize] = useState(window.innerWidth)
    const [state, dispatch] = useContext(Context);
    const propsTypeahead = {};

    var optionsActivity = [];
    var optionsAmbit = [];
    var optionsObjetive = [];
    var optionsSectorInstitutional = [];
    let optionsActivityAll = [];

    //// Por Sector o actividad económica /////////////////////////////////////////////////////////////////////
    const [optionsActivityState, setOptionsActivityState] = useState([])
    const [optionsObjetiveState, setOptionsObjetiveState] = useState([])
    const [optionsAmbitState, setOptionsAmbitState] = useState([])
    const [optionsSectorInstitutionalState, setOptionsSectorInstitutionalState] = useState([])
    const [optionsActivityStateAux, setOptionsActivityStateAux] = useState([])
    const [optionsObjetiveStateAux, setOptionsObjetiveStateAux] = useState([])
    const [optionsAmbitStateAux, setOptionsAmbitStateAux] = useState([])

    //// Por NACE /////////////////////////////////////////////////////////////////////////////////////////////
    var optionsNACE = [];

    const [optionsNACEState, setOptionsNACEState] = useState([])
    const [optionsNACEStateAux, setOptionsNACEStateAux] = useState([])
    const [optionsActivityStateAuxNACE, setOptionsActivityStateAuxNACE] = useState([])
    const [optionsObjetiveStateAuxNACE, setOptionsObjetiveStateAuxNACE] = useState([])
    const [optionsAmbitStateAuxNACE, setOptionsAmbitStateAuxNACE] = useState([])

    //// Por Finalidad /////////////////////////////////////////////////////////////////////////////////////////////
    var optionsPurpose = [];

    const [optionsPurposeState, setOptionsPurposeState] = useState([])
    const [optionsPurposeStateAux, setOptionsPurposeStateAux] = useState([])
    const [optionsActivityStateAuxPurpose, setOptionsActivityStateAuxPurpose] = useState([])
    const [optionsObjetiveStateAuxPurpose, setOptionsObjetiveStateAuxPurpose] = useState([])
    const [optionsAmbitStateAuxPurpose, setOptionsAmbitStateAuxPurpose] = useState([])

    const navigate = useNavigate();
    //const [optionsActivityStateAuxNACEBack, setOptionsActivityStateAuxNACEBack] = useState([])

    const [optionsActivityAllAux, setOptionsActivityAllAux] = useState([])
    useEffect(() => {
        dispatch({ type: "SET_HIDDEN", payload: false });
        taxoApi.getAllSectors().then((response, navigate) => {
            optionsAmbit = [];
            response.data.forEach(item => {
                optionsAmbit.push(item)
            })
            setOptionsAmbitState(optionsAmbit)
            setOptionsAmbitStateAux(optionsAmbit)
            setOptionsAmbitStateAuxNACE(optionsAmbit)
            setOptionsAmbitStateAuxPurpose(optionsAmbit)
        })

        taxoApi.getAllSectorInstitutionals().then((response, navigate) => {
            optionsSectorInstitutional = [];
            response.data.forEach(item => {
                optionsSectorInstitutional.push(item)
            })
            setOptionsSectorInstitutionalState(optionsSectorInstitutional)
            
        })


        taxoApi.getAllObjetives().then((response, navigate) => {
            optionsObjetive = [];
            response.data.forEach(item => {
                optionsObjetive.push(item)
            })
            setOptionsObjetiveState(optionsObjetive)
            setOptionsObjetiveStateAux([])
            setOptionsObjetiveStateAuxNACE([])
            setOptionsObjetiveStateAuxPurpose([])
        })

        taxoApi.getAllActivities().then((response, navigate) => {
            optionsActivity = [];
            response.data.forEach(item => {
                optionsActivity.push(item)
            })
            setOptionsActivityState(optionsActivity)
            setOptionsActivityStateAux(optionsActivity)
            setOptionsActivityStateAuxNACE(optionsActivity)
            setOptionsActivityStateAuxPurpose(optionsActivity)
            dispatch({ type: "SET_HIDDEN", payload: true });
        })

        



    }, [])

    const [checkvalue, setCheckvalue] = useState("radio1")
    const [loading, setLoading] = useState(false);

    const [activityWarning, setActivityWarning] = useState({ status: false, msg: "" })
    const [isEmptySectorInstitutional, setIsEmptySectorInstitutional] = useState({ status: false, msg: "" })
    const [isEmptyOperation, setIsEmptyOperation] = useState({ status: false, msg: "" })
    const [isEmptyAmbit, setIsEmptyAmbit] = useState({ status: false, msg: "" })
    const [isEmptyObjetive, setIsEmptyObjective] = useState({ status: false, msg: "" })
    const [singleSelectionsActivity, setSingleSelectionsActivity] = useState([])
    const [singleSelectionsAmbit, setSingleSelectionsAmbit] = useState([])
    const [singleSelectionsObjetive, setSingleSelectionsObjetive] = useState([])
    const [singleSelectionsOperation, setSingleSelectionsOperation] = useState([])
    const [singleSelectionSectorInstitutional, setSingleSelectionSectorInstitutional] = useState([])

    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [activityWarningNACE, setActivityWarningNACE] = useState({ status: false, msg: "" })
    const [isEmptyAmbitNACE, setIsEmptyAmbitNACE] = useState({ status: false, msg: "" })
    const [isEmptyObjetiveNACE, setIsEmptyObjectiveNACE] = useState({ status: false, msg: "" })
    const [isEmptyNACE, setIsEmptyNACE] = useState({ status: false, msg: "" })

    const [singleSelectionsActivityNACE, setSingleSelectionsActivityNACE] = useState([])
    const [singleSelectionsAmbitNACE, setSingleSelectionsAmbitNACE] = useState([])
    const [singleSelectionsObjetiveNACE, setSingleSelectionsObjetiveNACE] = useState([])
    const [singleSelectionsNACE, setSingleSelectionsNACE] = useState([])

    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [activityWarningPurpose, setActivityWarningPurpose] = useState({ status: false, msg: "" })
    const [isEmptyAmbitPurpose, setIsEmptyAmbitPurpose] = useState({ status: false, msg: "" })
    const [isEmptyObjetivePurpose, setIsEmptyObjectivePurpose] = useState({ status: false, msg: "" })
    const [isEmptyPurpose, setIsEmptyPurpose] = useState({ status: false, msg: "" })

    const [singleSelectionsActivityPurpose, setSingleSelectionsActivityPurpose] = useState([])
    const [singleSelectionsAmbitPurpose, setSingleSelectionsAmbitPurpose] = useState([])
    const [singleSelectionsObjetivePurpose, setSingleSelectionsObjetivePurpose] = useState([])
    const [singleSelectionsPurpose, setSingleSelectionsPurpose] = useState([])

    function initializeInputPurpose() {
        setSingleSelectionsPurpose([])
        setOptionsPurposeStateAux(optionsPurposeState)
    }

    function initializeInputAmbitPurpose() {
        setSingleSelectionsAmbitPurpose([])
        setOptionsAmbitStateAuxPurpose(optionsAmbitState)
        setIsEmptyAmbit({ status: false, msg: "" });
    }

    function initializeInputOperationPurpose() {
        setIsEmptyOperation({ status: false, msg: "" });
        setSingleSelectionsOperation([]);
    }
    

    function initializeInputNACE() {
        setSingleSelectionsNACE([])
        setOptionsNACEStateAux(optionsNACEState)
    }

    function initializeInputAmbit() {
        setSingleSelectionsAmbitNACE([])
        setOptionsAmbitStateAuxNACE(optionsAmbitState)
        setIsEmptyAmbit({ status: false, msg: "" });
    }

    function initializeInputSectorInstitutional() {
        setSingleSelectionSectorInstitutional([])
        isEmptySectorInstitutional({ status: false, msg: "" });
    }



    function initializeInputActivity() {
        if (checkvalue === "radio1") {
            setSingleSelectionsActivity([])
            setOptionsActivityStateAux(optionsActivityState)
            setActivityWarning({ status: false, msg: "" });
        } else if (checkvalue === "radio2") {
            setSingleSelectionsActivityNACE([])
            setOptionsActivityStateAuxNACE(optionsActivityState)
            setActivityWarningNACE({ status: false, msg: "" });
        }
        else {
            setSingleSelectionsActivityPurpose([])
            setOptionsActivityStateAuxPurpose(optionsActivityState)
            setActivityWarningPurpose({ status: false, msg: "" });
        }
    }

    function initializeInputObjetive() {
        if (checkvalue === "radio1") {
            setSingleSelectionsObjetive([])
            //setOptionsObjetiveStateAux(optionsObjetiveState)
            setIsEmptyObjective({ status: false, msg: "" });
        } else if (checkvalue === "radio2") {
            setSingleSelectionsObjetiveNACE([])
            //setOptionsObjetiveStateAuxNACE(optionsObjetiveState)
            setIsEmptyObjectiveNACE({ status: false, msg: "" });
        } else {
            setSingleSelectionsObjetivePurpose([])
            //setOptionsObjetiveStateAuxNACE(optionsObjetiveState)
            setIsEmptyObjectivePurpose({ status: false, msg: "" });
        }
    }

    const handleInputChange = (event) => {
        setCheckvalue(event.target.id)
        if ((event.target.id === 'radio2') && optionsNACEState !== [] && optionsNACEState !== undefined && optionsNACEState.length < 1) {
            dispatch({ type: "SET_HIDDEN", payload: false });
            taxoApi.getActivitiesNACE().then((response, navigate) => {
                optionsNACE = []
                /*Object.values(response.data).map(item => {
                    optionsNACE.push(item)
                })*/
                setOptionsNACEState(Object.values(response.data))
                setOptionsNACEStateAux(Object.values(response.data))
                dispatch({ type: "SET_HIDDEN", payload: true });
            }
            )
        }
        else if ((event.target.id === 'radio3') && optionsPurposeState !== [] && optionsPurposeState !== undefined && optionsPurposeState.length < 1) {
            dispatch({ type: "SET_HIDDEN", payload: false });
            taxoApi.getActivitiesPurpose().then((response, navigate) => {
                optionsNACE = []
                /*Object.values(response.data).map(item => {
                    optionsNACE.push(item)
                })*/
                setOptionsPurposeState(Object.values(response.data))
                setOptionsPurposeStateAux(Object.values(response.data))
                dispatch({ type: "SET_HIDDEN", payload: true });
            }
            )
        }
    }

    const handleInputOperationChange = (event) => {
        setIsEmptyOperation({ status: false, msg: "" });
        if (event.target.value !== null || event.target.value.length > 0) {
            setSingleSelectionsOperation(event.target.value);
        }
        if(event.target.value === null || event.target.value.length === 0){
            setIsEmptyOperation({ status: true, msg: "indique una operación" });
        }
        
    }

    const handleInputSectorInstitutionalChange = (event) => {
        setIsEmptySectorInstitutional({ status: false, msg: "" });
        if (event !== null && event.length > 0) {
            setSingleSelectionSectorInstitutional(event);
        }
        else{
            setSingleSelectionSectorInstitutional([]);
        }
        
    }

    const handleInputAmbitChange = (event) => {
        if (checkvalue === "radio1") {
            //setOptionsObjetiveStateAux([]);
            setIsEmptyAmbit({ status: false, msg: "" });
            setActivityWarning({ status: false, msg: "" });
            if (event !== null && event.length > 0) {

                let optionsActividadAux = []

                setSingleSelectionsAmbit(event);
                optionsActividadAux = optionsActivityState.filter((n) => {
                    return n.idSector === event[0].id
                });
                setOptionsActivityStateAux(optionsActividadAux);
                setIsEmptyAmbit({ status: false, msg: "" });

                if (optionsActividadAux.length === 1) {
                    setSingleSelectionsActivity(optionsActividadAux)
                    handleInputActivityChange(optionsActividadAux)
                    setOptionsActivityStateAux(optionsActividadAux);
                }

                if (event[0].implementationState !== 1) {
                    setIsEmptyAmbit({ status: true, msg: "Sector no disponible" });
                    setIsEmptyObjective({ status: false, msg: "" });
                    setOptionsObjetiveStateAux([]);
                    return
                }

            }
            else {
                setOptionsObjetiveStateAux([]);
                setSingleSelectionsAmbit([]);
                initializeInputActivity();
                initializeInputObjetive();
                setOptionsAmbitStateAux(optionsAmbitState)
            }
        } else if (checkvalue === "radio2") {
            setIsEmptyAmbitNACE({ status: false, msg: "" });
            setActivityWarningNACE({ status: false, msg: "" });
            if (event !== null && event.length > 0) {
                let optionsActividadAux = []
                setSingleSelectionsAmbitNACE(event);
                optionsActividadAux = optionsActivityStateAuxNACE.filter((n) => {
                    return n.idSector === event[0].id
                });
                if (event[0].implementationState === 1) {
                    setOptionsActivityStateAuxNACE(optionsActividadAux);
                }

                setIsEmptyAmbitNACE({ status: false, msg: "" });

                if (event[0].implementationState !== 1) {
                    setIsEmptyAmbitNACE({ status: true, msg: "Sector no disponible" });
                    setIsEmptyObjectiveNACE({ status: false, msg: "" });
                    return
                }

            }
            else {
                setOptionsObjetiveStateAuxNACE([]);
                setSingleSelectionsAmbitNACE([]);
                setSingleSelectionsActivityNACE([]);
                setSingleSelectionsObjetiveNACE([])
                setOptionsActivityStateAuxNACE(optionsActivityAllAux)
            }
        } else {
            setIsEmptyAmbitPurpose({ status: false, msg: "" });
            setActivityWarningPurpose({ status: false, msg: "" });
            if (event !== null && event.length > 0) {
                let optionsActividadAux = []
                setSingleSelectionsAmbitPurpose(event);
                optionsActividadAux = optionsActivityStateAuxPurpose.filter((n) => {
                    return n.idSector === event[0].id
                });
                if (event[0].implementationState === 1) {
                    setOptionsActivityStateAuxPurpose(optionsActividadAux);
                }

                setIsEmptyAmbitPurpose({ status: false, msg: "" });

                if (event[0].implementationState !== 1) {
                    setIsEmptyAmbitPurpose({ status: true, msg: "Sector no disponible" });
                    setIsEmptyObjectivePurpose({ status: false, msg: "" });
                    return
                }

            }
            else {
                setOptionsObjetiveStateAuxPurpose([]);
                setSingleSelectionsAmbitPurpose([]);
                setSingleSelectionsActivityPurpose([]);
                setSingleSelectionsObjetivePurpose([]);
                //setOptionsActivityStateAuxNACE(optionsActivityStateAuxNACEBack)
            }
        }
    }

    const handleInputActivityChange = (event) => {
        if (checkvalue === "radio1") {
            setActivityWarning({ status: false, msg: "" });
            var activitySelectedAux = []
            var optionsObjetivoAux = [];

            if (event !== null && event.length > 0) {
                setSingleSelectionsActivity(event);
                activitySelectedAux = optionsActivityState.filter((n) => {
                    return n.idSector === event[0].idSector
                });

                if (activitySelectedAux !== null && activitySelectedAux.length > 0) {
                    if (event[0].implementationState === 1) {
                        optionsObjetivoAux = optionsObjetiveState.filter((n) => {
                            return event[0].id === n.idActivity;
                        })

                    }
                    setSingleSelectionsAmbit(optionsAmbitState.filter((n) => n.id === activitySelectedAux[0].idSector));
                    setOptionsActivityStateAux(optionsActivityState.filter((n) => { return n.idSector === activitySelectedAux[0].idSector }));
                    setActivityWarning({ status: false, msg: "" });

                    if (optionsAmbitState.filter((n) => n.id === activitySelectedAux[0].idSector)[0].implementationState !== 1) {
                        setIsEmptyAmbit({ status: true, msg: "Sector no disponible" });
                    }
                    else {
                        setIsEmptyAmbit({ status: false, msg: "" });
                    }
                }

                setOptionsObjetiveStateAux(optionsObjetivoAux);
                if (optionsObjetivoAux.length === 1) {
                    setSingleSelectionsObjetive(optionsObjetivoAux)

                }

                if (event[0].implementationState !== 1) {
                    setActivityWarning({ status: true, msg: "Actividad no disponible" });
                    setIsEmptyObjective({ status: false, msg: "" });
                    return
                }

            }
            else {
                setSingleSelectionsActivity([]);
                setSingleSelectionsObjetive([]);
                setOptionsObjetiveStateAux([]);
            }
        } else if (checkvalue === "radio2") {
            activitySelectedAux = []
            optionsObjetivoAux = [];
            setActivityWarningNACE({ status: false, msg: "" });

            if (event !== null && event.length > 0) {
                setSingleSelectionsActivityNACE(event);

                activitySelectedAux = optionsActivityState.filter((n) => {
                    return n.idSector === event[0].idSector
                });


                if (activitySelectedAux !== null && activitySelectedAux.length > 0) {
                    if (event[0].implementationState === 1) {
                        optionsObjetivoAux = optionsObjetiveState.filter((n) => {
                            return event[0].id === n.idActivity;
                        })
                    }

                    setSingleSelectionsAmbitNACE(optionsAmbitState.filter((n) => n.id === activitySelectedAux[0].idSector));
                    setOptionsActivityStateAuxNACE(optionsActivityStateAuxNACE.filter((n) => { return n.idSector === activitySelectedAux[0].idSector }));
                    setActivityWarningNACE({ status: false, msg: "" });
                    setIsEmptyObjectiveNACE({ status: false, msg: "" });

                    if (optionsAmbitState.filter((n) => n.id === activitySelectedAux[0].idSector)[0].implementationState !== 1) {
                        setIsEmptyAmbitNACE({ status: true, msg: "Sector no disponible" });
                    }
                    else {
                        setIsEmptyAmbitNACE({ status: false, msg: "" });
                    }

                }
                setOptionsObjetiveStateAuxNACE(optionsObjetivoAux);

                if (optionsObjetivoAux.length === 1) {
                    setSingleSelectionsObjetiveNACE(optionsObjetivoAux)

                }

                if (event[0].implementationState !== 1) {
                    setActivityWarningNACE({ status: true, msg: "Actividad no disponible" });
                    setIsEmptyObjectiveNACE({ status: false, msg: "" });
                    return
                }

            } else {
                setSingleSelectionsActivityNACE([]);
                setSingleSelectionsObjetiveNACE([]);
                setOptionsObjetiveStateAuxNACE([]);
            }
        }
        else {
            activitySelectedAux = []
            optionsObjetivoAux = [];
            setActivityWarningPurpose({ status: false, msg: "" });

            if (event !== null && event.length > 0) {
                setSingleSelectionsActivityPurpose(event);

                activitySelectedAux = optionsActivityState.filter((n) => {
                    return n.idSector === event[0].idSector
                });


                if (activitySelectedAux !== null && activitySelectedAux.length > 0) {
                    if (event[0].implementationState === 1) {
                        optionsObjetivoAux = optionsObjetiveState.filter((n) => {
                            return event[0].id === n.idActivity;
                        })
                    }

                    setSingleSelectionsAmbitPurpose(optionsAmbitState.filter((n) => n.id === activitySelectedAux[0].idSector));
                    setOptionsActivityStateAuxPurpose(optionsActivityStateAuxPurpose.filter((n) => { return n.idSector === activitySelectedAux[0].idSector }));
                    setActivityWarningPurpose({ status: false, msg: "" });
                    setIsEmptyObjectivePurpose({ status: false, msg: "" });

                    if (optionsAmbitState.filter((n) => n.id === activitySelectedAux[0].idSector)[0].implementationState !== 1) {
                        setIsEmptyAmbitPurpose({ status: true, msg: "Sector no disponible" });
                    }
                    else {
                        setIsEmptyAmbitPurpose({ status: false, msg: "" });
                    }

                }
                setOptionsObjetiveStateAuxPurpose(optionsObjetivoAux);

                if (optionsObjetivoAux.length === 1) {
                    setSingleSelectionsObjetivePurpose(optionsObjetivoAux)

                }

                if (event[0].implementationState !== 1) {
                    setActivityWarningPurpose({ status: true, msg: "Actividad no disponible" });
                    setIsEmptyObjectivePurpose({ status: false, msg: "" });
                    return
                }

            } else {
                setSingleSelectionsActivityPurpose([]);
                setSingleSelectionsObjetivePurpose([]);
                setOptionsObjetiveStateAux([]);
            }
        }
    }

    const handleInputObjetivoChange = (event) => {
        if (checkvalue === "radio1") {
            if (event !== null && event.length > 0) {
                setSingleSelectionsObjetive(event);
                setIsEmptyObjective({ status: false, msg: "" });
            } else {
                setIsEmptyObjective({ status: false, msg: "" });
                setSingleSelectionsObjetive([]);
            }
        } else if (checkvalue === "radio2") {
            if (event !== null && event.length > 0) {
                setSingleSelectionsObjetiveNACE(event);
                setIsEmptyObjectiveNACE({ status: false, msg: "" });
            } else {
                setSingleSelectionsObjetiveNACE([]);
            }
        }
        else {
            if (event !== null && event.length > 0) {
                setSingleSelectionsObjetivePurpose(event);
                setIsEmptyObjectivePurpose({ status: false, msg: "" });
            } else {
                setSingleSelectionsObjetivePurpose([]);
            }
        }
    }


    const handleInputNACEChange = (event) => {
        var activitySelectedAux = []
        var ambitSelectedAux = []
        var optionsNACEAux = [];
        var sectores = []
        var actividades = []
        setIsEmptyAmbitNACE({ status: false, msg: "" });
        setOptionsObjetiveStateAuxNACE([]);

        if (event !== null && event.length > 0) {
            setSingleSelectionsNACE(event);
            setIsEmptyNACE({ status: false, msg: "" });
            optionsNACEAux = optionsNACEState.filter((n) => {
                return n.Id === event[0].Id
            });

            if (optionsNACEAux !== null && optionsNACEAux.length > 0) {
                Object.values(optionsNACEAux[0].activities).forEach((n) => {
                    if (!actividades.includes(n.activityId)) {
                        actividades.push(n.activityId)
                        activitySelectedAux.push(optionsActivityState.filter(i => i.id === n.activityId)[0])
                    }
                })

                optionsActivityAll = JSON.parse(JSON.stringify(activitySelectedAux)) //{...activitySelectedAux};
                setOptionsActivityAllAux(optionsActivityAll);

                activitySelectedAux.forEach((n) => {
                    if (!sectores.includes(n.idSector)) {
                        sectores.push(n.idSector)
                        ambitSelectedAux.push(optionsAmbitState.filter((i) => {
                            return i.id === n.idSector
                        })[0])
                    }
                })
            }



            setOptionsActivityStateAuxNACE(activitySelectedAux)
            setOptionsAmbitStateAuxNACE(ambitSelectedAux)
            //setOptionsActivityStateAuxNACEBack(activitySelectedAux)

            if (activitySelectedAux.length === 1) {
                setSingleSelectionsActivityNACE(activitySelectedAux)
                handleInputActivityChange(activitySelectedAux)
            }
        }
        else {
            initializeInputNACE()
            initializeInputAmbit()
            initializeInputActivity()
            initializeInputObjetive()
            //setOptionsActivityStateAuxNACEBack([])
        }
    }

    const questionHelp = (message) => {
        let units = null
        let pos = windowSize > 575 ? undefined : 'bottom'
        let help =
            <div className='info-field d-inline color-secondary'>
                <PopOver title={<MdOutlineHelpOutline className='h6' />} message={message} units={units} position={pos} />
            </div>
        return help
    }

    const handleInputPurposeChange = (event) => {
        var activitySelectedAux = []
        var ambitSelectedAux = []
        var optionsPurposeAux = [];
        var sectores = []
        var actividades = []
        setIsEmptyAmbitPurpose({ status: false, msg: "" });
        setOptionsObjetiveStateAuxPurpose([]);

        if (event !== null && event.length > 0) {
            setSingleSelectionsPurpose(event);
            setIsEmptyPurpose({ status: false, msg: "" });
            optionsPurposeAux = optionsPurposeState.filter((n) => {
                return n.Id === event[0].Id
            });

            if (optionsPurposeAux !== null && optionsPurposeAux.length > 0) {
                Object.values(optionsPurposeAux[0].activities).forEach((n) => {
                    if (!actividades.includes(n.activityId)) {
                        actividades.push(n.activityId)
                        activitySelectedAux.push(optionsActivityState.filter(i => i.id === n.activityId)[0])
                    }
                })


                activitySelectedAux.forEach((n) => {
                    if (!sectores.includes(n.idSector)) {
                        sectores.push(n.idSector)
                        ambitSelectedAux.push(optionsAmbitState.filter((i) => {
                            return i.id === n.idSector
                        })[0])
                    }
                })
            }



            setOptionsActivityStateAuxPurpose(activitySelectedAux)
            setOptionsAmbitStateAuxPurpose(ambitSelectedAux)
            //setOptionsActivityStateAuxNACEBack(activitySelectedAux)

            if (activitySelectedAux.length === 1) {
                setSingleSelectionsActivityPurpose(activitySelectedAux)
                handleInputActivityChange(activitySelectedAux)
            }
        }
        else {
            initializeInputPurpose()
            initializeInputAmbitPurpose()
            initializeInputActivity()
            initializeInputObjetive()
            initializeInputOperationPurpose()
            //setOptionsActivityStateAuxNACEBack([])
        }
    }

    async function handleSubmit(event) {
        event.preventDefault(); // Evita la recarga
        try {
            if (checkvalue === "radio1") {
                if (singleSelectionsAmbit !== null && singleSelectionsAmbit.length > 0
                    && singleSelectionsActivity !== null && singleSelectionsActivity.length > 0
                    && singleSelectionsObjetive !== null && singleSelectionsObjetive.length > 0
                    && singleSelectionsOperation !== null && singleSelectionsOperation.length > 0) {
                    setLoading(true)
                    //await sleep(1000)

                    var url = "/evaluation/1/" + singleSelectionsAmbit[0].id + "/" + singleSelectionsActivity[0].id + "/" + singleSelectionsObjetive[0].id + "/" + singleSelectionsOperation;
                    navigate(url);
                } else {
                    if ((singleSelectionsOperation === null || (singleSelectionsOperation !== null && singleSelectionsOperation.length < 1))) {
                        setIsEmptyOperation({ status: true, msg: "Por favor, indique una operación" });
                    }
                    if ((singleSelectionsActivity === null || (singleSelectionsActivity !== null && singleSelectionsActivity.length < 1)) && singleSelectionsAmbit !== null && singleSelectionsAmbit.length > 0 && singleSelectionsAmbit[0].implementationState === 1) {
                        setActivityWarning({ status: true, msg: "Por favor, seleccione una actividad" });
                    }
                    if (singleSelectionsAmbit === null || (singleSelectionsAmbit !== null && singleSelectionsAmbit.length < 1)) {
                        setIsEmptyAmbit({ status: true, msg: "Por favor, seleccione un sector" });
                    }
                    if ((singleSelectionsObjetive === null || (singleSelectionsObjetive !== null && singleSelectionsObjetive.length < 1)) && optionsObjetiveStateAux.length > 0) {
                        setIsEmptyObjective({ status: true, msg: "Por favor, seleccione un objetivo" });
                    }
                    
                }
            }
            else if (checkvalue === "radio2") {
                if (singleSelectionsNACE !== null && singleSelectionsNACE.length > 0) {
                    if (singleSelectionsAmbitNACE !== null && singleSelectionsAmbitNACE.length > 0
                        && singleSelectionsActivityNACE !== null && singleSelectionsActivityNACE.length > 0
                        && singleSelectionsObjetiveNACE !== null && singleSelectionsObjetiveNACE.length > 0
                        && singleSelectionsOperation !== null && singleSelectionsOperation.length > 0) {
                        setLoading(true)
                        //await sleep(1000)

                        url = "/evaluation/1/" + singleSelectionsAmbitNACE[0].id + "/" + singleSelectionsActivityNACE[0].id + "/" + singleSelectionsObjetiveNACE[0].id + "/" + singleSelectionsOperation;
                        navigate(url);
                        //navigate("/evaluation/1/" + singleSelectionsNACE[0].Id);
                    }
                    else {
                        if ((singleSelectionsOperation === null || (singleSelectionsOperation !== null && singleSelectionsOperation.length < 1))) {
                            setIsEmptyOperation({ status: true, msg: "Por favor, indique una operación" });
                        }
                        if ((singleSelectionsActivityNACE === null || (singleSelectionsActivityNACE !== null && singleSelectionsActivityNACE.length < 1)) && singleSelectionsAmbitNACE !== null && singleSelectionsAmbitNACE.length > 0 && singleSelectionsAmbitNACE[0].implementationState === 1) {
                            setActivityWarningNACE({ status: true, msg: "Por favor, seleccione una actividad" });
                        }
                        if (singleSelectionsAmbitNACE === null || (singleSelectionsAmbitNACE !== null && singleSelectionsAmbitNACE.length < 1)) {
                            setIsEmptyAmbitNACE({ status: true, msg: "Por favor, seleccione un sector" });
                        }
                        if ((singleSelectionsObjetiveNACE === null || (singleSelectionsObjetiveNACE !== null && singleSelectionsObjetiveNACE.length < 1)) && optionsObjetiveStateAuxNACE.length > 0) {
                            setIsEmptyObjectiveNACE({ status: true, msg: "Por favor, seleccione un objetivo" });
                        }
                        
                    }
                }
                else {
                    setIsEmptyNACE({ status: true, msg: "Por favor, seleccione un CNAE" });

                }
            }
            else {
                if (singleSelectionsPurpose !== null && singleSelectionsPurpose.length > 0) {
                    if (singleSelectionsAmbitPurpose !== null && singleSelectionsAmbitPurpose.length > 0
                        && singleSelectionsActivityPurpose !== null && singleSelectionsActivityPurpose.length > 0
                        && singleSelectionsObjetivePurpose !== null && singleSelectionsObjetivePurpose.length > 0
                        && singleSelectionsOperation !== null && singleSelectionsOperation.length > 0) {
                        setLoading(true)
                        //await sleep(1000)

                        url = "/evaluation/1/" + singleSelectionsAmbitPurpose[0].id + "/" + singleSelectionsActivityPurpose[0].id + "/" + singleSelectionsObjetivePurpose[0].id + "/" + singleSelectionsOperation;
                        navigate(url);
                        //navigate("/evaluation/1/" + singleSelectionsNACE[0].Id);
                    }
                    else {
                        if ((singleSelectionsOperation === null || (singleSelectionsOperation !== null && singleSelectionsOperation.length < 1))) {
                            setIsEmptyOperation({ status: true, msg: "Por favor, indique una operación" });
                        }
                        if ((singleSelectionsActivityPurpose === null || (singleSelectionsActivityPurpose !== null && singleSelectionsActivityPurpose.length < 1)) && singleSelectionsAmbitPurpose !== null && singleSelectionsAmbitPurpose.length > 0 && singleSelectionsAmbitPurpose[0].implementationState === 1) {
                            setActivityWarningPurpose({ status: true, msg: "Por favor, seleccione una actividad" });
                        }
                        if (singleSelectionsAmbitPurpose === null || (singleSelectionsAmbitPurpose !== null && singleSelectionsAmbitPurpose.length < 1)) {
                            setIsEmptyAmbitPurpose({ status: true, msg: "Por favor, seleccione un sector" });
                        }
                        if ((singleSelectionsObjetivePurpose === null || (singleSelectionsObjetivePurpose !== null && singleSelectionsObjetivePurpose.length < 1)) && optionsObjetiveStateAuxPurpose.length > 0) {
                            setIsEmptyObjectivePurpose({ status: true, msg: "Por favor, seleccione un objetivo" });
                        }
                        
                    }
                }
                else {
                    setIsEmptyPurpose({ status: true, msg: "Por favor, seleccione una finalidad" });

                }
            }

        } catch (error) {
            console.log(error);
        }

    };

    //método que permite que las actividades no implementadas estén pintadas en gris
    propsTypeahead.renderMenuItemChildren = (option, { text }) => (
        <>
            {option.implementationState === 1 ?
                <Highlighter search={text}>{option.name}</Highlighter>
                :
                <div className='disabled-option'>
                    <Highlighter search={text}>{option.name}</Highlighter>
                </div>
            }

        </>
    );

    return (
        <>
            <button type="button" id="select-activity-id" role="tab" data-rr-ui-event-key="CS" aria-controls="select-activity" aria-selected="true" class="nav-link active activity-selection">Paso 0: Selección</button>
            <Form onSubmit={handleSubmit} className="row mt-3">
                <div className="mb-1">
                            <span className='text-bold'>1º) Identifique el nombre o identificador de la operación a evaluar: </span>
                    {/* {questionHelp("hola")} */}
                </div>
                <Form.Group id="group-input-objetiveNACE" className="col-md-6 mb-4 p-35" >
                    <Form.Label className='font'>Identificador de la evaluación (*):</Form.Label>
                    <Form.Control id="input-operation" placeholder="Nombre o identificador de la operación" onChange={handleInputOperationChange} type='text' className='input-form-typeahead question-input' />
                    <div className='mb-0 text-danger'>{isEmptyOperation.msg}</div>
                </Form.Group>
                <Form.Group id="group-input-objetiveNACE" className="col-md-6 mb-4 p-35" >
                    <Form.Label className='font'>Sector institucional de la contraparte:</Form.Label>
                    <Typeahead
                        clearButton
                        id="input-sector-institutional"
                        inputProps={{ className: 'input-form-typeahead' }}
                        labelKey="name"
                        onChange={handleInputSectorInstitutionalChange}
                        options={optionsSectorInstitutionalState}
                        placeholder="Seleccione el sector institucional"
                        selected={singleSelectionSectorInstitutional}
                        {...propsTypeahead}
                    >
                        <svg className={isEmptySectorInstitutional.status ? "col-md lens-image-error" : "col-md lens-image"} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 485.104 485.104" >
                            <g>
                                <path d="M110.028,115.171c-4.76-4.767-12.483-4.752-17.227,0c-32.314,32.33-32.314,84.898-0.016,117.197
                            c2.38,2.379,5.487,3.569,8.614,3.569c3.123,0,6.234-1.19,8.613-3.569c4.76-4.76,4.76-12.469,0-17.228
                            c-22.795-22.803-22.795-59.923,0.016-82.742C114.788,127.64,114.788,119.923,110.028,115.171z"/>
                                <path d="M471.481,405.861L324.842,259.23c37.405-66.25,28.109-151.948-28.217-208.317C263.787,18.075,220.133,0,173.718,0
                            C127.287,0,83.633,18.075,50.81,50.913c-67.717,67.74-67.701,177.979,0.02,245.738c32.85,32.823,76.488,50.897,122.919,50.897
                            c30.489,0,59.708-7.939,85.518-22.595L405.824,471.51c18.113,18.121,47.493,18.129,65.641,0
                            c8.706-8.71,13.593-20.512,13.608-32.823C485.073,426.37,480.171,414.567,471.481,405.861z M85.28,262.191
                            c-48.729-48.756-48.729-128.079-0.016-176.828c23.62-23.627,55.029-36.634,88.453-36.634c33.407,0,64.816,13.007,88.451,36.627
                            c48.715,48.756,48.699,128.094-0.015,176.85c-23.62,23.612-55.014,36.612-88.406,36.612
                            C140.341,298.818,108.919,285.811,85.28,262.191z"/>
                            </g>
                        </svg>
                    </Typeahead>
                    <div className='mb-0 text-danger'>{isEmptySectorInstitutional.msg}</div>
                </Form.Group>
                <div className="mb-1">
                    <span className='text-bold'>2º) Seleccione actividad económica de la operación  (*): </span> 
                    {/* {questionHelp("hola")} */}
                </div>
                <div className="mb-2  p-35">
                    <label>Seleccione opción: </label>
                    {data.map((type) => (
                        <Form.Check
                            inline
                            name={`${type.id}`}
                            type="radio"
                            id={`${type.id}`}
                            label={`${type.label}`}
                            value={`${type.label}`}
                            checked={checkvalue === `${type.id}`}
                            onChange={(event) => { handleInputChange(event) }}
                        />
                    ))}
                </div>
                
                {checkvalue === "radio1" ?
                    <>
                        <Form.Group id="group-input-ambit" className="col-md-6  mb-4  p-35">


                            <Typeahead
                                clearButton
                                id="input-ambit"
                                inputProps={{ className: 'input-form-typeahead' }}
                                labelKey="name"
                                onChange={handleInputAmbitChange}
                                options={optionsAmbitStateAux}
                                placeholder="Seleccione un sector"
                                selected={singleSelectionsAmbit}
                                {...propsTypeahead}
                            >
                                <svg className={isEmptyAmbit.status ? "col-md lens-image-error" : "col-md lens-image"} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 485.104 485.104" >
                                    <g>
                                        <path d="M110.028,115.171c-4.76-4.767-12.483-4.752-17.227,0c-32.314,32.33-32.314,84.898-0.016,117.197
                                    c2.38,2.379,5.487,3.569,8.614,3.569c3.123,0,6.234-1.19,8.613-3.569c4.76-4.76,4.76-12.469,0-17.228
                                    c-22.795-22.803-22.795-59.923,0.016-82.742C114.788,127.64,114.788,119.923,110.028,115.171z"/>
                                        <path d="M471.481,405.861L324.842,259.23c37.405-66.25,28.109-151.948-28.217-208.317C263.787,18.075,220.133,0,173.718,0
                                    C127.287,0,83.633,18.075,50.81,50.913c-67.717,67.74-67.701,177.979,0.02,245.738c32.85,32.823,76.488,50.897,122.919,50.897
                                    c30.489,0,59.708-7.939,85.518-22.595L405.824,471.51c18.113,18.121,47.493,18.129,65.641,0
                                    c8.706-8.71,13.593-20.512,13.608-32.823C485.073,426.37,480.171,414.567,471.481,405.861z M85.28,262.191
                                    c-48.729-48.756-48.729-128.079-0.016-176.828c23.62-23.627,55.029-36.634,88.453-36.634c33.407,0,64.816,13.007,88.451,36.627
                                    c48.715,48.756,48.699,128.094-0.015,176.85c-23.62,23.612-55.014,36.612-88.406,36.612
                                    C140.341,298.818,108.919,285.811,85.28,262.191z"/>
                                    </g>
                                </svg>
                            </Typeahead>
                            <div className='mb-0 text-danger'>{isEmptyAmbit.msg}</div>
                        </Form.Group>

                        <Form.Group id="group-input-activity" className="col-md-6 mb-4  p-35" >
                            
                            <Typeahead
                                clearButton
                                id="input-activity"
                                labelKey="name"
                                onChange={handleInputActivityChange}
                                options={optionsActivityStateAux}
                                inputProps={{ className: 'input-form-typeahead' }}
                                placeholder="Seleccione una actividad económica"
                                selected={singleSelectionsActivity}
                                {...propsTypeahead}
                            >
                                <svg className={activityWarning.status ? "col-md lens-image-error" : "col-md lens-image"} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 485.104 485.104" >
                                    <g>
                                        <path d="M110.028,115.171c-4.76-4.767-12.483-4.752-17.227,0c-32.314,32.33-32.314,84.898-0.016,117.197
                                    c2.38,2.379,5.487,3.569,8.614,3.569c3.123,0,6.234-1.19,8.613-3.569c4.76-4.76,4.76-12.469,0-17.228
                                    c-22.795-22.803-22.795-59.923,0.016-82.742C114.788,127.64,114.788,119.923,110.028,115.171z"/>
                                        <path d="M471.481,405.861L324.842,259.23c37.405-66.25,28.109-151.948-28.217-208.317C263.787,18.075,220.133,0,173.718,0
                                    C127.287,0,83.633,18.075,50.81,50.913c-67.717,67.74-67.701,177.979,0.02,245.738c32.85,32.823,76.488,50.897,122.919,50.897
                                    c30.489,0,59.708-7.939,85.518-22.595L405.824,471.51c18.113,18.121,47.493,18.129,65.641,0
                                    c8.706-8.71,13.593-20.512,13.608-32.823C485.073,426.37,480.171,414.567,471.481,405.861z M85.28,262.191
                                    c-48.729-48.756-48.729-128.079-0.016-176.828c23.62-23.627,55.029-36.634,88.453-36.634c33.407,0,64.816,13.007,88.451,36.627
                                    c48.715,48.756,48.699,128.094-0.015,176.85c-23.62,23.612-55.014,36.612-88.406,36.612
                                    C140.341,298.818,108.919,285.811,85.28,262.191z"/>
                                    </g>
                                </svg>
                            </Typeahead>
                            <div className='mb-0 text-danger'>{activityWarning.msg}</div>
                        </Form.Group>
                        <div className="mb-1">
                                <span className='text-bold'>3º) Seleccione el objetivo ambiental contra el que se desea evaluar la sostenibilidad de la operación  (*): </span>
                            {/* {questionHelp("hola")} */}
                        </div>
                        <Form.Group id="group-input-objetive" className="col-md-6  mb-1  p-35" >
                            <Typeahead
                                clearButton
                                disabled={optionsObjetiveStateAux.length === 0}
                                inputProps={{ className: 'input-form-typeahead' }}
                                id="input-objetive"
                                labelKey="name"
                                onChange={handleInputObjetivoChange}
                                options={optionsObjetiveStateAux}
                                placeholder="Seleccione un objetivo"
                                selected={singleSelectionsObjetive}>
                                <svg className={isEmptyObjetive.status ? "col-md lens-image-error" : "col-md lens-image"} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 485.104 485.104" >
                                    <g>
                                        <path d="M110.028,115.171c-4.76-4.767-12.483-4.752-17.227,0c-32.314,32.33-32.314,84.898-0.016,117.197
                                    c2.38,2.379,5.487,3.569,8.614,3.569c3.123,0,6.234-1.19,8.613-3.569c4.76-4.76,4.76-12.469,0-17.228
                                    c-22.795-22.803-22.795-59.923,0.016-82.742C114.788,127.64,114.788,119.923,110.028,115.171z"/>
                                        <path d="M471.481,405.861L324.842,259.23c37.405-66.25,28.109-151.948-28.217-208.317C263.787,18.075,220.133,0,173.718,0
                                    C127.287,0,83.633,18.075,50.81,50.913c-67.717,67.74-67.701,177.979,0.02,245.738c32.85,32.823,76.488,50.897,122.919,50.897
                                    c30.489,0,59.708-7.939,85.518-22.595L405.824,471.51c18.113,18.121,47.493,18.129,65.641,0
                                    c8.706-8.71,13.593-20.512,13.608-32.823C485.073,426.37,480.171,414.567,471.481,405.861z M85.28,262.191
                                    c-48.729-48.756-48.729-128.079-0.016-176.828c23.62-23.627,55.029-36.634,88.453-36.634c33.407,0,64.816,13.007,88.451,36.627
                                    c48.715,48.756,48.699,128.094-0.015,176.85c-23.62,23.612-55.014,36.612-88.406,36.612
                                    C140.341,298.818,108.919,285.811,85.28,262.191z"/>
                                    </g>
                                </svg>
                            </Typeahead>
                            <div className='mb-0 text-danger'>{isEmptyObjetive.msg}</div>
                            <div className='mt-1 text-end'>
                                {singleSelectionsActivity.length === 0 ?
                                    <span className='cursor-not'>? Sugerencia sobre objetivo a seleccionar</span> :

                                    // <PopOver title={'? Sugerencia sobre objetivo a seleccionar'} message={<><p>Adaptación cambio climático</p><p>{<BsCheckCircleFill className='me-2 color-succes'/>}Mitigación cambio climático</p>
                                    <PopOver title={'? Sugerencia sobre objetivo a seleccionar'} message={<>{optionsObjetiveStateAux.map((item, i) => (<p className={i + 1 === optionsObjetiveStateAux.length && 'mb-0'}>{item.id === singleSelectionsActivity[0].suggestedObjective && <BsCheckCircleFill className='me-2 color-succes' />}{item.name}</p>))}
                                    </>
                                    } />
                                }

                            </div>
                        </Form.Group>

                    </> :
                    checkvalue === "radio2" ?
                        <>
                            {/* //// NACE ///////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
                            <div className='input-pr-2'>
                                <Form.Group id="group-input-CNAE" className="col-md-6  mb-4  px-4">
                                    <Form.Label className='font'>Seleccione CNAE actividad económica  (*):</Form.Label>
                                    <Typeahead
                                        clearButton
                                        inputProps={{ className: 'input-form-typeahead' }}
                                        id="input-CNAE"
                                        labelKey={options => `${options.Id} - ${options.name}`}
                                        onChange={handleInputNACEChange}
                                        options={optionsNACEStateAux}
                                        placeholder="Seleccione un CNAE"
                                        selected={singleSelectionsNACE}
                                    >
                                        <svg className={isEmptyNACE.status ? "col-md lens-image-error" : "col-md lens-image"} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 485.104 485.104" >
                                            <g>
                                                <path d="M110.028,115.171c-4.76-4.767-12.483-4.752-17.227,0c-32.314,32.33-32.314,84.898-0.016,117.197
                                    c2.38,2.379,5.487,3.569,8.614,3.569c3.123,0,6.234-1.19,8.613-3.569c4.76-4.76,4.76-12.469,0-17.228
                                    c-22.795-22.803-22.795-59.923,0.016-82.742C114.788,127.64,114.788,119.923,110.028,115.171z"/>
                                                <path d="M471.481,405.861L324.842,259.23c37.405-66.25,28.109-151.948-28.217-208.317C263.787,18.075,220.133,0,173.718,0
                                    C127.287,0,83.633,18.075,50.81,50.913c-67.717,67.74-67.701,177.979,0.02,245.738c32.85,32.823,76.488,50.897,122.919,50.897
                                    c30.489,0,59.708-7.939,85.518-22.595L405.824,471.51c18.113,18.121,47.493,18.129,65.641,0
                                    c8.706-8.71,13.593-20.512,13.608-32.823C485.073,426.37,480.171,414.567,471.481,405.861z M85.28,262.191
                                    c-48.729-48.756-48.729-128.079-0.016-176.828c23.62-23.627,55.029-36.634,88.453-36.634c33.407,0,64.816,13.007,88.451,36.627
                                    c48.715,48.756,48.699,128.094-0.015,176.85c-23.62,23.612-55.014,36.612-88.406,36.612
                                    C140.341,298.818,108.919,285.811,85.28,262.191z"/>
                                            </g>
                                        </svg>
                                    </Typeahead>
                                    <div className='mb-0 text-danger'>{isEmptyNACE.msg}</div>
                                </Form.Group>
                            </div>
                            <Form.Group id="group-input-ambitNACE" className="col-md-6  mb-4  p-35">
                               

                                <Typeahead
                                    clearButton
                                    id="input-ambitNACE"
                                    inputProps={{ className: 'input-form-typeahead' }}
                                    disabled={singleSelectionsNACE.length === 0}
                                    labelKey="name"
                                    onChange={handleInputAmbitChange}
                                    options={optionsAmbitStateAuxNACE}
                                    placeholder="Seleccione un sector"
                                    selected={singleSelectionsAmbitNACE}
                                    {...propsTypeahead}
                                >
                                    <svg className={isEmptyAmbitNACE.status ? "col-md lens-image-error" : "col-md lens-image"} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 485.104 485.104" >
                                        <g>
                                            <path d="M110.028,115.171c-4.76-4.767-12.483-4.752-17.227,0c-32.314,32.33-32.314,84.898-0.016,117.197
                                    c2.38,2.379,5.487,3.569,8.614,3.569c3.123,0,6.234-1.19,8.613-3.569c4.76-4.76,4.76-12.469,0-17.228
                                    c-22.795-22.803-22.795-59.923,0.016-82.742C114.788,127.64,114.788,119.923,110.028,115.171z"/>
                                            <path d="M471.481,405.861L324.842,259.23c37.405-66.25,28.109-151.948-28.217-208.317C263.787,18.075,220.133,0,173.718,0
                                    C127.287,0,83.633,18.075,50.81,50.913c-67.717,67.74-67.701,177.979,0.02,245.738c32.85,32.823,76.488,50.897,122.919,50.897
                                    c30.489,0,59.708-7.939,85.518-22.595L405.824,471.51c18.113,18.121,47.493,18.129,65.641,0
                                    c8.706-8.71,13.593-20.512,13.608-32.823C485.073,426.37,480.171,414.567,471.481,405.861z M85.28,262.191
                                    c-48.729-48.756-48.729-128.079-0.016-176.828c23.62-23.627,55.029-36.634,88.453-36.634c33.407,0,64.816,13.007,88.451,36.627
                                    c48.715,48.756,48.699,128.094-0.015,176.85c-23.62,23.612-55.014,36.612-88.406,36.612
                                    C140.341,298.818,108.919,285.811,85.28,262.191z"/>
                                        </g>
                                    </svg>
                                </Typeahead>
                                <div className='mb-0 text-danger'>{isEmptyAmbitNACE.msg}</div>
                            </Form.Group>
                            <Form.Group id="group-input-activityNACE" className="col-md-6 mb-4  p-35" >
                                
                                <Typeahead
                                    clearButton
                                    id="input-activityNACE"
                                    labelKey="name"
                                    disabled={singleSelectionsNACE.length === 0}
                                    onChange={handleInputActivityChange}
                                    options={optionsActivityStateAuxNACE}
                                    inputProps={{ className: 'input-form-typeahead' }}
                                    placeholder="Seleccione una actividad económica"
                                    selected={singleSelectionsActivityNACE}
                                    {...propsTypeahead}
                                >
                                    <svg className={activityWarningNACE.status ? "col-md lens-image-error" : "col-md lens-image"} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 485.104 485.104" >
                                        <g>
                                            <path d="M110.028,115.171c-4.76-4.767-12.483-4.752-17.227,0c-32.314,32.33-32.314,84.898-0.016,117.197
                                    c2.38,2.379,5.487,3.569,8.614,3.569c3.123,0,6.234-1.19,8.613-3.569c4.76-4.76,4.76-12.469,0-17.228
                                    c-22.795-22.803-22.795-59.923,0.016-82.742C114.788,127.64,114.788,119.923,110.028,115.171z"/>
                                            <path d="M471.481,405.861L324.842,259.23c37.405-66.25,28.109-151.948-28.217-208.317C263.787,18.075,220.133,0,173.718,0
                                    C127.287,0,83.633,18.075,50.81,50.913c-67.717,67.74-67.701,177.979,0.02,245.738c32.85,32.823,76.488,50.897,122.919,50.897
                                    c30.489,0,59.708-7.939,85.518-22.595L405.824,471.51c18.113,18.121,47.493,18.129,65.641,0
                                    c8.706-8.71,13.593-20.512,13.608-32.823C485.073,426.37,480.171,414.567,471.481,405.861z M85.28,262.191
                                    c-48.729-48.756-48.729-128.079-0.016-176.828c23.62-23.627,55.029-36.634,88.453-36.634c33.407,0,64.816,13.007,88.451,36.627
                                    c48.715,48.756,48.699,128.094-0.015,176.85c-23.62,23.612-55.014,36.612-88.406,36.612
                                    C140.341,298.818,108.919,285.811,85.28,262.191z"/>
                                        </g>
                                    </svg>
                                </Typeahead>
                                <div className='mb-0 text-danger'>{activityWarningNACE.msg}</div>
                            </Form.Group>
                            <div className="mb-1">
                                <span className='text-bold'>3º) Seleccione el objetivo ambiental contra el que se desea evaluar la sostenibilidad de la operación  (*): </span>
                                {/* {questionHelp("hola")} */}
                            </div>
                            <Form.Group id="group-input-objetiveNACE" className="col-md-6  mb-1 p-35" >
                                <Typeahead
                                    clearButton
                                    disabled={optionsObjetiveStateAuxNACE.length === 0}
                                    inputProps={{ className: 'input-form-typeahead' }}
                                    id="input-objetiveNACE"
                                    labelKey="name"
                                    onChange={handleInputObjetivoChange}
                                    options={optionsObjetiveStateAuxNACE}
                                    placeholder="Seleccione un objetivo"
                                    selected={singleSelectionsObjetiveNACE}>
                                    <svg className={isEmptyObjetiveNACE.status ? "col-md lens-image-error" : "col-md lens-image"} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 485.104 485.104" >
                                        <g>
                                            <path d="M110.028,115.171c-4.76-4.767-12.483-4.752-17.227,0c-32.314,32.33-32.314,84.898-0.016,117.197
                                    c2.38,2.379,5.487,3.569,8.614,3.569c3.123,0,6.234-1.19,8.613-3.569c4.76-4.76,4.76-12.469,0-17.228
                                    c-22.795-22.803-22.795-59.923,0.016-82.742C114.788,127.64,114.788,119.923,110.028,115.171z"/>
                                            <path d="M471.481,405.861L324.842,259.23c37.405-66.25,28.109-151.948-28.217-208.317C263.787,18.075,220.133,0,173.718,0
                                    C127.287,0,83.633,18.075,50.81,50.913c-67.717,67.74-67.701,177.979,0.02,245.738c32.85,32.823,76.488,50.897,122.919,50.897
                                    c30.489,0,59.708-7.939,85.518-22.595L405.824,471.51c18.113,18.121,47.493,18.129,65.641,0
                                    c8.706-8.71,13.593-20.512,13.608-32.823C485.073,426.37,480.171,414.567,471.481,405.861z M85.28,262.191
                                    c-48.729-48.756-48.729-128.079-0.016-176.828c23.62-23.627,55.029-36.634,88.453-36.634c33.407,0,64.816,13.007,88.451,36.627
                                    c48.715,48.756,48.699,128.094-0.015,176.85c-23.62,23.612-55.014,36.612-88.406,36.612
                                    C140.341,298.818,108.919,285.811,85.28,262.191z"/>
                                        </g>
                                    </svg>
                                </Typeahead>
                                <div className='mb-0 text-danger'>{isEmptyObjetiveNACE.msg}</div>
                                <div className='mt-1 text-end'>
                                    {singleSelectionsActivityNACE.length === 0 ?
                                        <span className='cursor-not'>? Sugerencia sobre objetivo a seleccionar</span> :
                                        // optionsObjetiveStateAuxNACE.length === 1 ?
                                        //     <PopOver title={'? Sugerencia sobre objetivo a seleccionar'} message={<><BsCheckCircleFill className='me-2 color-succes' />{optionsObjetiveStateAuxNACE[0].id}</>} /> :
                                        //     <PopOver title={'? Sugerencia sobre objetivo a seleccionar'} message={<><p>Adaptación cambio climático</p><p>{<BsCheckCircleFill className='me-2 color-succes' />}Mitigación cambio climático</p></>} />   
                                        <PopOver title={'? Sugerencia sobre objetivo a seleccionar'} message={<>{optionsObjetiveStateAuxNACE.map((item, i) => (<p className={i + 1 === optionsObjetiveStateAuxNACE.length && 'mb-0'}>{item.id === singleSelectionsActivityNACE[0].suggestedObjective && <BsCheckCircleFill className='me-2 color-succes' />}{item.name}</p>))}</>} />
                                    }

                                </div>
                            </Form.Group>
                        </> :
                        <>
                            {/* //// Finalidad ///////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
                            <div className='input-pr-2'>
                                <Form.Group id="group-input-Purpose" className="col-md-6  mb-4 px-4">
                                    <Form.Label className='font'>Seleccione la finalidad de la actividad económica (*):</Form.Label>
                                    <Typeahead
                                        clearButton
                                        inputProps={{ className: 'input-form-typeahead' }}
                                        id="input-Purpose"
                                        labelKey={options => `${options.Id} - ${options.name}`}
                                        onChange={handleInputPurposeChange}
                                        options={optionsPurposeStateAux}
                                        placeholder="Seleccione una finalidad"
                                        selected={singleSelectionsPurpose}
                                    >
                                        <svg className={isEmptyPurpose.status ? "col-md lens-image-error" : "col-md lens-image"} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 485.104 485.104" >
                                            <g>
                                                <path d="M110.028,115.171c-4.76-4.767-12.483-4.752-17.227,0c-32.314,32.33-32.314,84.898-0.016,117.197
                                    c2.38,2.379,5.487,3.569,8.614,3.569c3.123,0,6.234-1.19,8.613-3.569c4.76-4.76,4.76-12.469,0-17.228
                                    c-22.795-22.803-22.795-59.923,0.016-82.742C114.788,127.64,114.788,119.923,110.028,115.171z"/>
                                                <path d="M471.481,405.861L324.842,259.23c37.405-66.25,28.109-151.948-28.217-208.317C263.787,18.075,220.133,0,173.718,0
                                    C127.287,0,83.633,18.075,50.81,50.913c-67.717,67.74-67.701,177.979,0.02,245.738c32.85,32.823,76.488,50.897,122.919,50.897
                                    c30.489,0,59.708-7.939,85.518-22.595L405.824,471.51c18.113,18.121,47.493,18.129,65.641,0
                                    c8.706-8.71,13.593-20.512,13.608-32.823C485.073,426.37,480.171,414.567,471.481,405.861z M85.28,262.191
                                    c-48.729-48.756-48.729-128.079-0.016-176.828c23.62-23.627,55.029-36.634,88.453-36.634c33.407,0,64.816,13.007,88.451,36.627
                                    c48.715,48.756,48.699,128.094-0.015,176.85c-23.62,23.612-55.014,36.612-88.406,36.612
                                    C140.341,298.818,108.919,285.811,85.28,262.191z"/>
                                            </g>
                                        </svg>
                                    </Typeahead>
                                    <div className='mb-0 text-danger'>{isEmptyPurpose.msg}</div>
                                </Form.Group>
                            </div>
                            <Form.Group id="group-input-ambitPurpose" className="col-md-6  mb-4 p-35">
                               

                                <Typeahead
                                    clearButton
                                    id="input-ambitPurpose"
                                    inputProps={{ className: 'input-form-typeahead' }}
                                    disabled={singleSelectionsPurpose.length === 0}
                                    labelKey="name"
                                    onChange={handleInputAmbitChange}
                                    options={optionsAmbitStateAuxPurpose}
                                    placeholder="Seleccione un sector"
                                    selected={singleSelectionsAmbitPurpose}
                                    {...propsTypeahead}
                                >
                                    <svg className={isEmptyAmbitPurpose.status ? "col-md lens-image-error" : "col-md lens-image"} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 485.104 485.104" >
                                        <g>
                                            <path d="M110.028,115.171c-4.76-4.767-12.483-4.752-17.227,0c-32.314,32.33-32.314,84.898-0.016,117.197
                                    c2.38,2.379,5.487,3.569,8.614,3.569c3.123,0,6.234-1.19,8.613-3.569c4.76-4.76,4.76-12.469,0-17.228
                                    c-22.795-22.803-22.795-59.923,0.016-82.742C114.788,127.64,114.788,119.923,110.028,115.171z"/>
                                            <path d="M471.481,405.861L324.842,259.23c37.405-66.25,28.109-151.948-28.217-208.317C263.787,18.075,220.133,0,173.718,0
                                    C127.287,0,83.633,18.075,50.81,50.913c-67.717,67.74-67.701,177.979,0.02,245.738c32.85,32.823,76.488,50.897,122.919,50.897
                                    c30.489,0,59.708-7.939,85.518-22.595L405.824,471.51c18.113,18.121,47.493,18.129,65.641,0
                                    c8.706-8.71,13.593-20.512,13.608-32.823C485.073,426.37,480.171,414.567,471.481,405.861z M85.28,262.191
                                    c-48.729-48.756-48.729-128.079-0.016-176.828c23.62-23.627,55.029-36.634,88.453-36.634c33.407,0,64.816,13.007,88.451,36.627
                                    c48.715,48.756,48.699,128.094-0.015,176.85c-23.62,23.612-55.014,36.612-88.406,36.612
                                    C140.341,298.818,108.919,285.811,85.28,262.191z"/>
                                        </g>
                                    </svg>
                                </Typeahead>
                                <div className='mb-0 text-danger'>{isEmptyAmbitPurpose.msg}</div>
                            </Form.Group>
                            <Form.Group id="group-input-activityPurpose" className="col-md-6 mb-4 p-35" >
                                
                                <Typeahead
                                    clearButton
                                    id="input-activityPurpose"
                                    labelKey="name"
                                    disabled={singleSelectionsPurpose.length === 0}
                                    onChange={handleInputActivityChange}
                                    options={optionsActivityStateAuxPurpose}
                                    inputProps={{ className: 'input-form-typeahead' }}
                                    placeholder="Seleccione una actividad económica"
                                    selected={singleSelectionsActivityPurpose}
                                    {...propsTypeahead}
                                >
                                    <svg className={activityWarningPurpose.status ? "col-md lens-image-error" : "col-md lens-image"} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 485.104 485.104" >
                                        <g>
                                            <path d="M110.028,115.171c-4.76-4.767-12.483-4.752-17.227,0c-32.314,32.33-32.314,84.898-0.016,117.197
                                    c2.38,2.379,5.487,3.569,8.614,3.569c3.123,0,6.234-1.19,8.613-3.569c4.76-4.76,4.76-12.469,0-17.228
                                    c-22.795-22.803-22.795-59.923,0.016-82.742C114.788,127.64,114.788,119.923,110.028,115.171z"/>
                                            <path d="M471.481,405.861L324.842,259.23c37.405-66.25,28.109-151.948-28.217-208.317C263.787,18.075,220.133,0,173.718,0
                                    C127.287,0,83.633,18.075,50.81,50.913c-67.717,67.74-67.701,177.979,0.02,245.738c32.85,32.823,76.488,50.897,122.919,50.897
                                    c30.489,0,59.708-7.939,85.518-22.595L405.824,471.51c18.113,18.121,47.493,18.129,65.641,0
                                    c8.706-8.71,13.593-20.512,13.608-32.823C485.073,426.37,480.171,414.567,471.481,405.861z M85.28,262.191
                                    c-48.729-48.756-48.729-128.079-0.016-176.828c23.62-23.627,55.029-36.634,88.453-36.634c33.407,0,64.816,13.007,88.451,36.627
                                    c48.715,48.756,48.699,128.094-0.015,176.85c-23.62,23.612-55.014,36.612-88.406,36.612
                                    C140.341,298.818,108.919,285.811,85.28,262.191z"/>
                                        </g>
                                    </svg>
                                </Typeahead>
                                <div className='mb-0 text-danger'>{activityWarningPurpose.msg}</div>
                            </Form.Group>
                            <div className="mb-1">
                                <span className='text-bold'>3º) Seleccione el objetivo ambiental contra el que se desea evaluar la sostenibilidad de la operación (*): </span>
                                {/* {questionHelp("hola")} */}
                            </div>
                            <Form.Group id="group-input-objetivePurpose" className="col-md-6  mb-1 p-35" >
                                <Typeahead
                                    clearButton
                                    disabled={optionsObjetiveStateAuxPurpose.length === 0}
                                    inputProps={{ className: 'input-form-typeahead' }}
                                    id="input-objetivePurpose"
                                    labelKey="name"
                                    onChange={handleInputObjetivoChange}
                                    options={optionsObjetiveStateAuxPurpose}
                                    placeholder="Seleccione un objetivo"
                                    selected={singleSelectionsObjetivePurpose}>
                                    <svg className={isEmptyObjetivePurpose.status ? "col-md lens-image-error" : "col-md lens-image"} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 485.104 485.104" >
                                        <g>
                                            <path d="M110.028,115.171c-4.76-4.767-12.483-4.752-17.227,0c-32.314,32.33-32.314,84.898-0.016,117.197
                                    c2.38,2.379,5.487,3.569,8.614,3.569c3.123,0,6.234-1.19,8.613-3.569c4.76-4.76,4.76-12.469,0-17.228
                                    c-22.795-22.803-22.795-59.923,0.016-82.742C114.788,127.64,114.788,119.923,110.028,115.171z"/>
                                            <path d="M471.481,405.861L324.842,259.23c37.405-66.25,28.109-151.948-28.217-208.317C263.787,18.075,220.133,0,173.718,0
                                    C127.287,0,83.633,18.075,50.81,50.913c-67.717,67.74-67.701,177.979,0.02,245.738c32.85,32.823,76.488,50.897,122.919,50.897
                                    c30.489,0,59.708-7.939,85.518-22.595L405.824,471.51c18.113,18.121,47.493,18.129,65.641,0
                                    c8.706-8.71,13.593-20.512,13.608-32.823C485.073,426.37,480.171,414.567,471.481,405.861z M85.28,262.191
                                    c-48.729-48.756-48.729-128.079-0.016-176.828c23.62-23.627,55.029-36.634,88.453-36.634c33.407,0,64.816,13.007,88.451,36.627
                                    c48.715,48.756,48.699,128.094-0.015,176.85c-23.62,23.612-55.014,36.612-88.406,36.612
                                    C140.341,298.818,108.919,285.811,85.28,262.191z"/>
                                        </g>
                                    </svg>
                                </Typeahead>
                                <div className='mb-0 text-danger'>{isEmptyObjetivePurpose.msg}</div>
                                <div className='mt-1 text-end'>
                                    {singleSelectionsActivityPurpose.length === 0 ?
                                        <span className='cursor-not'>? Sugerencia sobre objetivo a seleccionar</span> :
                                        // optionsObjetiveStateAuxPurpose.length === 1 ?
                                        //     <PopOver title={'? Sugerencia sobre objetivo a seleccionar'} message={<><BsCheckCircleFill className='me-2 color-succes' />{optionsObjetiveStateAuxNACE[0].id}</>} /> :
                                        //     <PopOver title={'? Sugerencia sobre objetivo a seleccionar'} message={<><p>Adaptación cambio climático</p><p>{<BsCheckCircleFill className='me-2 color-succes' />}Mitigación cambio climático</p></>} />   
                                        <PopOver title={'? Sugerencia sobre objetivo a seleccionar'} message={<>{optionsObjetiveStateAuxPurpose.map((item, i) => (<p className={i + 1 === optionsObjetiveStateAuxPurpose.length && 'mb-0'}>{item.id === singleSelectionsActivityPurpose[0].suggestedObjective && <BsCheckCircleFill className='me-2 color-succes' />}{item.name}</p>))}</>} />
                                    }

                                </div>
                            </Form.Group>
                        </>
                }
                <div className="col-md-12 align-self-end mb-4">
                    {loading ?
                        <Spinner animation="border" variant="primary" className="float-end" /> :
                        <div className='d-inline eva float-end' >
                            <div className='section d-none d-md-inline float-end'>
                                <Button className='me-3  justify-content-center btn btn-secondary ' onClick={() => navigate('/')}>Cancelar</Button>
                                <Button type="submit" className="btn btn-secondary mid-button float-end me-0" >Aceptar</Button>
                            </div>
                        </div>
                    }
                </div>
            </Form>
        </>);

}
