import React, { useContext } from 'react';
import { BrowserRouter as Routers } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css?v1';
import Navigator from './commons/navigator/navigator';
import Router from './router/router';
import Footer from './commons/footer/footer';
import { Context } from "./commons/utils/store";
import { UsuarioAutenticadoProvider } from "./context/authenticatedUser";


function App() {

  const [state, dispatch] = useContext(Context);

  //return user.auth ? <AuthApp /> : <UnauthApp />;

  const setWithExpiry = (key, value, ttl) => {
    const now = new Date()
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    }
    localStorage.setItem(key, JSON.stringify(item))
  }

  const itemStr = localStorage.getItem("token-taxo")
	if (itemStr) {
    const item = JSON.parse(itemStr)
    const now = new Date()
    if (now.getTime() > item.expiry) {
      localStorage.removeItem("token-taxo")
      localStorage.removeItem("id_usuario");
      localStorage.removeItem("desc_usuario");
      localStorage.removeItem("desc_entidad");
      localStorage.removeItem("id_entidad");
      localStorage.removeItem("alias");
      localStorage.setItem("sesion","caducada");
    }else{
      localStorage.removeItem("token-taxo")
      setWithExpiry("token-taxo",item.value,5400000)
      localStorage.setItem("sesion","activa");
    }
	}



  return (
    <Routers>
      {!state.hiddeSpinner &&
        <>
          <div className='w-100 h-100 d-flex fade-panel'>
            <div className='loading-text'>
              <h6>Cargando...</h6>
            </div>
            <div>
              <svg
                version="1.1"
                id="L7"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                className='loading-content'
              >
                <path
                  fill='var(--primary)'
                  d="M31.6,3.5C5.9,13.6-6.6,42.7,3.5,68.4c10.1,25.7,39.2,38.3,64.9,28.1l-3.1-7.9c-21.3,8.4-45.4-2-53.8-23.3
c-8.4-21.3,2-45.4,23.3-53.8L31.6,3.5z"
                >
                  <animateTransform
                    attributeName="transform"
                    attributeType="XML"
                    type="rotate"
                    dur="2s"
                    from="0 50 50"
                    to="360 50 50"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  fill='var(--primary)'
                  d="M42.3,39.6c5.7-4.3,13.9-3.1,18.1,2.7c4.3,5.7,3.1,13.9-2.7,18.1l4.1,5.5c8.8-6.5,10.6-19,4.1-27.7
c-6.5-8.8-19-10.6-27.7-4.1L42.3,39.6z"
                >
                  <animateTransform
                    attributeName="transform"
                    attributeType="XML"
                    type="rotate"
                    dur="1s"
                    from="0 50 50"
                    to="-360 50 50"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  fill='var(--primary)'
                  d="M82,35.7C74.1,18,53.4,10.1,35.7,18S10.1,46.6,18,64.3l7.6-3.4c-6-13.5,0-29.3,13.5-35.3s29.3,0,35.3,13.5
L82,35.7z"
                >
                  <animateTransform
                    attributeName="transform"
                    attributeType="XML"
                    type="rotate"
                    dur="2s"
                    from="0 50 50"
                    to="360 50 50"
                    repeatCount="indefinite"
                  />
                </path>
              </svg>
            </div>
          </div>
        </>
      }
      <header><Navigator /></header>


      <div className="body-content">




        <UsuarioAutenticadoProvider>
          <Router />
        </UsuarioAutenticadoProvider>
      </div>

      <Footer />
    </Routers>

  );
}
export default App;
