import React, { useState, useContext } from 'react';
import { Form, Tab, Tabs, Button, Modal } from 'react-bootstrap';
import './modalWarning.css';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';




export default function ModalSearchError({ block, input, header, message, show, url, handleContinue, handle, error, onlyButtonContinue = false, big = false, noButtons = false}) {
    
    const handleClose = () => handle(false);



    return (
        <Modal show={show} onHide={handleClose} centered size={big ? "lg" : "sm-down"}>
            <Modal.Header closeButton>{header}</Modal.Header>
            <Modal.Body className={input && 'modal-body-input'}>
                <p>{message}</p>
                {!noButtons && 
                <div className='options'>
                    <Button onClick={handleClose} variant="primary">Aceptar</Button>
                </div>
                }
            </Modal.Body>
        </Modal>
    )
}