import React, { useState, useEffect, useContext } from 'react';
import { Form, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css'
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css'
import "./uploadFile.css"
import { Grid } from 'semantic-ui-react';
import taxoApi from '../../api/taxoApi';
import { Context } from "../../commons/utils/store";
import { Typeahead, Menu, MenuItem, Highlighter } from 'react-bootstrap-typeahead';
import ModalWarning from '../../commons/modals/modalWarning'
import PopOver from '../../commons/popOver/popOver'
import { BsCheckCircleFill } from "react-icons/bs"

export default function UploadFile({ handle,handleShow,handleRefreshBlocks }) {

    const [state, dispatch] = useContext(Context);

    const [optionsActivityState, setOptionsActivityState] = useState([])
    const [optionsObjetiveState, setOptionsObjetiveState] = useState([])
    const [optionsAmbitState, setOptionsAmbitState] = useState([])
    const [optionsActivityStateAux, setOptionsActivityStateAux] = useState([])
    const [optionsObjetiveStateAux, setOptionsObjetiveStateAux] = useState([])
    const [optionsAmbitStateAux, setOptionsAmbitStateAux] = useState([])

    var optionsActivity = [];
    var optionsAmbit = [];
    var optionsObjetive = [];
    const propsTypeahead = {};


    const [activityWarning, setActivityWarning] = useState({ status: false, msg: "" })
    const [ambitError, setAmbitError] = useState({ status: false, msg: "" })
    const [isEmptyObjetive, setIsEmptyObjective] = useState({ status: false, msg: "" })
    const [singleSelectionsActivity, setSingleSelectionsActivity] = useState([])
    const [singleSelectionsAmbit, setSingleSelectionsAmbit] = useState([])
    const [singleSelectionsObjetive, setSingleSelectionsObjetive] = useState([])

    const [errorMsg, setErrorMsg] = useState()
    const [error, setError] = useState(false)

    const handleError = (click) => setError(click)

    useEffect(() => {
        dispatch({ type: "SET_HIDDEN", payload: false });
        taxoApi.getAllSectors().then((response, navigate) => {
            optionsAmbit = [];
            response.data.forEach(item => {
                optionsAmbit.push(item)
            })
            setOptionsAmbitState(optionsAmbit)
            setOptionsAmbitStateAux(optionsAmbit)
        })

        taxoApi.getAllObjetives().then((response, navigate) => {
            optionsObjetive = [];
            response.data.forEach(item => {
                optionsObjetive.push(item)
            })
            setOptionsObjetiveState(optionsObjetive)
            setOptionsObjetiveStateAux([])
        })

        taxoApi.getAllActivities().then((response, navigate) => {
            optionsActivity = [];
            response.data.forEach(item => {
                optionsActivity.push(item)
            })
            setOptionsActivityState(optionsActivity)
            setOptionsActivityStateAux(optionsActivity)
            dispatch({ type: "SET_HIDDEN", payload: true });
        })



    }, [])

    const [ficheroSeleccionado, setFicheroSeleccionado] = useState(null);

    const [inputs, setInputs] = useState({
        'blockName':null,
        'activity':null,
        'objective':null
    })

    const [nameError, setNameError] = useState({ status: false, msg: "" })
    const [fileTypeError, setFileTypeError] = useState({ status: false, msg: "" })

    const handleChange = (event) => {
        const value = event.target.value;
        const control = value.includes('\\') || value.includes('/') || value.includes(':') || value.includes('*') || value.includes('?') || value.includes('"') || value.includes('<') || value.includes('>') || value.includes('|')
        if (control) {
            setNameError({ status: true, msg: "No se permiten los caracteres: \\, /, :, *, ?, \", <, >, |" })
        }
        else {
            setNameError({ status: false, msg: "" })
            setInputs(values => ({ ...values, ['blockName']: value }))
        }
    }

    const handlePreupload = (event) => {
        event.preventDefault();
        setFicheroSeleccionado(event.target.files[0]);
        !event.target.files[0].name.endsWith('.csv') && setFileTypeError({ status: true, msg: "Solo se permiten ficheros de tipo csv" })
    };

    const handleAddFile = () => {
        if (ficheroSeleccionado !== "" && !nameError.status && !fileTypeError.status && inputs['blockName'] !== null && inputs['activity'] !== null && inputs['objective'] !== null) {
            try {
                //console.log("Subiendo el fichero:",ficheroSeleccionado, inputs['blockName'], inputs['activity'], inputs['objective'])
                const entidad = localStorage.getItem("desc_entidad")
                dispatch({ type: "SET_HIDDEN", payload: false });
                taxoApi.getTaxoUploadAppFile(ficheroSeleccionado, inputs['blockName'], inputs['activity'], inputs['objective'], entidad)
                    .then((response) => {
                        //console.log("response1",response)
                        if (response.data) {
                            taxoApi.processFile(response.data, inputs['activity'], inputs['objective'], entidad, inputs['blockName']).then((response) => {
                                //console.log("response2",response)
                                dispatch({ type: "SET_HIDDEN", payload: true });
                                setFicheroSeleccionado(null)
                                setInputs({})
                                handleRefreshBlocks()
                                handleShow(false)
                            })
                        }
                        else {
                            console.log("No se ha podido importar el fichero con los siguientes parámetros:",ficheroSeleccionado, inputs['blockName'], inputs['activity'], inputs['objective'])
                            setError(true)
                            dispatch({ type: "SET_HIDDEN", payload: true });
                            setErrorMsg("Error al cargar el fichero")
                        }
                    })
            }
            catch (err) {
                console.error(err)
            }
        }else{
            if(inputs['blockName'] === null){
                setNameError({ status: true, msg: "Debe indicar un nombre para el bloque" })
            }
            if(inputs['activity']  === null){
                setActivityWarning({ status: true, msg: "Debe seleccionar un Actividad económica" })
            }
            if(inputs['objective'] === null){
                setIsEmptyObjective({ status: true, msg: "Debe seleccionar un Objetivo" })
            }
            if(ficheroSeleccionado === "" || ficheroSeleccionado === null || ficheroSeleccionado === undefined ){
                setFileTypeError({ status: true, msg: "Debe adjuntar un fichero valido" })
            }
        }
    }

    const handleStopUpload = () => {
        setFileTypeError({ status: false, msg: "" })
        setFicheroSeleccionado("")
    }

    //método que permite que las actividades no implementadas estén pintadas en gris
    propsTypeahead.renderMenuItemChildren = (option, { text }) => (
        <>
            {option.implementationState === 1 ?
                <Highlighter search={text}>{option.name}</Highlighter>
                :
                <div className='disabled-option'>
                    <Highlighter search={text}>{option.name}</Highlighter>
                </div>
            }
        </>
    );


    function initializeInputActivity() {
        setSingleSelectionsActivity([])
        setOptionsActivityStateAux(optionsActivityState)
        setActivityWarning({ status: false, msg: "" });
    }

    function initializeInputObjetive() {
        setSingleSelectionsObjetive([])
        setIsEmptyObjective({ status: false, msg: "" });
    }

    const handleInputAmbitChange = (event) => {

        setAmbitError({ status: false, msg: "" });
        //setActivityWarning({ status: false, msg: "" });
        if (event !== null && event.length > 0) {

            let optionsActividadAux = []

            setSingleSelectionsAmbit(event);
            optionsActividadAux = optionsActivityState.filter((n) => {
                return n.idSector === event[0].id
            });
            setOptionsActivityStateAux(optionsActividadAux);
            setAmbitError({ status: false, msg: "" });

            if (optionsActividadAux.length === 1) {
                setSingleSelectionsActivity(optionsActividadAux)
                handleInputActivityChange(optionsActividadAux)
                setOptionsActivityStateAux(optionsActividadAux);
            }

            if (event[0].implementationState !== 1) {
                setAmbitError({ status: true, msg: "Sector no disponible" });
                setIsEmptyObjective({ status: false, msg: "" });
                setOptionsObjetiveStateAux([]);
                return
            }

        }
        else {
            setOptionsObjetiveStateAux([]);
            setSingleSelectionsAmbit([]);
            initializeInputActivity();
            initializeInputObjetive();
            setOptionsAmbitStateAux(optionsAmbitState)
            setInputs(values => ({ ...values, ['activity']: null }))
            setInputs(values => ({ ...values, ['objective']: null}))
        }
    }

    const handleInputActivityChange = (event) => {

        setActivityWarning({ status: false, msg: "" });
        var activitySelectedAux = []
        var optionsObjetivoAux = [];

        if (event !== null && event.length > 0) {
            setInputs(values => ({ ...values, ['activity']: event[0].id }))
            setSingleSelectionsActivity(event);
            activitySelectedAux = optionsActivityState.filter((n) => {
                return n.idSector === event[0].idSector
            });

            if (activitySelectedAux !== null && activitySelectedAux.length > 0) {
                if (event[0].implementationState === 1) {
                    optionsObjetivoAux = optionsObjetiveState.filter((n) => {
                        return event[0].id === n.idActivity;
                    })

                }
                setSingleSelectionsAmbit(optionsAmbitState.filter((n) => n.id === activitySelectedAux[0].idSector));
                setOptionsActivityStateAux(optionsActivityState.filter((n) => { return n.idSector === activitySelectedAux[0].idSector }));
                setActivityWarning({ status: false, msg: "" });

                if (optionsAmbitState.filter((n) => n.id === activitySelectedAux[0].idSector)[0].implementationState !== 1) {
                    setAmbitError({ status: true, msg: "Sector no disponible" });
                }
                else {
                    setAmbitError({ status: false, msg: "" });
                }
            }

            setOptionsObjetiveStateAux(optionsObjetivoAux);
            if (optionsObjetivoAux.length === 1) {
                setSingleSelectionsObjetive(optionsObjetivoAux)
                setInputs(values => ({ ...values, ['objective']: optionsObjetivoAux[0].id}))
            }

            if (event[0].implementationState !== 1) {
                setActivityWarning({ status: true, msg: "Actividad no disponible" });
                setIsEmptyObjective({ status: false, msg: "" });
                return
            }

        }
        else {
            setSingleSelectionsActivity([]);
            setSingleSelectionsObjetive([]);
            setOptionsObjetiveStateAux([]);
            setInputs(values => ({ ...values, ['activity']: null }))
            setInputs(values => ({ ...values, ['objective']: null}))
        }
    }



    const handleInputObjetivoChange = (event) => {

        if (event !== null && event.length > 0) {
            setInputs(values => ({ ...values, ['objective']: event[0].id }))
            setSingleSelectionsObjetive(event);
            setIsEmptyObjective({ status: false, msg: "" });
        } else {
            setIsEmptyObjective({ status: false, msg: "" });
            setSingleSelectionsObjetive([]);
            setInputs(values => ({ ...values, ['objective']: null}))
        }

    }

    return (
        <div>
            <ModalWarning header={<span className='section-title-modal'>Error</span>} message={errorMsg} show={error} handle={handleError} noButtons> </ModalWarning>

            <Form className="row">
                <Form.Label className="h6"> Seleccione la actividad:</Form.Label>
                <Form.Group id="group-input-ambit" className="mt-3 mb-0 dataFileSelector">
                    <Form.Label className='col-3 upload-label'>Sector</Form.Label>
                    <Typeahead className='col-9 upload-input-typeahead'
                        clearButton
                        id="input-ambit"
                        inputProps={{ className: 'input-form-typeahead3' }}
                        labelKey="name"
                        onChange={handleInputAmbitChange}
                        options={optionsAmbitStateAux}
                        placeholder="Seleccione un sector"
                        selected={singleSelectionsAmbit}
                        {...propsTypeahead}
                    >
                        <svg className={ambitError.status ? "col-md lens-image-error" : "col-md lens-image"} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 485.104 485.104" >
                            <g>
                                <path d="M110.028,115.171c-4.76-4.767-12.483-4.752-17.227,0c-32.314,32.33-32.314,84.898-0.016,117.197
                            c2.38,2.379,5.487,3.569,8.614,3.569c3.123,0,6.234-1.19,8.613-3.569c4.76-4.76,4.76-12.469,0-17.228
                            c-22.795-22.803-22.795-59.923,0.016-82.742C114.788,127.64,114.788,119.923,110.028,115.171z"/>
                                <path d="M471.481,405.861L324.842,259.23c37.405-66.25,28.109-151.948-28.217-208.317C263.787,18.075,220.133,0,173.718,0
                            C127.287,0,83.633,18.075,50.81,50.913c-67.717,67.74-67.701,177.979,0.02,245.738c32.85,32.823,76.488,50.897,122.919,50.897
                            c30.489,0,59.708-7.939,85.518-22.595L405.824,471.51c18.113,18.121,47.493,18.129,65.641,0
                            c8.706-8.71,13.593-20.512,13.608-32.823C485.073,426.37,480.171,414.567,471.481,405.861z M85.28,262.191
                            c-48.729-48.756-48.729-128.079-0.016-176.828c23.62-23.627,55.029-36.634,88.453-36.634c33.407,0,64.816,13.007,88.451,36.627
                            c48.715,48.756,48.699,128.094-0.015,176.85c-23.62,23.612-55.014,36.612-88.406,36.612
                            C140.341,298.818,108.919,285.811,85.28,262.191z"/>
                            </g>
                        </svg>
                    </Typeahead>
                </Form.Group>  
                <div style={{'marginLeft':'26%'}} className='mt-0 mb-3 text-danger'>{ambitError.msg}</div>

                <Form.Group id="group-input-activity" className="mt-3 mb-0 dataFileSelector">
                    <Form.Label className="col-3 upload-label">Actividad económica</Form.Label>
                    <Typeahead className="col-9 upload-input-typeahead"
                        clearButton
                        id="input-activity"
                        labelKey="name"
                        onChange={handleInputActivityChange}
                        options={optionsActivityStateAux}
                        inputProps={{ className: 'input-form-typeahead3' }}
                        placeholder="Seleccione una actividad económica"
                        selected={singleSelectionsActivity}
                        {...propsTypeahead}
                    >
                        <svg className={activityWarning.status ? "col-md lens-image-error" : "col-md lens-image"} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 485.104 485.104" >
                            <g>
                                <path d="M110.028,115.171c-4.76-4.767-12.483-4.752-17.227,0c-32.314,32.33-32.314,84.898-0.016,117.197
                                c2.38,2.379,5.487,3.569,8.614,3.569c3.123,0,6.234-1.19,8.613-3.569c4.76-4.76,4.76-12.469,0-17.228
                                c-22.795-22.803-22.795-59.923,0.016-82.742C114.788,127.64,114.788,119.923,110.028,115.171z"/>
                                <path d="M471.481,405.861L324.842,259.23c37.405-66.25,28.109-151.948-28.217-208.317C263.787,18.075,220.133,0,173.718,0
                                C127.287,0,83.633,18.075,50.81,50.913c-67.717,67.74-67.701,177.979,0.02,245.738c32.85,32.823,76.488,50.897,122.919,50.897
                                c30.489,0,59.708-7.939,85.518-22.595L405.824,471.51c18.113,18.121,47.493,18.129,65.641,0
                                c8.706-8.71,13.593-20.512,13.608-32.823C485.073,426.37,480.171,414.567,471.481,405.861z M85.28,262.191
                                c-48.729-48.756-48.729-128.079-0.016-176.828c23.62-23.627,55.029-36.634,88.453-36.634c33.407,0,64.816,13.007,88.451,36.627
                                c48.715,48.756,48.699,128.094-0.015,176.85c-23.62,23.612-55.014,36.612-88.406,36.612
                                C140.341,298.818,108.919,285.811,85.28,262.191z"/>
                            </g>
                        </svg>
                    </Typeahead>
                </Form.Group>
                <div style={{'marginLeft':'26%'}} className='mt-0 mb-3 text-danger'>{activityWarning.msg}</div>

                <Form.Group id="group-input-objetive" className="mt-3 mb-0 dataFileSelector">
                    <Form.Label className='col-3 upload-label'>Objetivo</Form.Label>
                    <Typeahead className="col-9"
                        clearButton
                        disabled={optionsObjetiveStateAux.length === 0}
                        inputProps={{ className: 'input-form-typeahead3' }}
                        id="input-objetive"
                        labelKey="name"
                        onChange={handleInputObjetivoChange}
                        options={optionsObjetiveStateAux}
                        placeholder="Seleccione un objetivo"
                        selected={singleSelectionsObjetive}>
                        <svg className={isEmptyObjetive.status ? "col-md lens-image-error" : "col-md lens-image"} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 485.104 485.104" >
                            <g>
                                <path d="M110.028,115.171c-4.76-4.767-12.483-4.752-17.227,0c-32.314,32.33-32.314,84.898-0.016,117.197
                                c2.38,2.379,5.487,3.569,8.614,3.569c3.123,0,6.234-1.19,8.613-3.569c4.76-4.76,4.76-12.469,0-17.228
                                c-22.795-22.803-22.795-59.923,0.016-82.742C114.788,127.64,114.788,119.923,110.028,115.171z"/>
                                <path d="M471.481,405.861L324.842,259.23c37.405-66.25,28.109-151.948-28.217-208.317C263.787,18.075,220.133,0,173.718,0
                                C127.287,0,83.633,18.075,50.81,50.913c-67.717,67.74-67.701,177.979,0.02,245.738c32.85,32.823,76.488,50.897,122.919,50.897
                                c30.489,0,59.708-7.939,85.518-22.595L405.824,471.51c18.113,18.121,47.493,18.129,65.641,0
                                c8.706-8.71,13.593-20.512,13.608-32.823C485.073,426.37,480.171,414.567,471.481,405.861z M85.28,262.191
                                c-48.729-48.756-48.729-128.079-0.016-176.828c23.62-23.627,55.029-36.634,88.453-36.634c33.407,0,64.816,13.007,88.451,36.627
                                c48.715,48.756,48.699,128.094-0.015,176.85c-23.62,23.612-55.014,36.612-88.406,36.612
                                C140.341,298.818,108.919,285.811,85.28,262.191z"/>
                            </g>
                        </svg>
                    </Typeahead>
                </Form.Group>
                <div style={{'marginLeft':'26%'}} className='mt-0 mb-3 text-danger'>{isEmptyObjetive.msg}</div>
                <label className="h6">Fichero de datos para evaluar:</label>
                <Grid.Column> <Form.Group controlId="formFileSm" className='answer py-2'>
                    <label className='col-3'>Nombre del bloque</label>
                    <input className='inputData col-9' type='text' name='blockName' onChange={handleChange}></input>
                </Form.Group>
                </Grid.Column> <div style={{'marginLeft':'26%'}} className='mt-0 mb-3 text-danger'>{nameError.msg}</div>


                <Grid.Column> <Form.Group controlId="formFileSm" className='answer py-2'>
                    <label className='col-3'>Adjuntar fichero</label>
                    <div className="col-8 inputFile">
                        <label for="formFileSm" className='fakeInput col-9'>{ficheroSeleccionado ? ficheroSeleccionado.name : "Seleccionar fichero"}</label>
                        <Button onClick={() => handleStopUpload()} className='btnX col-3'>
                            <img alt="" src="data:image/svg+xml;base64,PHN2ZyBzdHlsZT0iY29sb3I6IHJnYig4NywgODksIDk4KTsiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2IiBmaWxsPSJjdXJyZW50Q29sb3IiIGNsYXNzPSJiaSBiaS14IiB2aWV3Qm94PSIwIDAgMTYgMTYiPiA8cGF0aCBkPSJNNC42NDYgNC42NDZhLjUuNSAwIDAgMSAuNzA4IDBMOCA3LjI5M2wyLjY0Ni0yLjY0N2EuNS41IDAgMCAxIC43MDguNzA4TDguNzA3IDhsMi42NDcgMi42NDZhLjUuNSAwIDAgMS0uNzA4LjcwOEw4IDguNzA3bC0yLjY0NiAyLjY0N2EuNS41IDAgMCAxLS43MDgtLjcwOEw3LjI5MyA4IDQuNjQ2IDUuMzU0YS41LjUgMCAwIDEgMC0uNzA4eiIgZmlsbD0iIzU3NTk2MiI+PC9wYXRoPiA8L3N2Zz4=" />
                        </Button>
                    </div>
                    <Form.Control type="file" className='upload-inputFile mb-2' onChange={(event) => handlePreupload(event)} />

                    <Button variant="primary" className="col-1" onClick={() => {
                        //if(ficheroSeleccionado && ficheroSeleccionado.name){
                            return handleAddFile()
                        //}else{
                        //    return null
                        //}
                        }
                    }>Subir</Button>
                </Form.Group> </Grid.Column>
                <div style={{'marginLeft':'26%'}} className='mt-0 mb-3 text-danger'>{fileTypeError.msg}</div>
            </Form>
        </div>
    );
}