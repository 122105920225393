import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { FaRegCalendarAlt } from 'react-icons/fa'
import taxoApi from '../../api/taxoApi';
import { Context } from "../utils/store";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Table from '../table/tableSearch'
import dayjs from 'dayjs';
import moment from 'moment';
import customParseFormat from "dayjs/plugin/customParseFormat";
import './searchFilter.css'
import ModalSearchError from '../modals/modalSearchError'


export default function SearchFilter({ data, pDataType = "0", handleTable, handleShow }) {

    const [filtrar, setFiltrar] = useState(false)
    const [valueFrom, setValueFrom] = useState();
    const [valueTo, setValueTo] = useState();
    const [valueCalendarFrom, setValueCalendarFrom] = useState();
    const [valueCalendarTo, setValueCalendarTo] = useState();
    const [showFrom, setShowFrom] = useState(false);
    const [showTo, setShowTo] = useState(false);
    const [products, setProducts] = useState(data);
    const [emptyFiltersMsg,setEmptyFiltersMsg] = useState()
    const [errorMsg,setErrorMsg] = useState()
    const [errorResult, setErrorResult] = useState(false);
    const [count, setCount] = useState();
    const [searchMsg, setSearchMsg] = useState();
    const [selectedType, setSelectedType] = useState("individual");
    const [isVisibleBlock, setIsVisibleBlock] = useState(false)

    const [dataType, setDataType] = useState() //Lets identify data type  queries(0) or blocks(1)
    const [executionState, setExecutionState] = useState([])
    const refFrom = useRef(null);
    const refTo = useRef(null);

    const navigate = useNavigate();

    const onChangeFrom = (event) => {
        setValueFrom(event)
        var aux = new Date(event)
        setValueCalendarFrom(aux.toLocaleDateString())
        refFrom.current.focus()
        setShowFrom(false)
    }

    const onChangeTo = (event) => {
        setValueTo(event)
        var aux = new Date(event)
        setValueCalendarTo(aux.toLocaleDateString())
        refTo.current.focus()
        setShowTo(false)
    }

    const search = <svg className="col-md lens-image" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 485.104 485.104" >
        <g>
            <path d="M110.028,115.171c-4.76-4.767-12.483-4.752-17.227,0c-32.314,32.33-32.314,84.898-0.016,117.197
        c2.38,2.379,5.487,3.569,8.614,3.569c3.123,0,6.234-1.19,8.613-3.569c4.76-4.76,4.76-12.469,0-17.228
        c-22.795-22.803-22.795-59.923,0.016-82.742C114.788,127.64,114.788,119.923,110.028,115.171z"/>
            <path d="M471.481,405.861L324.842,259.23c37.405-66.25,28.109-151.948-28.217-208.317C263.787,18.075,220.133,0,173.718,0
        C127.287,0,83.633,18.075,50.81,50.913c-67.717,67.74-67.701,177.979,0.02,245.738c32.85,32.823,76.488,50.897,122.919,50.897
        c30.489,0,59.708-7.939,85.518-22.595L405.824,471.51c18.113,18.121,47.493,18.129,65.641,0
        c8.706-8.71,13.593-20.512,13.608-32.823C485.073,426.37,480.171,414.567,471.481,405.861z M85.28,262.191
        c-48.729-48.756-48.729-128.079-0.016-176.828c23.62-23.627,55.029-36.634,88.453-36.634c33.407,0,64.816,13.007,88.451,36.627
        c48.715,48.756,48.699,128.094-0.015,176.85c-23.62,23.612-55.014,36.612-88.406,36.612
        C140.341,298.818,108.919,285.811,85.28,262.191z"/>
        </g>
    </svg>;

    const handleSearch = (e, id) => {
       var data = {
            ambitId : singleSelectionsAmbit[0] !== undefined && !isVisibleBlock ? singleSelectionsAmbit[0].id : null, 
            activityId : singleSelectionsActivity[0] !== undefined && !isVisibleBlock  ? singleSelectionsActivity[0].id : null, 
            objetiveId : singleSelectionsObjetive[0] !== undefined && !isVisibleBlock  ? singleSelectionsObjetive[0].id : null, 
            operationId : singleSelectionsOp[0] !== undefined && !isVisibleBlock ? singleSelectionsOp : null,
            blqName : singleSelectionsBlock[0] !== undefined && isVisibleBlock ? singleSelectionsBlock : null,
            status: singleSelectionsStatus[0] !== undefined && !isVisibleBlock  ? singleSelectionsStatus[0].id : null,
            dateFrom : valueFrom !== undefined && valueFrom !== "" && !isVisibleBlock ? moment(valueFrom).format('YYYY-MM-DD') : null,
            dateTo : valueTo !== undefined && valueTo !== "" && !isVisibleBlock ? moment(valueTo).format('YYYY-MM-DD') : null,
            isHome : 0
        }
        if ((singleSelectionsAmbit[0] == undefined &&
            singleSelectionsAmbit[0] == undefined &&
            singleSelectionsActivity[0] == undefined &&
            singleSelectionsObjetive[0] == undefined &&  
            singleSelectionsOp[0] == undefined &&      
            singleSelectionsStatus[0] == undefined && 
            valueFrom == undefined && 
            valueTo == undefined && !isVisibleBlock)            
            ||
            (singleSelectionsBlock[0] == undefined && isVisibleBlock)
            ){
                setErrorMsg("Debe seleccionar algún filtro de búsqueda")
                setErrorResult(true)
            }else{
                dispatch({ type: "SET_HIDDEN", payload: false });
                
                taxoApi.searchSaveQueries(data, navigate)
                    .then((response) => {
                        if (response.status === 200) {   
                            setCount(response.count);
                            if(response.data.length == 1000){
                                if(response.count != -1){
                                    setErrorMsg("Se han encontrado "+ response.count +" coincidencias, por favor acote más los resultados de búsqueda. \n Solo he mostraran los primeros 1000 resultados.")
                                }
                                else{
                                    setErrorMsg("El número de coincidencias excede el límite, por favor acote más los resultados de búsqueda. \n Solo he mostraran los primeros 1000 resultados.")
                                }
                                setErrorResult(true);
                            }
                            handleTable(response.data)
                            if (typeof handleShow === "function") {
                                handleShow(true)
                            }
                        } else {
                            setErrorMsg("Se ha producido un error en la búsqueda")
                            setErrorResult(true)
                        }  
                        dispatch({ type: "SET_HIDDEN", payload: true });
                    })
                }     
    }


    const [state, dispatch] = useContext(Context);

    var optionsActivity = [];
    var optionsAmbit = [];
    var optionsObjetive = [];

    //// Por Sector o actividad económica /////////////////////////////////////////////////////////////////////
    const [optionsActivityState, setOptionsActivityState] = useState([])
    const [optionsObjetiveState, setOptionsObjetiveState] = useState([])
    const [optionsAmbitState, setOptionsAmbitState] = useState([])
    const [optionsActivityStateAux, setOptionsActivityStateAux] = useState([])
    const [optionsObjetiveStateAux, setOptionsObjetiveStateAux] = useState([])
    const [optionsAmbitStateAux, setOptionsAmbitStateAux] = useState([])

    useEffect(() => {
        dispatch({ type: "SET_HIDDEN", payload: false });
        setDataType(pDataType)
        if (pDataType === "1") {
            setExecutionState([{ "id": 1, "name": "Pendiente" }, { "id": 2, "name": "Ejecutando" },
            { "id": 3, "name": "Completado" }, { "id": 4, "name": "Error" }])
        }
        else if (pDataType === "2") {
            setExecutionState([{ "id": 1, "name": "Alineada" }, { "id": 2, "name": "No alineada" }])
        }
        else {
            setExecutionState([{ "id": 1, "name": "Activo" }, { "id": 2, "name": "Finalizado" }, { "id": 3, "name": "Error" }])
        }

        taxoApi.getAllSectors().then((response, navigate) => {
            optionsAmbit = [];
            response.data.forEach(item => {
                optionsAmbit.push(item)
            })
            setOptionsAmbitState(optionsAmbit)
            setOptionsAmbitStateAux(optionsAmbit)
        })


        taxoApi.getAllObjetives().then((response, navigate) => {
            optionsObjetive = [];
            response.data.forEach(item => {
                optionsObjetive.push(item)
            })
            setOptionsObjetiveState(optionsObjetive)
            setOptionsObjetiveStateAux(optionsObjetive)
        })

        taxoApi.getAllActivities().then((response, navigate) => {
            optionsActivity = [];
            response.data.forEach(item => {
                optionsActivity.push(item)
            })
            setOptionsActivityState(optionsActivity)
            setOptionsActivityStateAux(optionsActivity)
            dispatch({ type: "SET_HIDDEN", payload: true });
        })
    }, [])

    const [loading, setLoading] = useState(false);

    const [isEmptyActivity, setIsEmptyActivity] = useState({ status: false, msg: "" })
    const [isEmptyAmbit, setIsEmptyAmbit] = useState({ status: false, msg: "" })
    const [isEmptyObjetive, setIsEmptyObjective] = useState({ status: false, msg: "" })
    const [singleSelectionsActivity, setSingleSelectionsActivity] = useState([])
    const [singleSelectionsAmbit, setSingleSelectionsAmbit] = useState([])
    const [singleSelectionsObjetive, setSingleSelectionsObjetive] = useState([])
    const [singleSelectionsStatus, setSingleSelectionsStatus] = useState([])
    const [singleSelectionsOp, setSingleSelectionsOp] = useState([])
    const [singleSelectionsBlock, setSingleSelectionsBlock] = useState([])

    function initializeInputActivity() {
        setSingleSelectionsActivity([])
        setOptionsActivityStateAux(optionsActivityState)
    }

    function initializeInputObjetive() {
        setSingleSelectionsObjetive([])
        setOptionsObjetiveStateAux(optionsObjetiveState)
    }

    const handleInputAmbitChange = (event) => {
        if (event != null && event.length > 0) {
            let optionsActividadAux = []

            setSingleSelectionsAmbit(event);
            optionsActividadAux = optionsActivityState.filter((n) => {
                return n.idSector === event[0].id
            });
            setOptionsActivityStateAux(optionsActividadAux);
            setIsEmptyAmbit({ status: false, msg: "" });
        }
        else {
            setOptionsObjetiveStateAux([]);
            setSingleSelectionsAmbit([]);
            initializeInputActivity();
            initializeInputObjetive();
            setOptionsAmbitStateAux(optionsAmbitState)
        }
    }

    const handleInputActivityChange = (event) => {
        var activitySelectedAux = []
        var optionsObjetivoAux = [];

        if (event != null && event.length > 0) {
            setSingleSelectionsActivity(event);

            activitySelectedAux = optionsActivityState.filter((n) => {
                return n.idSector === event[0].idSector
            });

            if (activitySelectedAux !== null && activitySelectedAux.length > 0) {
                optionsObjetivoAux = optionsObjetiveState.filter((n) => {
                    return event[0].id === n.idActivity;
                })
                setSingleSelectionsAmbit(optionsAmbitState.filter((n) => n.id === activitySelectedAux[0].idSector));
                setOptionsActivityStateAux(optionsActivityState.filter((n) => { return n.idSector === activitySelectedAux[0].idSector }));
                setIsEmptyActivity({ status: false, msg: "" });
                setIsEmptyAmbit({ status: false, msg: "" });
            }

            setOptionsObjetiveStateAux(optionsObjetivoAux);
            if (optionsObjetivoAux.length === 1) {
                setSingleSelectionsObjetive(optionsObjetivoAux)

            }
        }
        else {
            setSingleSelectionsActivity([]);
            setSingleSelectionsObjetive([]);
            setOptionsObjetiveStateAux([]);
        }

    }

    const handleInputObjetivoChange = (event) => {
        if (event != null && event.length > 0) {
            setSingleSelectionsObjetive(event);
            setIsEmptyObjective({ status: false, msg: "" });
        } else {
            setSingleSelectionsObjetive([]);
        }
    }

    const handleInputStatus = (event) => {
        if (event != null && event.length > 0) {
            setSingleSelectionsStatus(event);
        } else {
            setSingleSelectionsStatus([]);
        }
    }

    const handleInputOp = (event) => {
        if (event !== null && event !== undefined) {
            setSingleSelectionsOp(event.target.value);
        } else {
            setSingleSelectionsOp([]);
        }
    }

    const handleInputBlock = (event) => {
        if (event !== null && event !== undefined) {
            setSingleSelectionsBlock(event.target.value);
        } else {
            setSingleSelectionsBlock([]);
        }
    }

    const handleOnBlurFrom = (event) => {
        if (event !== undefined && event !== null && event.relatedTarget !== undefined && event.relatedTarget !== null) {
            return
        }
        else {
            setShowFrom(false)
        }
    }

    const handleOnBlurTo = (event) => {
        if (event !== undefined && event !== null && event.relatedTarget !== undefined && event.relatedTarget !== null) {
            return
        }
        else {
            setShowTo(false)
        }
    }
    const handleClickState = (event) => {
        setErrorResult(false);
        setEmptyFiltersMsg("")
        setErrorMsg("")
        //searchMsg("")
    }
    const handlerDeleteQuery = (event) => {
        setErrorResult(false);
    }

    const handleTypeChange = (event) => {
        if(selectedType === "bloques"){
            setSelectedType("individual");
        }
        else{
            setSelectedType("bloques");
        }
        //handleTable([])
        setIsVisibleBlock(!isVisibleBlock);
    }

    async function handleSubmit(event) {
        event.preventDefault(); // Evita la recarga
        try {
            if (singleSelectionsAmbit != null && singleSelectionsAmbit.length > 0
                && singleSelectionsActivity != null && singleSelectionsActivity.length > 0
                && singleSelectionsObjetive != null && singleSelectionsObjetive.length > 0) {
                setLoading(true)
                var url = "/evaluation/1/" + singleSelectionsAmbit[0].id + "/" + singleSelectionsActivity[0].id + "/" + singleSelectionsObjetive[0].id;
                navigate(url);
            } else {
                if (singleSelectionsActivity === null || (singleSelectionsActivity != null && singleSelectionsActivity.length < 1)) {
                    setIsEmptyActivity({ status: true, msg: "Por favor, seleccione una actividad" });
                }
                if (singleSelectionsAmbit === null || (singleSelectionsAmbit != null && singleSelectionsAmbit.length < 1)) {
                    setIsEmptyAmbit({ status: true, msg: "Por favor, seleccione un sector" });
                }
                if (singleSelectionsObjetive === null || (singleSelectionsObjetive != null && singleSelectionsObjetive.length < 1)) {
                    setIsEmptyObjective({ status: true, msg: "Por favor, seleccione un objetivo" });
                }
            }
        } catch (error) {
            console.log(error);
        }

    };

    function workDate(dateInput) {
        var dateParts = dateInput.split('-')
        return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])
    }

    const clearCalendarFrom = () => {
        setValueCalendarFrom('')
        setValueFrom('')
    }

    const clearCalendarTo = () => {
        setValueCalendarTo('')
        setValueTo('')
    }

    return (
        <React.Fragment>
           {/*  <Form className="row">
                <Form.Group id="group-input-type" className="d-flex">
                    <Form.Check 
                        type="radio"
                        label="Busqueda Individual"
                        name="radioGroup"
                        id="radioType2"
                        value="individual"
                        checked={selectedType === 'individual'}
                        onChange={handleTypeChange}
                    />
                    <Form.Check 
                        type="radio"
                        label="Busqueda por Bloque"
                        name="radioGroup"
                        id="radioType1"
                        value="bloques"
                        checked={selectedType === 'bloques'}
                        onChange={handleTypeChange}
                    />
                    
                </Form.Group>
            </Form>   */}
            <Form className="row mt-3">
                {!isVisibleBlock && <Form.Group id="group-input-ambit" className="col-md-4  mb-4">
                    <Form.Label className='font'>Sector</Form.Label>
                    <Typeahead
                        clearButton
                        id="input-ambit"
                        inputProps={{ className: 'input-form-typeahead' }}
                        labelKey="name"
                        onChange={handleInputAmbitChange}
                        options={optionsAmbitStateAux}
                        placeholder="Seleccione un sector"
                        selected={singleSelectionsAmbit}
                    >

                        {search}
                    </Typeahead>
                    <div className='mb-0 text-danger'></div>
                </Form.Group>}
                
                {!isVisibleBlock && <Form.Group id="group-input-activity" className="col-md-4 mb-4" >
                    <Form.Label className='font'>Actividad</Form.Label>
                    <Typeahead
                        clearButton
                        id="input-activity"
                        labelKey="name"
                        onChange={handleInputActivityChange}
                        options={optionsActivityStateAux}
                        inputProps={{ className: 'input-form-typeahead' }}
                        placeholder="Seleccione una actividad económica"
                        selected={singleSelectionsActivity}
                    >
                        {search}
                    </Typeahead>
                    <div className='mb-0 text-danger'></div>
                </Form.Group>}
                {!isVisibleBlock && <Form.Group id="group-input-objetive" className="col-md-4 mb-4" >
                    <Form.Label className='font'>Objetivo</Form.Label>
                    <Typeahead
                        clearButton
                        disabled={singleSelectionsActivity.length === 0}
                        id="input-objetive"
                        labelKey="name"
                        onChange={handleInputObjetivoChange}
                        options={optionsObjetiveStateAux}
                        inputProps={{ className: 'input-form-typeahead' }}
                        placeholder="Seleccione un objetivo"
                        selected={singleSelectionsObjetive}
                    >
                        {search}
                    </Typeahead>
                    <div className='mb-0 text-danger'></div>
                </Form.Group>}
            </Form>

            <Form className="row">
            {!isVisibleBlock && <Form.Group id="group-input-ambit" className="col-md-4  mb-4">
                    <Form.Label className='font'>{dataType === "1" ? 'Nombre bloque' : 'Operación'}</Form.Label>
                    <div className='position-relative'>
                        <Form.Control onChange={handleInputOp} value={singleSelectionsOp} type='text' className='ps-0 input-form-typeahead' placeholder={dataType === "1" ? 'Escriba un nombre de bloque' : 'Escriba un nombre de operación'} />
                        {singleSelectionsOp && singleSelectionsOp.length !== 0 &&
                            <button className="close btn-close rbt-close lens-clear" type="button" onClick={() => setSingleSelectionsOp('')}><span className="rbt-close-content">×</span></button>
                        }
                    </div>
                    {/* <Typeahead
                        clearButton
                        id="input-ambit"
                        inputProps={{ className: 'ps-0 input-form-typeahead' }}
                        labelKey="name"
                        onChange={handleInputAmbitChange}
                        options={optionsAmbitStateAux}
                        placeholder="Seleccione una operación"
                    selected={singleSelectionsAmbit}
                    >
                    </Typeahead> */}
                    <div className='mb-0 text-danger'></div>
                </Form.Group>}
                {!isVisibleBlock && <Form.Group id="group-input-activity" className="col-md-4 mb-4" >
                    <Form.Label className='font'>Estado</Form.Label>
                    <Typeahead
                        clearButton
                        id="input-activity"
                        labelKey="name"
                        onChange={handleInputStatus}
                        options={executionState}
                        inputProps={{ className: 'ps-0 input-form-typeahead' }}
                        placeholder="Seleccione un estado"
                        selected={singleSelectionsStatus}
                    >
                        {/* {search} */}
                    </Typeahead>
                    <div className='mb-0 text-danger'></div>
                </Form.Group>}

                {isVisibleBlock && <Form.Group id="group-input-ambit" className="col-md-4  mb-4">
                    <Form.Label className='font'>{dataType === "1" ? 'Nombre bloque' : 'Bloque'}</Form.Label>
                    <div className='position-relative'>
                        <Form.Control onChange={handleInputBlock} value={singleSelectionsBlock} type='text' className='ps-0 input-form-typeahead' placeholder='Escriba un nombre de bloque'/>
                        {singleSelectionsBlock && singleSelectionsBlock.length !== 0 &&
                            <button className="close btn-close rbt-close lens-clear" type="button" onClick={() => setSingleSelectionsBlock('')}><span className="rbt-close-content">×</span></button>
                        }
                    </div>
                    <div className='mb-0 text-danger'></div>
                </Form.Group>}
                    
            </Form>

            <Form className="row position-relative">
            {!isVisibleBlock && <Form.Group id="group-input-activity" className="col-md-4 mb-4" >
                    <Form.Label className='font'>Desde</Form.Label>
                    <div className='position-relative'>
                        <Form.Control ref={refFrom} className='input-form-typeahead' value={valueCalendarFrom} placeholder='Seleccione una fecha desde' onClick={() => setShowFrom(!showFrom)} onBlur={handleOnBlurFrom} />
                        <FaRegCalendarAlt className="col-md lens-image cursor-pointer" onClick={() => setShowFrom(!showFrom)} />
                        {valueCalendarFrom && valueCalendarFrom !== '' &&
                            <button className="close btn-close rbt-close lens-clear" type="button" onClick={() => clearCalendarFrom()}><span className="rbt-close-content">×</span></button>
                        }
                    </div>

                    {showFrom &&
                        <Calendar className='position-absolute border-1 border-color-secondary mt-3' onChange={onChangeFrom} value={valueFrom} />
                    }

                    <div className='mb-0 text-danger'></div>
                </Form.Group>}
                {!isVisibleBlock && <Form.Group id="group-input-activity" className="col-md-4 mb-4" >
                    <Form.Label className='font'>Hasta</Form.Label>
                    <div className='position-relative'>
                        <Form.Control ref={refTo} className='input-form-typeahead' value={valueCalendarTo} placeholder='Seleccione una fecha desde' onClick={() => setShowTo(!showTo)} onBlur={handleOnBlurTo} />
                        <FaRegCalendarAlt className="col-md lens-image cursor-pointer" onClick={() => setShowTo(!showTo)} />
                        {valueCalendarTo && valueCalendarTo !== '' &&
                            <button className="close btn-close rbt-close lens-clear" type="button" onClick={() => clearCalendarTo()}><span className="rbt-close-content">×</span></button>
                        }

                    </div>

                    {showTo &&
                        <Calendar className='position-absolute border-1 border-color-secondary mt-3' onChange={onChangeTo} value={valueTo} />
                    }
                    {/* <Typeahead
                        clearButton
                        id="input-activity"
                        type='input'
                        labelKey="name"
                        options={[]}
                        inputProps={{ className: 'input-form-typeahead' }}
                        placeholder="Seleccione una fecha hasta"
                    >
                        <FaRegCalendarAlt className="col-md lens-image" />
                    </Typeahead> */}
                    <div className='mb-0 text-danger'></div>
                </Form.Group>}
                <div className="col-md-12 align-self-end mb-4">
                    <Button className="btn btn-secondary mid-button float-end me-0" onClick={() => handleSearch()}>
                        Aceptar
                    </Button>

                </div>
            </Form>

            {/* <ModalSearchError header={<span className='section-title-modal'>Aviso</span>} message={searchMsg} show={errorResult} handle={handleClickState} > </ModalSearchError>
            <ModalSearchError header={<span className='section-title-modal'>Aviso</span>} message={emptyFiltersMsg} show={errorResult} handle={handleClickState} > </ModalSearchError> */}
            <ModalSearchError header={<span className='section-title-modal'>Aviso</span>} message={errorMsg} show={errorResult} handle={handleClickState} > </ModalSearchError>

        </React.Fragment>
    );
}