import React, { useState, useEffect, useContext } from 'react';
import { Context } from "../utils/store";
import { Modal, Button } from 'react-bootstrap';
import './modalDocuments.css';
import taxoApi from '../../api/taxoApi';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { useNavigate } from 'react-router-dom';
// -----------
import { Tab,Tabs } from 'react-bootstrap';
import '../../pages/evaluation/evaluation.css'
import ButtonNewAction from '../../commons/buttonNewAction/buttonNewAction';
import ObjectiveImage from '../../commons/objectiveImage/objectiveImage'
import PopOver from '../../commons/popOver/popOver'
import { BsCheckCircleFill } from "react-icons/bs"
import { MdCancel } from "react-icons/md"

export default function ModalSummary({ show, handle,type,sectorId,activityId,objectiveId,status,evaluationId}) {

    const navigate = useNavigate();
    const [stateEvaluation, setStateEvaluation] = useState(0)
    const [scoringEvaluation, setScoringEvaluation] = useState(0)
    const [esBloque,setEsBloque] = useState(0);
    const [info, setInfo] = useState([])
    const [summary, setSummary] = useState([])
    const [state, dispatch] = useContext(Context);
    const [error, setError] = useState(false)
    const [prevPage,setPrevPage] = useState(1)
  
  
    const [dnshResult,setDnshResult] = useState("")
    const [dnshDetail,setDnshDetail] = useState("")
    const [dnshTotalDetail,setDnshTotalDetail] = useState("")
  
    const handleError = (click) => {
      setError(!click)
      navigate("/home")
    }
  
  
    const checkBloque = (evaluationId) => {
      taxoApi.getTaxoCheckBloque(evaluationId,navigate).then((response) => {
        if (response.response === 1) {
            setEsBloque(1)
        }
      })
    }
  
    useEffect(() => {
      dispatch({ type: "SET_HIDDEN", payload: false });
      let tipo = type
      let ruta = ""
      switch(tipo){
        case "1": 
          ruta = "/home";
          break
        case "2": 
          ruta = "/search";
          break
        case "3": 
          ruta = "/batchevaluation";
          break
        default: 
          ruta = "/home";
          break
      }
  
      setPrevPage(ruta)
  
      if (evaluationId !== undefined) { // esta finalizada
        checkBloque(evaluationId)
        taxoApi.getTaxoSummaryEvaluation(evaluationId,navigate).then((response, navigate) => {
          if(response.status == "OK"){
            setStateEvaluation(response.data[0].stateEvaluation)
            setScoringEvaluation(response.data[0].scoring)
            setInfo(response.data[0])
            let summary = response.summary
            setSummary(summary)
            setDnshResult(summary.some(e => e.evaluationType == "DNSH" && e.result == "No cumple")?"No Cumple":"Cumple")
            let detalles = summary.filter(e => e.evaluationType == "DNSH" && e.result == "No cumple")
            let detallesComp = summary.filter(e => e.evaluationType == "DNSH")
            setDnshDetail(detalles)
            setDnshTotalDetail(detallesComp)
          }else{
            setError(true)
          }
          
          dispatch({ type: "SET_HIDDEN", payload: true });
        })
      }
      else {
        // no esta finalizada
      }
    }, [])

    
    const handleDiscard = () => {
        handle(false)
    }
  
    return (<>

        <Modal show={show} onHide={handleDiscard}  centered size="xl">
            <Modal.Header className="docsTittle" closeButton>
                <div className='docsCabecera'>
                    <span>Resumen de Evaluación</span>
                </div>
            </Modal.Header>

            <Modal.Body>

                {(info && summary && summary[0]) &&
                <>
                    <div id='evaluation' className='section-block' style={{paddingTop:"0rem",marginTop:"0rem"}}>
                      <div className="section-content tabs-home">
                        <Tabs className='' defaultActiveKey="Resumen" transition={true} id="noanim-tab-example">
                          <Tab eventKey={"Resumen"} className="colorBlack" title={<div className='decoration list-eval'>Datos de la Operación</div>}>
                            <div className='row'>
                                <div className='col-sm-3 col-md-2 col-primary py-1'>
                                    Operación
                                </div>
                                <div className='col-sm-9 col-md-10 col-secondary py-1'>
                                    {info.date} {info.idOperation}
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-3 col-md-2 col-primary py-1'>
                                    Objetivo
                                </div>
                                <div className='col-sm-9 col-md-10 col-secondary py-1'><span>
                                    <ObjectiveImage objectiveId={info.idObjective} block={'block-size'} /> {info.nameObjective}
                                </span>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-3 col-md-2 col-primary py-1'>
                                    Sector
                                </div>
                                <div className='col-sm-9 col-md-10 col-secondary py-1'>
                                    {info.idSector}-{info.nameSector}
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-3 col-md-2 col-primary-end py-1'>
                                    Actividad
                                </div>
                                <div className='col-sm-9 col-md-10 col-secondary-end py-1'>
                                    <div>
                                        {info.activityCode}. {info.nameActivity}
                                        <div className='float-end color-secondary cursor-pointer'>
                                            <PopOver title={'Descripción'} message={info.activityDesc} position={'auto'} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{backgroundColor:"white",paddingTop:"0rem",paddingBottom:"0rem"}}className='special-block result-block aligmentResults-block'>
                              <div className='bg-white special-content'>

                                <div className='row' style={{marginBottom:"1rem"}}>
                                  <div className='col-sm-6 col-md-6 py-1 '>
                                    <div className='summaryAligment-titleContainer'>
                                      <span className='summaryAligment-title'> Alineamiento Actividad </span>
                                    </div>
                                  </div>
                                  <div className='col-sm-6 col-md-6 py-1 summaryAligment-detail state-end pr'>                        
                                    {stateEvaluation == 1 ?
                                    <Button className='button-state align-items-center width-10rem summaryAligment-badge'>
                                      <BsCheckCircleFill className='color-succes pb-0 me-2 text-bg' />
                                      <span className='d-none d-sm-inline'>Alineada</span>
                                    </Button> :
                                    <Button className='button-state align-items-center width-10rem summaryAligment-badge'>
                                      <MdCancel className='color-fail pb-0 me-2 text-bg' />
                                      <span className='d-none d-sm-inline'>No alineada</span>
                                    </Button> 
                                    } 
                                    <span className='summaryAligment-subtitle'> Scoring </span>
                                    <span> {scoringEvaluation + "%"} </span>                      
                                  </div>
                                </div>
                                <div className='summary-block'>
                                {
                                (summary && summary[0] && summary.length !== 0) &&
                                <>
                                  <div className='row'>
                                    <div className='col-sm-8 col-md-6 col-primary py-2'>
                                        <span className='summaryResult-title'>Contribución Sustancial</span>
                                    </div>
                                    <div className='col-sm-4 col-md-6 col-secondary py-2'>
                                        <span>{summary.filter(e => e.evaluationType == "CS")[0]["result"]}</span>
                                    </div>
                                  </div>
                                  {
                                    (summary.filter(e => e.evaluationType == "CS")[0]["detail"] != "") &&
                                    <div className='row'>
                                      <div className='col-secondary py-2'>
                                        <span className='summaryResult-detail'>{summary.filter(e => e.evaluationType == "CS")[0]["detail"]}</span>
                                      </div>
                                    </div>
                                  }
                                  <div className='row'>
                                    <div className='col-sm-8 col-md-6 col-primary py-2'>
                                        <span className='summaryResult-title'>No Causar Daño no Significativo</span>
                                    </div>
                                    <div className='col-sm-4 col-md-6 col-secondary py-2'>
                                        <span>{dnshResult}</span>
                                    </div>
                                  </div>
                                  {
                                    dnshTotalDetail.map(e => (
                                      <div className='row'>
                                        <div className='col-sm-8 col-md-6 col-secondary py-2'>
                                            <span className='summaryResult-subtitle'>{e.objetiveName}</span>
                                        </div>
                                        <div className='col-sm-4 col-md-6 col-secondary py-2'>
                                            <span>{e.result}</span>
                                        </div>
                                      </div>
                                    ))
                                  }
                                  {
                                    (dnshResult === "No Cumple") &&
                                    <div className='row'>
                                      <div className='col-secondary py-2'>
                                      {dnshDetail[0] && dnshDetail.map(e => (<span className='summaryResult-Dnshdetail'>{"- " + e.detail}</span>))}
                                      </div>
                                    </div>
                                  }
                                  <div className='row'>
                                    <div className='col-sm-8 col-md-6 col-primary-end py-2'>
                                        <span className='summaryResult-title'>Mínimas Salvaguardas</span>
                                    </div>
                                    <div className='col-sm-4 col-md-6 col-secondary-end py-2'>
                                        <span>{summary.filter(e => e.evaluationType == "SALV")[0]["result"]}</span>
                                    </div>
                                  </div>
                                  {
                                    (summary.filter(e => e.evaluationType == "SALV")[0]["detail"] != "") &&
                                    <div className='row'>
                                      <div className='col-secondary-end py-2'>
                                        <span className='summaryResult-detail'>{summary.filter(e => e.evaluationType == "SALV")[0]["detail"]}</span>
                                      </div>
                                    </div>
                                  }     
                                </>
                                }
                              </div>
                              </div>    
                            </div>
                            
                          </Tab>
                        </Tabs>
                      </div>
                    </div>
                </>
                }
            </Modal.Body>
        </Modal>
    </>
    )
}