import React, { useState, createContext } from 'react';
import { useNavigate, Navigate, useLocation } from "react-router-dom";
//var jwt = require('jsonwebtoken');


export const UsuarioAutenticadoContext = createContext({
  usuarioAutenticado: null,
  user: null,/*provisional  */
  password: null /*provisional  */
});

function estoyLogueado(loc) {
  const stored = localStorage.getItem("token-taxo");
  if ((stored !== null && stored !== undefined && stored !== "caducado") || loc === "/login") {
    return true
  }
  return false
}

export function UsuarioAutenticadoProvider({ children }) {
  const history = useNavigate();
  //const token = localStorage.getItem('token-taxo');
  let nombre = null;
  let rol = null;
  let correo = null;
  let principal = null;

  /*let user = user;
  let password = password*/

  const location = useLocation();
  try {
    var decoded = { "name": "xxx", "rol": "1", "correo": "xxx@xxx.com", "principal": "XXXX" };//jwt.verify(token, '3A8CE926BE618912BEDEC2755E7D1',{ algorithms: ['HS256'] });
    //localStorage.setItem('token-taxo', token);

    nombre = decoded.name;
    rol = decoded.rol;
    correo = decoded.correo;
    principal = decoded.principal;
    //TODO: Sacar los datos del usuario
  } catch (err) {
    console.log(err);
    localStorage.removeItem('token-taxo');
    history.push("/login");
    // err
  }
  const [usuarioAutenticado, setUsuarioAutenticado] = useState({ nombre, rol, correo, principal } || null);
  //const [usuarioAutenticado, setUsuarioAutenticado] = useState({ user, password } || null);

  const provider = {
    usuarioAutenticado,
    usuarioAutenticadoChange: selected => {
      var decoded = { "name": "xxx", "rol": "1", "correo": "xxx@xxx.com", "principal": "XXXX" };//jwt.verify(selected, '3A8CE926BE618912BEDEC2755E7D1',{ algorithms: ['HS256'] });
      nombre = decoded.name;
      rol = decoded.rol;
      correo = decoded.correo;
      principal = decoded.principal;
      setUsuarioAutenticado({ nombre, rol, correo, principal });
    }
  };

  return (
    <UsuarioAutenticadoContext.Provider value={provider}>
      {estoyLogueado(location.pathname) ? children : <Navigate to='/login' />}
    </UsuarioAutenticadoContext.Provider>
  );
};
