import React, { createContext, useReducer } from "react";
import Reducer from "./reducer";

const initialState = {
  hiddeSpinner: true
};

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};

export const Context = createContext(initialState);
export default Store;

// import React from 'react';

// export const loadingState = (state) => {
//     return {hiddeSpinner : state}
//   }
  
//   export const initialState = {
//     hiddeSpinner: true
//   }
 

//   export const Context = React.createContext(initialState)