class taxoApi {

  static getAllSectors(navigate) {

    return fetch("https://o90nm1zsn0.execute-api.eu-west-1.amazonaws.com/taxo-allsectors", {
      method: 'get',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value }
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static getAllActivities(navigate) {
    return fetch("https://o90nm1zsn0.execute-api.eu-west-1.amazonaws.com/taxo-allactivities", {
      method: 'get',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value }
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static getAllObjetives(navigate) {
    return fetch("https://o90nm1zsn0.execute-api.eu-west-1.amazonaws.com/taxo-allobjetives", {
      method: 'get',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value }
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static getAllSectorInstitutionals(navigate) {
    return fetch("https://o90nm1zsn0.execute-api.eu-west-1.amazonaws.com/taxo-allSectorInstitutionals", {
      method: 'get',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value }
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static getAllQuestions(navigate) {
    return fetch("https://o90nm1zsn0.execute-api.eu-west-1.amazonaws.com/taxo-objetivequestion", {
      method: 'GET',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

    static getAllActivities(navigate) {
    return fetch("https://o90nm1zsn0.execute-api.eu-west-1.amazonaws.com/taxo-allactivities", {
      method: 'get',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value }
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static getSaveQueries(navigate){
    const key = localStorage.getItem("id_entidad")
    const raw = JSON.stringify({entityId: key});
    return fetch("https://o90nm1zsn0.execute-api.eu-west-1.amazonaws.com/taxo-saveQueries", {
      method: 'POST',
      headers: {Authorization: JSON.parse(localStorage.getItem("token-taxo")).value},
      body: raw
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static getInfoDashboard(navigate){
    const key = localStorage.getItem("id_entidad")
    const raw = JSON.stringify({entityId: key});
    return fetch("https://o90nm1zsn0.execute-api.eu-west-1.amazonaws.com/taxo-GetInfoDashboard", {
      method: 'POST',
      headers: {Authorization: JSON.parse(localStorage.getItem("token-taxo")).value},
      body: raw
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static searchSaveQueries(response, navigate){
    const key = localStorage.getItem("id_entidad")
    const raw = JSON.stringify({data: response, entityId: key});
    return fetch("https://o90nm1zsn0.execute-api.eu-west-1.amazonaws.com/taxo-searchSaveQueries", {
      method: 'POST',
      headers: {Authorization: JSON.parse(localStorage.getItem("token-taxo")).value},
      body: raw
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  



  static getObjectiveActivity(activityId, objectiveId, operationId, navigate) {
    const url = 'https://o90nm1zsn0.execute-api.eu-west-1.amazonaws.com/taxo-objetivequestion';
    // const myHeaders = new Headers({ Authorization: localStorage.getItem("token-imcapp") });
    //   const myHeaders = new Headers()
    /* myHeaders.append('user', 'admin')
     myHeaders.append('password', 'admin')
     myHeaders.append('Content-Type', 'application/json;charset=UTF-8')
     myHeaders.append('Access-Control-Allow-Origin','*')*/

    const controllerc = new AbortController()
    const signalc = controllerc.signal

    const key = localStorage.getItem("id_entidad")
    const raw = JSON.stringify({ activityId: activityId, objectiveId: objectiveId, operationId: operationId,entityId: key });
    // const options = {
    //   method: "POST",
    //   signal: signalc,
    //   headers: {
    //     user: 'admin',
    //     password: 'admin'
    //   },
    //   body: raw,
    // };
    //return fetch(url, options)
    return fetch(url, {
      method: 'POST',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
      body: raw
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json();
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static getObjectiveActivityResponse(activityId, objectiveId, evaluationId, navigate) {
    const key = localStorage.getItem("id_entidad")
    const url = 'https://o90nm1zsn0.execute-api.eu-west-1.amazonaws.com/taxo-objetivequestionresponse';

    const controllerc = new AbortController()
    const signalc = controllerc.signal


    const raw = JSON.stringify({ activityId: activityId, objectiveId: objectiveId, evaluationId: evaluationId,entityId: key});

    return fetch(url, {
      method: 'POST',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
      body: raw
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json();
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static getTaxoReopenEvaluation(response, navigate) {
    const url = 'https://o90nm1zsn0.execute-api.eu-west-1.amazonaws.com/taxo-reopenEvaluation';
    const raw = JSON.stringify({ data: response });
    const options = {
      method: "POST",
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
      body: raw,
    };
    return fetch(url, options)
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        else if (response.ok) {
          return response.json()
        }
        else {
          throw new Error(response);
        }
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 404,
          statusText: 'No se ha podido finalizar la evaluación correctamente',
          error: error
        }
      }));
  }

  static getTaxoEndEvaluation(response, navigate) {
    const url = 'https://o90nm1zsn0.execute-api.eu-west-1.amazonaws.com/taxo-endEvaluation';
    response.entityName = localStorage.getItem("desc_entidad")
    const raw = JSON.stringify({ data: response });
    const options = {
      method: "POST",
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
      body: raw,
    };
    return fetch(url, options)
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        else if (response.ok) {
          return response.json()
        }
        else {
          throw new Error(response);
        }
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 404,
          statusText: 'No se ha podido finalizar la evaluación correctamente',
          error: error
        }
      }));
  }

  static getTaxoSave(id, response, navigate) {
    const url = 'https://o90nm1zsn0.execute-api.eu-west-1.amazonaws.com/taxo-saveEvaluation';
    response.userId = localStorage.getItem("id_usuario")
    const raw = JSON.stringify({ idEvaluationType: id, data: response });
    const options = {
      method: "POST",
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
      body: raw,
    };
    return fetch(url, options)
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        else if (response.ok) {
          return response.json()
        }
        else {
          throw new Error(response);
        }
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 404,
          statusText: 'No se ha podido guardar correctamente',
          error: error
        }
      }));
  }

  static getTaxoSaveComment(id, commentIn, navigate) {
    
    const url = 'https://o90nm1zsn0.execute-api.eu-west-1.amazonaws.com/taxo-saveQueryComments';
    const raw = JSON.stringify({ idEvaluation: id, comment: commentIn });
    const options = {
      method: "POST",
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
      body: raw,
    };
    return fetch(url, options)
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        else if (response.ok) {
          return response.json()
        }
        else {
          throw new Error(response);
        }
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 404,
          statusText: 'No se ha podido guardar correctamente el comentario',
          error: error
        }
      }));
  }

  static getTaxoGetQueryComments(id, navigate) {
    const url = 'https://o90nm1zsn0.execute-api.eu-west-1.amazonaws.com/taxo-getQueryComments';
    const raw = JSON.stringify({ idEvaluation: id });
    const options = {
      method: "POST",
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
      body: raw,
    };
    return fetch(url, options)
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        else if (response.ok) {
          return response.json()
        }
        else {
          throw new Error(response);
        }
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 404,
          statusText: 'No se ha podido recuperar correctamente los comentarios',
          error: error
        }
      }));
  }


  static getTaxoEvaluation(id, responses, navigate) {

    const url = 'https://o90nm1zsn0.execute-api.eu-west-1.amazonaws.com/taxo-evaluation';
    responses.userId = localStorage.getItem("id_usuario")

    const raw = JSON.stringify({ idEvaluationType: id, data: responses});
    const options = {
      method: "POST",
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
      body: raw,
    };
    return fetch(url, options)
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        else if (response.ok) {
          return response.json()
        }
        else {
          throw new Error(response);
        }
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos',
          error: error
        }
      }));
  }



  static getActivitiesNACE(navigate) {
    const controllerc = new AbortController()
    const signalc = controllerc.signal
    const url = 'https://o90nm1zsn0.execute-api.eu-west-1.amazonaws.com/taxo-ActivitiesNACE';
    //const raw = JSON.stringify({ NACEIdKey: naceId});
    const options = {
      method: "POST",
      signal: signalc,
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value }
    };
    return fetch(url, options)
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static getActivitiesPurpose(navigate) {

    const url = 'https://o90nm1zsn0.execute-api.eu-west-1.amazonaws.com/taxo-ActivitiesPurpose';
    //const raw = JSON.stringify({ NACEIdKey: naceId});
    const options = {
      method: "POST",
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value }
    };
    return fetch(url, options)
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static authLogin({ user, password }) {

    const url = 'https://o90nm1zsn0.execute-api.eu-west-1.amazonaws.com/taxo-login-auth';
    const myHeaders = new Headers();
    const raw = JSON.stringify({ user: user, pwd: password });
    const options = {
      method: "POST",
      headers: myHeaders,
      body: raw
    };
    return fetch(url, options)
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static getTaxoBloques(navigate) {
    const key = localStorage.getItem("id_entidad")
    const raw = JSON.stringify({entityId: key});
    return fetch("https://o90nm1zsn0.execute-api.eu-west-1.amazonaws.com/taxo-getBloques", {
      method: 'POST',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
      body: raw
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static getTaxoConsultasBloque(blockId, navigate) {
    const raw = JSON.stringify({ bloque: blockId});
    return fetch("https://o90nm1zsn0.execute-api.eu-west-1.amazonaws.com/taxo-getConsultasBloque", {
      method: 'POST',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
      body: raw
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static getTaxoSummaryEvaluation(evaluationId, navigate) {
    const raw = JSON.stringify({ evaluationId: evaluationId});
    return fetch("https://o90nm1zsn0.execute-api.eu-west-1.amazonaws.com/taxo-SummaryEvaluation", {
      method: 'POST',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
      body: raw
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static getTaxoDownloadFile(Path, evaluationId, searchPath, navigate) {
    const raw = JSON.stringify({ bloque: Path, evaluationId:evaluationId, searchPath:searchPath });
    return fetch("https://o90nm1zsn0.execute-api.eu-west-1.amazonaws.com/taxo-downloadFile", {
      method: 'POST',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
      body: raw
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }
  
  static getTaxoCheckFile(evaluationId,fileType,navigate) {
    const raw = JSON.stringify({ evaluationId: evaluationId, fileType:fileType});
    // Si, Es una lambda reutilizada, habria que cambiarle el nombre.. :)
    return fetch("https://o90nm1zsn0.execute-api.eu-west-1.amazonaws.com/taxo-disableEvaluation", {
      method: 'POST',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
      body: raw
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static getTaxoCheckBloque(evaluationId,navigate) {
    const raw = JSON.stringify({ evaluationId: evaluationId});
    // Si, Es una lambda reutilizada, habria que cambiarle el nombre.. :)
    return fetch("https://o90nm1zsn0.execute-api.eu-west-1.amazonaws.com/taxo-generateEvaluationFile_TEST", {
      method: 'POST',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
      body: raw
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static getTaxoDeleteBatch(blockId, navigate) {
    const raw = JSON.stringify({ bloque: blockId});
    return fetch("https://o90nm1zsn0.execute-api.eu-west-1.amazonaws.com/taxo-deleteBatch", {
      method: 'DELETE',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
      body: raw
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static getTaxoDeleteQuery(id, navigate) {
    const raw = JSON.stringify({ idEvaluation: id});
    return fetch("https://o90nm1zsn0.execute-api.eu-west-1.amazonaws.com/taxo-deleteQuery", {
      method: 'DELETE',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
      body: raw
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static getTaxoUploadFile(evaluationId, file, type, navigate) {
    const raw = new FormData();
    raw.append('evaluationId', evaluationId)
    raw.append('fileTypeId', type)
    raw.append('File', file);
    

    return fetch("https://o90nm1zsn0.execute-api.eu-west-1.amazonaws.com/taxo-uploadQueryDocument", {
      method: 'POST',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value},
      body: raw
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static getTaxoUploadAppFile(file, blockName, activity, objective, entity, navigate) {
    const raw = new FormData();
    raw.append('blockName', blockName)
    raw.append('activity', activity)
    raw.append('objective', objective)
    raw.append('entity', entity)
    raw.append('File', file);
    

    return fetch("https://o90nm1zsn0.execute-api.eu-west-1.amazonaws.com/taxo-uploadAppFile", {
      method: 'POST',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value},
      body: raw
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }
  
  static getTaxoDocumentTypes(navigate) {
    return fetch("https://o90nm1zsn0.execute-api.eu-west-1.amazonaws.com/taxo-getDocumentTypes", {
      method: 'POST',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static getTaxoDocuments(id, navigate) {
    let raw = JSON.stringify({ evaluationId: id});
    return fetch("https://o90nm1zsn0.execute-api.eu-west-1.amazonaws.com/taxo-getQueryDocuments", {
      method: 'POST',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
      body: raw
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static deleteTaxoDocument(fileId, evaluationId, navigate) {
    let raw = JSON.stringify({ fileId: fileId, evaluationId: evaluationId});
    return fetch("https://o90nm1zsn0.execute-api.eu-west-1.amazonaws.com/taxo-deleteQueryDocument", {
      method: 'DELETE',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
      body: raw
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static processFile(key, activity, objective, entity, blockName, /* singleEv, */ navigate) {
    const user = localStorage.getItem("id_usuario")
    let raw = JSON.stringify({ key: key, activity: activity, objective: objective, blockName: blockName, user: user, entity: entity});
    return fetch("https://o90nm1zsn0.execute-api.eu-west-1.amazonaws.com/taxo-processingFile", {
      method: 'POST',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
      body: raw
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static evaluateBlock(idBloque, navigate) {
    const idUsuario = localStorage.getItem("id_usuario")
    let raw = JSON.stringify({ idBloque: idBloque , idUsuario: idUsuario});
    return fetch("https://o90nm1zsn0.execute-api.eu-west-1.amazonaws.com/taxo-batchEvaluation", {
      method: 'POST',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
      body: raw
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static deleteComment(idComment, navigate) {
    let raw = JSON.stringify({ idComment: idComment});
    return fetch("https://o90nm1zsn0.execute-api.eu-west-1.amazonaws.com/taxo-deleteComment", {
      method: 'POST',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
      body: raw
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

}



export default taxoApi;
