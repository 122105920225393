import { width } from '@mui/system';
import { hasSelectionSupport } from '@testing-library/user-event/dist/utils';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { MdOutlineHelpOutline } from "react-icons/md"
import PopOver from '../../../commons/popOver/popOver'

export default function ComponentQuestions(props) {
    const [objPreguntas, setObjPreguntas] = useState(props.preguntas)
    const [objQuestionByOrderId, setObjQuestionByOrderId] = useState([])
    const [objQuestionMalformed, setObjQuestionMalformed] = useState(false)
    const [hasRelatedQuestions, setHasRelatedQuestions] = useState(false)

    const [maxSizeQuestionLabel, setMaxSizeQuestionLabel] = useState(0)
    const [maxSizeQuestionLabelB, setMaxSizeQuestionLabelB] = useState(0)
    const [maxSizeAux, setMaxSizeAux] = useState(0)
    var pinted = []

    const [windowSize, setWindowSize] = useState(window.innerWidth)

    const updateSize = () => {
        setWindowSize(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', updateSize);
        return () => window.removeEventListener('resize', updateSize);
    }, [])

    const questionLabel = (obj) => {
        let sty
        if(windowSize > 1327){
            sty = {width: `${obj.formPosition === 0 ? maxSizeQuestionLabel : maxSizeQuestionLabelB}px`}
        }else{
            if(windowSize > ((maxSizeAux * 7) + 260 + 64)){
                if(maxSizeAux * 6.35 > 700){
                    sty = { width: 700 }
                }else{
                    sty = { width: maxSizeAux * 6.48 }
                }
            }else{
                sty = { width: `calc(100% - 260px)` }
            }
        }

        let label =
            <div className='question label-field-18' style={sty}>
                <span>{obj.nameQuestion}</span>
            </div>
        return label
    }

    const questionHelp = (obj) => {
        let units = obj.unitQuestion !== undefined && obj.unitQuestion !== '' ? obj.unitQuestion : null
        let pos = windowSize > 575 ? undefined : 'bottom'
        let help =
            <div className='info-field d-inline color-secondary is-not-disabled'>
                <PopOver title={<MdOutlineHelpOutline className='h6' />} message={((props.idAct == 7007 || props.idAct == 6005) && props.tab == "SALV")?"(Respuesta Opcional) "+obj.descQuestion:obj.descQuestion} units={units} position='bottom' />
            </div>
        return help
    }

    const calculateQuestionSize = () => {
        let hasRelatedQuestion = false;
        let maxSizeQuestionLabel = 0;
        let maxSizeQuestionLabelb = 0;

        Object.entries(props.preguntas).map(([key, obj]) => {
            var nameQuestion = obj.nameQuestion
            if (maxSizeQuestionLabel < nameQuestion.length && obj.formPosition === 0) {
                maxSizeQuestionLabel = nameQuestion.length
            }

            if (maxSizeQuestionLabelb < nameQuestion.length && obj.formPosition === 1) {
                maxSizeQuestionLabelb = nameQuestion.length
            }

            if (obj.relatedQuestion && !hasRelatedQuestion) {
                hasRelatedQuestion = true
            }
        })

        setHasRelatedQuestions(hasRelatedQuestion)
        setMaxSizeAux(maxSizeQuestionLabel > maxSizeQuestionLabelb ? maxSizeQuestionLabel : maxSizeQuestionLabelb)
        if (hasRelatedQuestion) {
            setMaxSizeQuestionLabel(maxSizeQuestionLabel * 7 > 335 ? 335 : maxSizeQuestionLabel * 7)
            setMaxSizeQuestionLabelB(maxSizeQuestionLabelb * 7 > 335 ? 335 : maxSizeQuestionLabelb * 6.57)
        } else {
            setMaxSizeQuestionLabel(maxSizeQuestionLabel * 6.35 > 700 ? 700 : maxSizeQuestionLabel * 6.48)
        }
    }

    const createDictQuestionByOrderId = (questions) => {
        var dictByOrder = {};
        var objMalformed = false;

        var items = Object.keys(questions).map(function (key) {
            return [key, questions[key]];
        });

        // Sort the array based on the second element
        items.sort(function (first, second) {
            return first[1].orderId - second[1].orderId;
        });

        items.forEach(function (value, index) {
            if (value[1].orderId !== undefined && !(value[1].orderId in dictByOrder)) {
                dictByOrder[value[1].orderId] = value[1]
            }
            else {
                objMalformed = true;
                return;
            }
        });

        setObjQuestionMalformed(objMalformed)
        setObjQuestionByOrderId(dictByOrder)


    }

    useEffect(() => {
        calculateQuestionSize()
        createDictQuestionByOrderId(props.preguntas)
    }, [props.preguntas])

    const checkResponse = (question) => {
        return question !== "" && question !== null && question !== undefined && question.response !== "" && question.response !== null && question.response !== undefined
    }

    const chooseQuestion = (obj, isRelated, relatedId) => {
        let format = []
        let disabled = null

        //   7001 || 7002 || 7003 || 7004 || 7005 || 7006 || 7007 ||                            // Vivienda
        //   4001 || 4002 || 4003 || 4004 || 4005 || 4006 || 4007 || 4008 || 4028 || 4029 ||    // Energia
        //   6005 || 6005 || 6013 ||                                                            // Transporte
        //   3001 || 3006                                                                       // Fabricacion
        //   10001 || 10002                                                                     // Financiera y seguros

        switch(obj.idQuestion){
            case 1: //¿Existe informe de vulnerabilidad y riesgo climático? 7001, 7002, 7003, 7004, 7005, 7006, 7007, 4001 , 4002, 4003, 4004, 4005, 4006, 4007, 4008, 4028, 4029, 6005, 6006
                disabled = 
                    (checkResponse(objPreguntas["502"]) && objPreguntas["502"].response === "SI" ) 
                    || (checkResponse(objPreguntas["66"])  && objPreguntas["66"].response === "NO")
                break              
            case 3: //¿Se han llevado a cabo soluciones de adaptación para reducir los riesgos climáticos? 7001, 7002, 7003, 7004, 7005, 7006, 7007, 4001 , 4002, 4003, 4004, 4005, 4006, 4007, 4008, 4028, 4029, 6005, 6006
                disabled = 
                    (checkResponse(objPreguntas["502"]) && objPreguntas["502"].response === "SI" ) 
                    || (checkResponse(objPreguntas["66"])  && objPreguntas["66"].response === "NO")
                break
            case 6: // ¿Existe un plan de acción de implementación de las medidas de mitigación? 7001, 4001 , 4002, 4003, 4004, 4005, 4006, 4007, 4008, 4028, 4029, 6013
                disabled = 
                    checkResponse(objPreguntas["65"]) && objPreguntas["65"].response === "NO"
                break    
            case 10: //¿Se ha llevado a cabo la prevención o mitigación de los impactos referidos en los Descriptores 1 y 6? 4003
                disabled = 
                    checkResponse(objPreguntas["49"]) && objPreguntas["49"].response === "NO"
                break               
            case 18: //Clase consumo de los neumáticos 6005
            case 19: //Clase ruido de los neumáticos 6005
                disabled = 
                    checkResponse(objPreguntas["11"]) && objPreguntas["11"].response === "L"
                break
            case 24: // Demanda Energía Primaria (PED) 7007, EN 7001 ES OBLIGATORIO
                disabled = false  
                //(checkResponse(objPreguntas["45"]) && objPreguntas["45"].response < 2021)    
                //(checkResponse(objPreguntas["45"]) && objPreguntas["45"].response > 2020) && (props.idAct == 7007)
                break
            case 26: // ¿Se ha realizado test de estanqueidad e integridad térmica o un control de calidad? 7007, 7001
                disabled =  
                    (checkResponse(objPreguntas["45"]) && objPreguntas["45"].response < 2021)
                    || (checkResponse(objPreguntas["45"]) && checkResponse(objPreguntas["37"]) && objPreguntas["45"].response > 2020 && parseInt(objPreguntas["37"].response.toString().replaceAll(".","").replace(",",".")) <= 5000)
                    //|| (checkResponse(objPreguntas["45"]) && checkResponse(objPreguntas["37"]) && checkResponse(objPreguntas["71"]) && objPreguntas["45"].response > 2020 && parseInt(objPreguntas["37"].response.toString().replaceAll(".","").replace(",",".")) > 5000 && objPreguntas["71"].response === "NO RESIDENCIAL") 
                    || (checkResponse(objPreguntas["155"]) && objPreguntas["155"].response === "NO")
                    //|| (checkResponse(objPreguntas["155"]) && checkResponse(objPreguntas["71"]) && objPreguntas["155"].response === "SI" && objPreguntas["71"].response === "NO RESIDENCIAL")
                break
            case 28: //¿Se ha realizado un plan de gestion del uso y proteccion del agua? 7001, 4002, 4006, 4007, 4008, 4028, 4029, 6013
                disabled = 
                    checkResponse(objPreguntas["169"]) && objPreguntas["169"].response === "NO"
                    || (checkResponse(objPreguntas["5"]) && objPreguntas["5"].response === "SI" && props.idAct != 7001)
                break
            case 29: //¿Se ha realizado un plan de gestion del uso y proteccion del agua? 7001, 4002, 4006, 4007, 4008, 4028, 4029, 6013
                disabled = 
                    checkResponse(objPreguntas["71"]) && objPreguntas["71"].response === "RESIDENCIAL"
                break
            case 31: //¿Se han buscado y detectado contaminantes potenciales? 7001
                disabled = 
                    checkResponse(objPreguntas["64"]) && objPreguntas["64"].response === "NO"
                break
            case 37: // ¿Cuál es la superficie de construcción? 7007
                disabled = 
                    checkResponse(objPreguntas["45"]) && objPreguntas["45"].response < 2021
                break                
            case 47: //¿Se ha realizado un estudio y gestión adecuada del amianto? 7003
                disabled = 
                    checkResponse(objPreguntas["74"]) && objPreguntas["74"].response === "NO"
                break
            case 63: //¿Se ha llevado a cabo una evaluación de riesgos medioambientales relacionados con el agua? 7001 4002, 4006, 4007, 4008, 40028, 4029, 6013
                disabled = 
                    checkResponse(objPreguntas["5"]) && objPreguntas["5"].response === "SI"
                break
            case 66: // ¿Existen riesgos climáticos físicos que afectan a la actividad? 7001, 7002, 7003, 7004, 7005, 7006, 7007, 4001 , 4002, 4003, 4004, 4005, 4006, 4007, 4008, 4028, 4029, 6005, 6006
                disabled = 
                    checkResponse(objPreguntas["502"]) && objPreguntas["502"].response === "SI"
                break
            case 70: // Certificado de Eficiencia Energética (EPC) 7003, 7007
                disabled = 
                    checkResponse(objPreguntas["45"]) && objPreguntas["45"].response > 2020
                break
            case 71: // Edificio de grandes dimensiones ¿residencial o no residencial? 7007
                disabled =  
                    (checkResponse(objPreguntas["45"]) && objPreguntas["45"].response < 2021) 
                    || (checkResponse(objPreguntas["45"])  && checkResponse(objPreguntas["37"]) && objPreguntas["45"].response > 2020 && parseInt(objPreguntas["37"].response.toString().replaceAll(".","").replace(",",".")) <= 5000)
                    || (checkResponse(objPreguntas["155"]) && objPreguntas["155"].response === "NO")
                break
            case 72: // ¿Se dispone de certificación de edificio eficiente? 7007
                disabled =  
                    (checkResponse(objPreguntas["45"]) && objPreguntas["45"].response < 2021)
                    //|| (checkResponse(objPreguntas["45"]) && checkResponse(objPreguntas["37"]) && objPreguntas["45"].response > 2020 && parseInt(objPreguntas["37"].response.toString().replaceAll(".","").replace(",",".")) <= 5000) 
                    //|| (checkResponse(objPreguntas["45"]) && checkResponse(objPreguntas["37"]) && checkResponse(objPreguntas["71"]) && objPreguntas["45"].response > 2020 && parseInt(objPreguntas["37"].response.toString().replaceAll(".","").replace(",",".")) >= 5000 && objPreguntas["71"].response === "RESIDENCIAL")
                break
            case 93: //¿Las medidas se supervisan según las condiciones para lograr el buen estado del agua afectada? 4005
                disabled = 
                    checkResponse(objPreguntas["90"]) && !(objPreguntas["90"].response === "EXISTENTE")
                break
            case 95: // ¿Se ha evaluado el impacto en el estado del agua, hábitats y especies? 4005
                disabled = 
                    checkResponse(objPreguntas["90"]) && !(objPreguntas["90"].response === "SE VA A CONSTRUIR")
                break
            case 96: // ¿La central provoca algún deterioro del estado del agua o la compromete? 4005
                disabled = 
                    checkResponse(objPreguntas["90"]) && !(objPreguntas["90"].response === "SE VA A CONSTRUIR")
                break
            case 97: // ¿Existen razones de interes público o los costes de beneficios superan los del deterioro del agua? 4005
                disabled = 
                    (checkResponse(objPreguntas["90"]) && !(objPreguntas["90"].response === "SE VA A CONSTRUIR")) 
                    || (checkResponse(objPreguntas["96"]) && objPreguntas["96"].response === "NO")
                break
            case 98: // ¿La central compromete permanentemente el logro de un buen estado del agua? 4005
                disabled = 
                    checkResponse(objPreguntas["90"]) && !(objPreguntas["90"].response === "SE VA A CONSTRUIR")
                break
            
            /*   ESTA COMENTADO PORQUE ANA QUIERE QUE ESTE COMO EL EXCEL, DONDE PONE QUE TIENEN QUE SER OBLIGATORIAS

            case 101: //¿Se cumplen los criterios de reducción de emisiones? 4007, 4029
                disabled = 
                    checkResponse(objPreguntas["88"]) && parseInt(objPreguntas["88"].response.toString().replaceAll(".","").replace(",",".")) >= 100 
                break                   
            case 102: // ¿Se captura CO2 que habría sido emitido, y se transporta y almacena bajo tierra? 4007, 4029
                disabled = 
                    (checkResponse(objPreguntas["103"]) && objPreguntas["103"].response === "XXX") 
                    || (checkResponse(objPreguntas["88"]) && parseInt(objPreguntas["88"].response.toString().replaceAll(".","").replace(",",".")) >= 100  )
                break
            case 103: //¿En el transporte de CO2, se producen fugas? 4007,4029
                disabled = 
                    checkResponse(objPreguntas["88"]) && parseInt(objPreguntas["88"].response.toString().replaceAll(".","").replace(",",".")) >= 100
                    || checkResponse(objPreguntas["102"]) && objPreguntas["102"].response === "NO"
                break
            */
            
            case 107: //En la construcción, ¿se instalan equipos de vigilancia de las emisiones físicas? 4007, 4029
                disabled = 
                    checkResponse(objPreguntas["514"]) && parseInt(objPreguntas["514"].response.toString().replaceAll(".","").replace(",",".")) > 2030
                    || (checkResponse(objPreguntas["88"]) && parseInt(objPreguntas["88"].response.toString().replaceAll(".","").replace(",",".")) >= 270) 
                break
            case 108: //En la fase operativa, ¿se informa de las emisiones de metano y se eliminan las fugas? 4007 , 4029
                disabled = 
                    checkResponse(objPreguntas["107"]) && objPreguntas["107"].response === "SI"
                    || (checkResponse(objPreguntas["88"]) && parseInt(objPreguntas["88"].response.toString().replaceAll(".","").replace(",",".")) >= 270)
                    || (checkResponse(objPreguntas["514"]) && parseInt(objPreguntas["514"].response.toString().replaceAll(".","").replace(",",".")) > 2030)
                break
            case 109: //¿Se mezclan combustibles líquidos o gaseosos de fuentes renovables con biogás o biolíquidos? 4007, 4029
                disabled = 
                    checkResponse(objPreguntas["108"]) && objPreguntas["108"].response === "XXX"
                    || (checkResponse(objPreguntas["88"]) && parseInt(objPreguntas["88"].response.toString().replaceAll(".","").replace(",",".")) >= 270)
                    || (checkResponse(objPreguntas["514"]) && parseInt(objPreguntas["514"].response.toString().replaceAll(".","").replace(",",".")) > 2030)
                break
            case 110: //¿Se cumplen los criterios para la producción del biogás o los biolíquidos y la biomoasa forestal? 4007, 4029
                disabled = 
                    checkResponse(objPreguntas["514"]) && parseInt(objPreguntas["514"].response.toString().replaceAll(".","").replace(",",".")) > 2030
                    || (checkResponse(objPreguntas["88"]) && parseInt(objPreguntas["88"].response.toString().replaceAll(".","").replace(",",".")) >= 270)
                break
            case 113: //Emisiones (BAT) 4007, 4008, 4028, 4029
                disabled = 
                    (checkResponse(objPreguntas["112"]) && parseInt(objPreguntas["112"].response.toString().replaceAll(".","").replace(",",".")) < 1)
                break
            case 114: //¿Se cumplen los niveles de eficiencia energética asociados a las conclusiones (NEA-MTD)? 4008
                disabled = 
                    (checkResponse(objPreguntas["112"]) && (parseInt(objPreguntas["112"].response.toString().replaceAll(".","").replace(",",".")) < 50 || parseInt(objPreguntas["112"].response.toString().replaceAll(".","").replace(",",".")) > 100)) 
                break
            case 115: //¿Se cumplen los criterios de biomasa agrícola y biomasa forestal? 4008
                disabled = 
                    checkResponse(objPreguntas["120"]) && objPreguntas["120"].response === "NO"
                    || (checkResponse(objPreguntas["112"]) && parseInt(objPreguntas["112"].response.toString().replaceAll(".","").replace(",",".")) > 2)
                break
            case 116: //Porcentaje de reducción de las emisiones de GEI por el uso de biomasa 4008, 4029
                disabled = 
                    checkResponse(objPreguntas["120"]) && objPreguntas["120"].response === "NO"
                    || (checkResponse(objPreguntas["112"]) && parseInt(objPreguntas["112"].response.toString().replaceAll(".","").replace(",",".")) > 2)
                    || (checkResponse(objPreguntas["88"]) && parseInt(objPreguntas["88"].response.toString().replaceAll(".","").replace(",",".")) >= 270)
                    || (checkResponse(objPreguntas["514"]) && parseInt(objPreguntas["514"].response.toString().replaceAll(".","").replace(",",".")) > 2030)
                break
            case 118: //¿Se cumplen los criterios de biomasa agrícola y biomasa forestal? 4008
                disabled = 
                    checkResponse(objPreguntas["117"]) && objPreguntas["117"].response === "NO"
                break
            case 119: //Porcentaje de reducción de las emisiones de GEI por el uso de biomasa 4008
                disabled = 
                    checkResponse(objPreguntas["117"]) && objPreguntas["117"].response === "NO"
                break
            case 120: //¿Las instalaciones de generación de electricidad utilizan combustibles gaseosos derivados de biomasa? 4008
                disabled = 
                    (checkResponse(objPreguntas["112"]) && parseInt(objPreguntas["112"].response.toString().replaceAll(".","").replace(",",".")) > 2)
                break
            case 121: //¿La actividad aplica la tecnología de cogeneración de alta eficiencia? 4008
            case 122: //¿Las instalaciones son únicamente eléctricas? 4008
                disabled = 
                    (checkResponse(objPreguntas["112"]) && (parseInt(objPreguntas["112"].response.toString().replaceAll(".","").replace(",",".")) < 50 || parseInt(objPreguntas["112"].response.toString().replaceAll(".","").replace(",",".")) > 100)) 
                break
            case 123: //Porcentaje de eficiencia eléctrica 4008
            case 124: //¿Se usa la generación combinada de calor y electricidad de alta eficiencia? 4008
            case 125: //¿Se utiliza una tecnología de captura y almacenamiento de carbono? 4008
                disabled = 
                    (checkResponse(objPreguntas["112"]) && parseInt(objPreguntas["112"].response.toString().replaceAll(".","").replace(",",".")) <= 100 ) 
                break   
            case 170: //¿Se dispone de certificación de Edificio Eficiente? 7007  
            disabled = 
                    (checkResponse(objPreguntas["45"]) && objPreguntas["45"].response < 2021) 
                    || (checkResponse(objPreguntas["45"]) && checkResponse(objPreguntas["37"]) && objPreguntas["45"].response > 2020 && parseInt(objPreguntas["37"].response.toString().replaceAll(".","").replace(",",".")) <= 5000)
                    || (checkResponse(objPreguntas["45"]) && checkResponse(objPreguntas["37"]) && checkResponse(objPreguntas["71"]) && objPreguntas["45"].response > 2020 && parseInt(objPreguntas["37"].response.toString().replaceAll(".","").replace(",",".")) > 5000 && objPreguntas["71"].response === "RESIDENCIAL")
                    || (checkResponse(objPreguntas["155"]) && objPreguntas["155"].response === "NO")
                    || (checkResponse(objPreguntas["155"]) && checkResponse(objPreguntas["71"]) && objPreguntas["155"].response === "SI" && objPreguntas["71"].response === "RESIDENCIAL")
                break 
            case 172: // Povincia 7007
                disabled = 
                    checkResponse(objPreguntas["45"]) && objPreguntas["45"].response > 2020
            break
            
            case 514: //Año de concesión del permiso de construcción 4029
                disabled = 
                    (checkResponse(objPreguntas["88"]) && parseInt(objPreguntas["88"].response.toString().replaceAll(".","").replace(",",".")) >= 270)
                break
            case 515: //¿La actividad sustituye a una de generación de fuentes renovables? 4029
            case 516: //¿La actividad sustituye a una de generación con altas emisiones que usa combustibles fósiles? 4029
            case 517: //¿Cuánto supera la capacidad de producción la instalación actual respecto a la sustituida? 4029
            case 518: //¿La instalación está diseñada para utilizar combustibles gaseosos renovables o hipocarbónicos? 4029
            case 519: //¿La actividad se realiza en un Estado miembro que utiliza carbón para generar energía? 4029
                disabled = 
                    (checkResponse(objPreguntas["514"]) && parseInt(objPreguntas["514"].response.toString().replaceAll(".","").replace(",",".")) > 2030)
                    || (checkResponse(objPreguntas["88"]) && parseInt(objPreguntas["88"].response.toString().replaceAll(".","").replace(",",".")) >= 270)
                break
            case 520: //¿El estado miembro se ha comprometido a eliminar el uso del carbón? 4029
                disabled = 
                    (checkResponse(objPreguntas["514"]) && parseInt(objPreguntas["514"].response.toString().replaceAll(".","").replace(",",".")) > 2030)
                    || (checkResponse(objPreguntas["88"]) && parseInt(objPreguntas["88"].response.toString().replaceAll(".","").replace(",",".")) >= 270)
                    || (checkResponse(objPreguntas["519"]) && objPreguntas["519"].response === "SI")
                break
            case 521: //¿El cumplimiento de los criterios mencionados, se verifica por un tercero? 4029
                disabled = 
                    (checkResponse(objPreguntas["514"]) && parseInt(objPreguntas["514"].response.toString().replaceAll(".","").replace(",",".")) > 2030)
                    || (checkResponse(objPreguntas["88"]) && parseInt(objPreguntas["88"].response.toString().replaceAll(".","").replace(",",".")) >= 270)
                    || (checkResponse(objPreguntas["519"]) && objPreguntas["519"].response === "NO")
                    || (checkResponse(objPreguntas["520"]) && objPreguntas["520"].response === "SI")
                break         
            default:
                disabled = false
                break
        }
        
        switch (obj.formatQuestion) {
            case 1:
                format = (<>
                    <div className='form-field'>
                        <Form>
                            <Form.Select id={`${obj.idQuestion}`} disabled={disabled} value={(disabled ? "" : obj.response)} onChange={handleChange} className='input-form-typeahead question-input' aria-label="Default select example">
                                <option>{""}</option>
                                {obj.options.map(item => (<option>{item.nameOption}</option>))}
                            </Form.Select>
                        </Form>
                    </div>
                </>)
                break
            case 2:
                format = (<>
                    <div className='form-field'>
                        <Form.Control id={`${obj.idQuestion}`} disabled={disabled} value={disabled ? "" : obj.response} onChange={handleChange} type='text' className='input-form-typeahead question-input' />
                    </div>
                </>)
                break
            case 3:
                format = (<>
                    <div className='form-field'>
                        <Form.Control id={`${obj.idQuestion}`} disabled={disabled} value={disabled ? "" : obj.response} onChange={handleChange} type='text' maxLength='4' className='input-form-typeahead question-input' />
                    </div>
                </>)
                break
            case 4:
                format = (<>
                    <div className='form-field'>
                        <Form.Control id={`${obj.idQuestion}`} disabled={disabled} value={disabled ? "" : obj.response} onChange={handleChange} type='text' className='input-form-typeahead question-input' />
                    </div>
                </>)
                break
            default:
                format = (<>
                    <div className='form-field'>
                        <Form.Control id={`${obj.idQuestion}`} disabled={disabled} value={disabled ? "" : obj.response} onChange={handleChange} className='input-form-typeahead question-input' />
                    </div>
                </>)
                break
        }
        return <div className={`question-block ${(!hasRelatedQuestions || windowSize < 1328) ? 'width-100' : 'width-50'}`}>
            {questionLabel(obj)}
            <div className='form-help-box'>
                {format} {questionHelp(obj)}
            </div>
        </div>
    }

    const paint = (key, obj) => {
        let target = parseInt(obj.relatedQuestion)
        let related = Object.values(objPreguntas).filter((i) => { return i.idQuestion === target })[0]
        let isRelated = obj.relatedQuestion !== null

        let question = pinted.includes(key) ?
            <></> :
            <div key={key} className='row py-2'>
                {isRelated ?
                    (<>{chooseQuestion(obj, isRelated, related.idQuestion)}{chooseQuestion(related, isRelated, obj.idQuestion)}</>)
                    : chooseQuestion(obj, isRelated, related, -1)
                }
            </div>
        if (obj.relatedQuestion !== null) pinted.push(obj.relatedQuestion)
        return question
    }


    const handleChange = (event) => {
        let sumar = 0
        let value = event.target.value
        let questionId = event.target.id

        var orderKey;

        if (value !== "" && (
            (objPreguntas[questionId].response === null || objPreguntas[questionId].response === "") ||
            (objPreguntas[questionId].response !== null && objPreguntas[questionId].response !== "" && value !== objPreguntas[questionId].response))
        ) {
            sumar = 1
        } else if (value !== "" && (objPreguntas[questionId].response !== null && objPreguntas[questionId].response !== "") && value === objPreguntas[questionId].response) {
            sumar = 0
        } else if (value === "" && (objPreguntas[questionId].response !== null && objPreguntas[questionId].response !== "")) {
            sumar = -1
        }


        Object.entries(objQuestionByOrderId).map(([key, obj]) => {
            if (obj.idQuestion.toString() === questionId) {
                obj.response = value;
                objQuestionByOrderId[key] = obj
                return;
            }
        })
        setObjQuestionByOrderId(objQuestionByOrderId)

        let pregunta = { ...objPreguntas[questionId], response: `${value}` }
        let preguntas = { ...objPreguntas, [questionId]: pregunta }
        setObjPreguntas(oldPreguntas => {
            return { ...oldPreguntas, [questionId]: pregunta }
        }
        )

        props.onObjectChange(preguntas, props.org, props.orgChild, sumar, props.org)

    }

    return (
        <>
            {!objQuestionMalformed ? Object.entries(objQuestionByOrderId).map(([key, obj]) => paint(obj.idQuestion, obj)) :
                <span>Parametrización errónea, por favor contacte con soporte</span>}
        </>
    )
}

