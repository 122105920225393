import React, { useState, useContext } from 'react';
import { Form, Tab, Tabs, Button, Modal } from 'react-bootstrap';
import './modalWarning.css';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';




export default function ModalWarning({ block, input, header, message, show, url, handleContinue, handle, error, onlyButtonContinue = false, big = false, noButtons = false}) {
    const navigate = useNavigate();
    const [fichNoSelected, setFichSelected] = useState(error)
    const handleClose = () => handle(false);

    const handleDiscard = () => {
        if (block !== null && block !== undefined && block === '') {
            setFichSelected(true);
            return;
        }
        else {
            setFichSelected(false);
        }
        handle(false);
        if (url !== undefined) {
            navigate(url);
        }
        if (handleContinue !== undefined) {
            handleContinue()
        }

    }


    return (
        <Modal show={show} onHide={handleClose} centered size={big ? "lg" : "sm-down"}>
            <Modal.Header closeButton>{header}</Modal.Header>
            <Modal.Body className={input && 'modal-body-input'}>
                <p>{message}</p>
                {fichNoSelected ? <p>
                    Se debe cargar un fichero
                </p> : <></>}
                {!noButtons && 
                <div className='options'>
                    {!onlyButtonContinue && <Button className='marginRight5px' onClick={handleClose} variant="secondary">Cancelar</Button>}
                    {/* <Button disabled={!block} onClick={handleDiscard} variant="primary">Continuar</Button> */}
                    <Button onClick={handleDiscard} variant="primary">Continuar</Button>
                </div>
                }
            </Modal.Body>
        </Modal>
    )
}