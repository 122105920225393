import React, { useState, useContext, useEffect } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import './modalWarning.css';
import { MdAddCircle } from "react-icons/md"
import { Grid, Item, ItemContent, Label } from 'semantic-ui-react';
import { HiOutlinePencil, HiOutlineTrash } from 'react-icons/hi'
import PopOver from '../popOver/popOver'
import taxoApi from '../../api/taxoApi';
import { useNavigate } from 'react-router-dom';
import { Context } from "../../commons/utils/store";
import './modalMessage.css';

export default function ModalMessage(props) {
    const handleClose = () => {
        props.handle(false);
        setShow(false)
        //setErrorGetComments(false)
        setErrorComment(false)
    }
    const handleDiscard = () => {
        props.handle(false);
        if (props.handleContinue !== undefined) {
            props.handleContinue()
        }
    }

    const navigate = useNavigate();



    const [state, dispatch] = useContext(Context);
    const maxCommentLength = 500

    const [show, setShow] = useState(false);
    const [msg, setMsg] = useState([]);
    const [text, setText] = useState("");

    const [errorComent, setErrorComment] = useState(false);
    const [errorGetComments, setErrorGetComments] = useState(false);
    const [characterRemain, setCharacterRemain] = useState(maxCommentLength);

    const handleTextChange = (event) => {
        setCharacterRemain(maxCommentLength - event.target.value.length)
        setText(event.target.value)
    }

    /* const deleteComment = (idComment) => {
        dispatch({ type: "SET_HIDDEN", payload: false });
        taxoApi.deleteComment(idComment).then((response) => {
            dispatch({ type: "SET_HIDDEN", payload: true });
        })
        
    } */

    const handleSave = () => {
        if (text !== undefined && text !== '') {
            //props.handle(false);         
            dispatch({ type: "SET_HIDDEN", payload: false });
            taxoApi.getTaxoSaveComment(props.evaluationId, text, navigate)
                .then((response) => {
                    if (response.status === 200) {
                        let listMsg = msg
                        let newComment = response.data
                        listMsg.push(newComment)
                        setMsg(listMsg)
                        setShow(false)
                        setText("")
                        setCharacterRemain(maxCommentLength)
                        //handlerObtainComments();
                    } else {
                        setErrorComment(true)
                    }
                    dispatch({ type: "SET_HIDDEN", payload: true });
                })


        }
    }


    const handlerObtainComments = (evaluationId) => {
        dispatch({ type: "SET_HIDDEN", payload: false });
        taxoApi.getTaxoGetQueryComments(evaluationId, navigate)
            .then((response) => {

                if (response.data) {
                    setMsg(response.data);
                    //dispatch({ type: "SET_HIDDEN", payload: true });
                    //props.handle(true);
                    setShow(false)
                }
                else {
                    setErrorGetComments(true)
                }
                dispatch({ type: "SET_HIDDEN", payload: true });
            })
    }

    useEffect(() => {
        if (props.evaluationId) {
            //dispatch({ type: "SET_HIDDEN", payload: false });
            handlerObtainComments(props.evaluationId);
        }
    }, [])


    return (
        <Modal show={props.show} onHide={handleClose} centered size="sm-down">

            <Modal.Header className='section-title-modal' closeButton>
                <div className='col-5'>{props.header}</div>
                <div className='col-6 d-flex justify-content-end'>
                    <Button className='btn-new' onClick={() => setShow(true)} variant="secondary">
                        <MdAddCircle className='me-1 mb-0-2' />Nuevo</Button>
                </div>
            </Modal.Header>

            <Modal.Body>
                {msg && msg.length > 0 ?
                    msg.map((m) =>
                        <Grid>
                            <Grid.Row className='docsRow'>
                                <Grid.Column >
                                    <Label className='msg-box msg-comment'>
                                        {/* <Label className='msg-box'> */}
                                        {m.comment}
                                    </Label>

                                </Grid.Column>
                                {/* { <Grid.Column className='actions-comment'>
                                    <PopOver title={
                                        <button className='play-button' onClick={(m) => editComment(m)} >
                                            <HiOutlinePencil className='eye color-secondary' />
                                        </button>} message={"Editar comentario"} />
                                        <PopOver title={
                                        <button className='play-button' onClick={() => deleteComment(m.idComment)}>
                                            <HiOutlineTrash className='eye color-secondary' />
                                        </button>} message={"Eliminar comentario"} />
                                </Grid.Column> } */}
                            </Grid.Row>
                        </Grid>)
                    : errorGetComments ?
                        <div className='mb-0 text-danger'>No se han podido recuperar los comentarios</div> :
                        !show && <div className='mb-0'>No hay comentarios</div>

                    // props.message.data.forEach(item => {
                    //     //<span> item.comment </s>
                    //     <Grid.Row className='docsRow'>
                    //     <Grid.Column>
                    //         <Label className='msg-box'>
                    //             <span>Hola</span>
                    //         </Label>
                    //     </Grid.Column>
                    // </Grid.Row>

                    //})
                }

                {show &&
                    <Form>
                        <Label className='label-new-comment'>Nuevo comentario ({characterRemain} Caracteres)</Label>
                        <Form.Control onChange={handleTextChange} as="textarea" rows={3} maxLength={maxCommentLength} value={text} />
                        {errorComent && <div className='mb-0 text-danger'>No se ha podido añadir el comentario</div>}
                        <Button className='float-end mt-3 btn-new' onClick={() => handleSave()} variant="secondary">Guardar</Button>
                    </Form>
                }

                {/* <div className='options'>
                <Button className='marginRight5px' onClick={handleClose} variant="secondary">Cancelar</Button>
                <Button onClick={handleDiscard} variant="primary">Continuar</Button>
                </div> */}
            </Modal.Body>
        </Modal>
    )
}