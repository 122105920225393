import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Popover, Button } from 'react-bootstrap';
import './popOver.css';
import { BsCheckCircleFill } from "react-icons/bs"

export default function PopOver({ end, strong, title, message, units, position }) {

  return (
    <OverlayTrigger
      //className={padding && 'pt-3'}
      key='bottom'
      placement={(position != undefined || position != null) ? `${position}` : 'left'}
      overlay={
        // <Tooltip id="button-tooltip">
        //   {message}
        // </Tooltip>
        <Popover id={'popover-positioned-bottom'}>
          <Popover.Body>
            {strong ?
              <>
                {units != null ? <strong>Unidades: {units}<br /></strong> : <></>}
                <strong>{message}</strong>
              </>
              :
              <>
                {units != null ? <><span>Unidades: </span><i>{units}<br /></i></> : <></>}
                <span>{message}</span>
              </>
            }
          </Popover.Body>
        </Popover>
      }
    >
      <span className={end ? 'float-end h6 cursor-not fw-normal text-decoration-underline' : 'link-help'} variant="secondary">{title}</span>
    </OverlayTrigger>
  )
}