import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Button } from 'react-bootstrap';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import '../buttonNewAction/buttonNewAction.css'
import { width } from '@mui/system';
import { BiPlus } from "react-icons/bi";

export default function ButtonsHome(props) {

    const navigate = useNavigate();

    return (
        <div className='d-inline eva' >
            <div className='section d-none d-md-inline'>
                <Button className='me-3  justify-content-center button-primary long-button' onClick={() => navigate('/newaction')}>Nueva Evaluación Individual</Button>
                {/* <Button className='justify-content-center button-primary long-button' onClick={() => navigate('/batchevaluation')}>Listado de Evaluaciones en bloque</Button> */}
            </div>

            {/*<div className='section d-inline d-md-none'>
                <DropdownButton className='d-inline button-primary long-button' id="dropdown-basic-button" title={<div className='d-inline'><span className='d-none d-sm-inline'>Nueva acción</span> <BiPlus className='d-sm-none plus-botton'> </BiPlus></div>} align='right' drop='down-centered'>
                    <Dropdown.Item className='dropdown-item-new-action fs-8' onClick={() => navigate('/newaction')}>
                        Nueva Evaluación
                    </Dropdown.Item>
                    <Dropdown.Item className='dropdown-item-new-action fs-8' onClick={() => navigate('/batchevaluation')}>
                        Evaluaciones en bloque
                    </Dropdown.Item>
                </DropdownButton>
            </div> */}
        </div>
    )
}