import React, { useState,useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Divider from '@material-ui/core/Divider';
import { Navbar, Container, NavLink, Button } from 'react-bootstrap';
import { Link, useNavigate, useLocation } from "react-router-dom";
import AccountCircle from '@mui/icons-material/AccountCircle';
import ModalDefaultMessage from '../../commons/modals/modalDefaultMessage'
import { Dropdown, DropdownButton } from 'react-bootstrap';
import UsersModal from '../usersModal/usersModal'
import './navigator.css';
//import '../../App-media.css';
import userIcon from '../images/user.svg';
import { HiHome } from "react-icons/hi"
import { HiViewGridAdd } from "react-icons/hi"
import { HiChevronDown } from "react-icons/hi"
import { MdOutlineHelpOutline } from "react-icons/md"
import PopOver from '../popOver/popOver'
import logo from '../../logo.png'
import logoBlue from '../../logoBlue.png'
import pdf from '../../api/ÁlamoESG_Módulo Evaluación Alineación Taxonomía UE_Manual Usuario.pdf'
import { HiAdjustments } from "react-icons/hi";
import { BsFileEarmarkBarGraph } from "react-icons/bs";
import { ImStatsDots } from "react-icons/im";
import { IoBarChart } from "react-icons/io5";
import { MdBarChart } from "react-icons/md";

export default function Navigator() {

  const location = useLocation();
  const stored = localStorage.getItem("token-taxo");

  const search = 
  <svg className="cursor-pointer fill-white barra-navegacion__primer-link barra-navegacion__link" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 485.104 485.104" onClick={() => { navigate('/search') }}>
    <g><path d="M110.028,115.171c-4.76-4.767-12.483-4.752-17.227,0c-32.314,32.33-32.314,84.898-0.016,117.197c2.38,2.379,5.487,3.569,8.614,3.569c3.123,0,6.234-1.19,8.613-3.569c4.76-4.76,4.76-12.469,0-17.228c-22.795-22.803-22.795-59.923,0.016-82.742C114.788,127.64,114.788,119.923,110.028,115.171z"/><path d="M471.481,405.861L324.842,259.23c37.405-66.25,28.109-151.948-28.217-208.317C263.787,18.075,220.133,0,173.718,0C127.287,0,83.633,18.075,50.81,50.913c-67.717,67.74-67.701,177.979,0.02,245.738c32.85,32.823,76.488,50.897,122.919,50.897c30.489,0,59.708-7.939,85.518-22.595L405.824,471.51c18.113,18.121,47.493,18.129,65.641,0c8.706-8.71,13.593-20.512,13.608-32.823C485.073,426.37,480.171,414.567,471.481,405.861z M85.28,262.191c-48.729-48.756-48.729-128.079-0.016-176.828c23.62-23.627,55.029-36.634,88.453-36.634c33.407,0,64.816,13.007,88.451,36.627c48.715,48.756,48.699,128.094-0.015,176.85c-23.62,23.612-55.014,36.612-88.406,36.612C140.341,298.818,108.919,285.811,85.28,262.191z"/></g>
  </svg>;

const dashboard = (
  <IoBarChart 
    className="cursor-pointer fill-white barra-navegacion__primer-link barra-navegacion__link"
    style={{ width: "1.3rem", height: "1.3rem" }}
    onClick={() => {navigate("/dashboard");}}
  />
);

  const [openModalUsuario, setOpenModal] = useState(false);

  const navigate = useNavigate();
  const [modalView, setModalView] = useState(false)
  const [permisoDashboard, setPermisoDashboard] = useState(false)
  const handleOpenModalUsuario = () => {
    setOpenModal(true);
  };

  const handleOpenModalContact = () => {
    setModalView(true);
  };

  const handleCloseModalContact = () => {
    setModalView(false);
  };

  const handleCloseModalUsuario = (event) => {
    setOpenModal(false);
  };

  const [toggleShow, setToggleShow] = useState(false);

  const handleDropDown = () => {
    setToggleShow(!toggleShow)
  }

  const handleCloseSession = (e) => {
    e.preventDefault();
    localStorage.removeItem('token-taxo')
    navigate("/login");
    setOpenModal(false);
  }

  useEffect(() => {
    const id = localStorage.getItem("PERMISO_VER_DASHBOARD")
    if(parseInt(id) > 0){
      setPermisoDashboard(true)
    }
  },[])

  return (
    <Navbar collapseOnSelect sticky="top" variant="dark" className='blueNavbar'>
      <Container fluid className='ps-0'>
        <Link to="/">
          <div className='pe-5 bg-title '>
            <Navbar.Brand className='ps-4rem margin-left position-relative'>
              <img className='logo position-absolute' src={logoBlue} alt="Logo" />
            </Navbar.Brand>
          </div>
        </Link>
        <Navbar.Brand className="secondLane  d-none d-md-inline">
          Evaluación de Actividades Económicas
          <p className="subtitle">Alineación con la Taxonomía EU de sostenibilidad</p>
        </Navbar.Brand>
        <div className="user hidden justify-content-lg-end">
          {/* <ul className="user-account-nav user-account-nav-tabs user-account-first-level" id="pills-tab" role="tablist">
                        <li className="user-account-nav-item rightMenu show">
                            <a id="usuarioDropdown" className="user-account-nav-link inline" onClick={handleDropDown} aria-expanded="true"><AccountCircle />&nbsp;&nbsp;<span>Usuario</span></a>
                            <ul className={!toggleShow ? "dropdown-menu drop-list user-list" : "dropdown-menu drop-list user-list show"} role="menu" aria-labelledby="usuarioDropdown">
                                <li className="dropdown-title"><AccountCircle /><h5 className="d-inline" ><span className="user-color title-options">UExplorerTaxonomy</span></h5></li>
                                <li className=" dropdown-subtitle"><span className="user-color">Usuario</span></li>
                                <li className="no-pointer user-element ms-1"><span className="user-color me-1">Entidad:</span><span className="user-color">ÁlamoConsulting</span></li>
                                <li className="no-pointer user-element ms-1"><span className="user-color me-1">Nombre:</span><span className="user-color">UExplorerTaxonomy</span></li>
                                <li className="dropdown-subtitle"><span className="user-color">Opciones</span></li>
                                <li><a className="user-color ms-1" href="/">Mi perfil</a></li>
                                <li><a className="user-color ms-1" href="/Principal/CerrarSesion">Cerrar sesión</a></li>
                            </ul>
                        </li>
                    </ul> */}
          {(location.pathname !== "/login") && <div className="barra-navegacion_acciones">
            {/* <PopOver title={<HiHome className='color-white home-icon titulo-pagina__icono' onClick={() => { navigate('/') }} />} message={"Inicio"} /> */}
            
            


            

            <DropdownButton className='no-hover-button' id="dropdown-basic-button-a" title={<><HiViewGridAdd className='color-white home-icon titulo-pagina__icono'></HiViewGridAdd>  <HiChevronDown className='color-white home-icon chevrodown'></HiChevronDown></>}>
              <Dropdown.Item onClick={() => navigate('/')}>Evaluaciones individuales</Dropdown.Item>
              <Dropdown.Item  onClick={() => navigate('/batchevaluation')}>Evaluaciones en bloque</Dropdown.Item>
          </DropdownButton>

          
            
          <Divider
                orientation="vertical"
                flexItem
                className=" d-md-none d-xl-block barra-navegacion__separador"
              />
            {/* <NavLink
              activeClassName="barra-navegacion__link-activo"
              to="/administracion" className="barra-navegacion__primer-link barra-navegacion__link"
            >...............................................................
              Usuario
            </NavLink> */}
            {/* <PopOver title={search} message={"Búsqueda"} /> */}
            {search}


            <Divider
              orientation="vertical"
              flexItem
              className="d-md-none d-xl-block barra-navegacion__separador"
            />
            {/* <PopOver title={<MdOutlineHelpOutline className='color-white home-icon titulo-pagina__icono'/>} message={"Ayuda"} /> */}
            
            {parseInt(localStorage.getItem("PERMISO_VER_DASHBOARD")) > 0 &&
            <>
              {dashboard}
              <Divider
                orientation="vertical"
                flexItem
                className=" d-md-none d-xl-block barra-navegacion__separador"
              />
            </>
            }

            <DropdownButton className='no-hover-button' id="dropdown-basic-button-b" title={<><MdOutlineHelpOutline className='color-white home-icon titulo-pagina__icono' /><HiChevronDown className='color-white home-icon chevrodown'></HiChevronDown></>} align={{ lg: 'end' }}>
              <Dropdown.Item>
                <PopOver className='no-hover-button' title={<Button className='no-hover-button noPaddingX' onClick={() => { setModalView(true) }}>Soporte</Button>} position="none"/>
                <ModalDefaultMessage header={<span className='section-title-modal'>Información de contacto</span>} label={"Para cualquier problema técnico o duda sobre el uso de la Aplicación de Evaluación de la Alineación de Actividades Económicas con la taxonomía EU tiene a su disposición el siguiente medio de contacto:"} message={"soporte_alamoesg@alamoconsulting.com"} show={modalView} handle= {handleCloseModalContact}></ModalDefaultMessage>
                </Dropdown.Item>
              <Dropdown.Item href={pdf} target="_blank">Manual de usuario</Dropdown.Item>
          </DropdownButton>
            <Divider
              orientation="vertical"
              flexItem
              className="d-md-none d-xl-block barra-navegacion__separador"
            />
            {/* <PopOver title={<img
              src={userIcon}
              onClick={handleOpenModalUsuario}
              className="titulo-pagina__boton titulo-pagina__icono"
              alt="User Modal"
            />} message={"Usuario"} /> */}
            <img
              src={userIcon}
              onClick={handleOpenModalUsuario}
              className="titulo-pagina__boton titulo-pagina__icono"
              alt="User Modal"
            />

            {openModalUsuario && (<UsersModal
              nombre= {localStorage.getItem("desc_usuario")}
              email={localStorage.getItem("desc_entidad")}
              iniciales={localStorage.getItem("alias")}
              handleClose={handleCloseModalUsuario}
              open={openModalUsuario}
              cerrarSesion={handleCloseSession}
            ></UsersModal>)}
          </div>}

        </div>
      </Container>
    </Navbar>
  )
}
