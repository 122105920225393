import Modal from "@material-ui/core/Modal";
import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "./usersModal.css";

function usersModal({
  nombre,
  email,
  iniciales,
  cambiarPasswd,
  cerrarSesion,
  handleClose,
  open,
}) {

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <div className="modal-usuario">
        <p className="iniciales circle"> {iniciales} </p>
        <h2 className="nombre">{nombre}</h2>
        <p className="correo">{email}</p>
        <hr className="line" />
        {/* <button className="botonCambiarPasswd" onClick={cambiarPasswd}>
          Cambiar Contraseña
        </button> */}
        <button className="botonCerrarSesion" onClick={cerrarSesion}>
          Cerrar Sesión
        </button>
      </div>
    </Modal>
  );
}

export default usersModal;
