import React, { useState } from 'react'
import ModalWarning from '../../commons/modals/modalWarning'
import { useParams } from 'react-router-dom'
import { Form, Tab, Tabs, Button, Alert, Accordion } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import ApexCharts from 'apexcharts'
import './dashboard.css'
import Chart from "react-apexcharts";
import { MdCancel } from "react-icons/md"
import { BsCheckCircleFill } from "react-icons/bs"
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { BiPlus } from "react-icons/bi";
import { BiRightArrow } from 'react-icons/bi'
import { BsDashCircleFill } from "react-icons/bs";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { MdTrendingUp ,MdTrendingFlat,MdTrendingDown  } from "react-icons/md";
import  {  useEffect, useContext } from 'react';
import { Link, useNavigate } from "react-router-dom";
import taxoApi from '../../api/taxoApi';
import { Context } from "../../commons/utils/store";

export default function Dashboard(props) {
    let params = useParams();
    const navigate = useNavigate();
    const [state, dispatch] = useContext(Context);
    
    const [filter, setFilter] = useState(false)
    const [mostrar, setMostrar] = useState(false);
    const [operacionesRealizadas,setOperacionesRealizadas] = useState(0);
    const handleSetOperacionesRealizadas = (click) => setOperacionesRealizadas(click)

    const [operacionesNoAlineadas,setOperacionesNoAlineadas] = useState(0);
    const handleSetOperacionesNoAlineadas = (click) => setOperacionesNoAlineadas(click)

    const [operacionesAlineadas,setOperacionesAlineadas] = useState(0);
    const handleSetOperacionesAlineadas = (click) => setOperacionesAlineadas(click)

    const [operacionesActivas,setOperacionesActivas] = useState(0);
    const handleSetOperacionesActivas = (click) => setOperacionesActivas(click)

    const [porcOperacionesRealizadas,setPorcOperacionesRealizadas] = useState(0);
    const handleSetPorcOperacionesRealizadas = (click) => setPorcOperacionesRealizadas(click)

    const [porcOperacionesNoAlineadas,setPorcOperacionesNoAlineadas] = useState(0);
    const handleSetPorcOperacionesNoAlineadas = (click) => setPorcOperacionesNoAlineadas(click)

    const [porcOperacionesAlineadas,setPorcOperacionesAlineadas] = useState(0);
    const handleSetPorcOperacionesAlineadas = (click) => setPorcOperacionesAlineadas(click)

    const [porcOperacionesActivas,setPorcOperacionesActivas] = useState(0);
    const handleSetPorcOperacionesActivas = (click) => setPorcOperacionesActivas(click)


    const [dic1,setDic1] = useState(0);
    const handleSetDic1 = (click) => setDic1(click)

    const [dic2,setDic2] = useState(0);
    const handleSetDic2 = (click) => setDic2(click)

    const [dic3,setDic3] = useState(0);
    const handleSetDic3 = (click) => setDic3(click)

    const [dic4,setDic4] = useState(0);
    const handleSetDic4 = (click) => setDic4(click)

    const [dic5,setDic5] = useState(0);
    const handleSetDic5 = (click) => setDic5(click)

    const [errorFile, setErrorFile] = useState(false)
    const handleErrorFile = (click) => setErrorFile(click)
    const [errorMsg, setErrorMsg] = useState()

    const fechaActual = new Date();
    const mes = fechaActual.getMonth();
    const año = fechaActual.getFullYear();
    const daysInMonth = new Date(fechaActual.getFullYear(), fechaActual.getMonth() + 1, 0).getDate();
    const diasCompletos = Array.from({ length: daysInMonth }, (_, index) => index + 1);

    const nombresMeses = [
      "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
      "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];

    function sortErrors(a, b) {
      return b.N_ERRORES - a.N_ERRORES;
    }

    function formatDateToYYYYMMDD(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Agrega un cero al principio si el mes es de un solo dígito
      const day = String(date.getDate()).padStart(2, '0'); // Agrega un cero al principio si el día es de un solo dígito
      return `${year}-${month}-${day}`;
    }
    
    function formatDateToYYYYMM(date) {
      var year = date.getFullYear();
      var month = date.getMonth() + 1; 
      if (month < 10) {
          month = '0' + month;
      }
      var formattedDate = year + '/' + month;
      return formattedDate;
    }

    useEffect(() => {
      setMostrar(false);
      dispatch({ type: "SET_HIDDEN", payload: false });
      
      const id = localStorage.getItem("PERMISO_VER_DASHBOARD")
      if(parseInt(id) > 0){
        taxoApi.getInfoDashboard(navigate).then((response) => {        
          if (response.status === 'OK') {
            const realizadasVarMensualValue = response.dic1[14]["N_CONSULTAS"];
            setOperacionesRealizadas(realizadasVarMensualValue);
  
            const NoAlineadasVarMensualValue = response.dic1[10]["N_CONSULTAS"];
            setOperacionesNoAlineadas(NoAlineadasVarMensualValue);
  
            const AlineadasVarMensualValue = response.dic1[6]["N_CONSULTAS"];
            setOperacionesAlineadas(AlineadasVarMensualValue);
  
            const ActivasVarMensualValue = response.dic1[2]["N_CONSULTAS"];
            setOperacionesActivas(ActivasVarMensualValue);
  
            const porcRealizadasVarMensualValue = calculatePercentage(response.dic1[14]["N_CONSULTAS"], response.dic1[15]["N_CONSULTAS"]);
            setPorcOperacionesRealizadas(porcRealizadasVarMensualValue);
  
            const porcNoAlineadasVarMensualValue = calculatePercentage(response.dic1[10]["N_CONSULTAS"], response.dic1[11]["N_CONSULTAS"]);
            setPorcOperacionesNoAlineadas(porcNoAlineadasVarMensualValue);
  
            const porcAlineadasVarMensualValue = calculatePercentage(response.dic1[6]["N_CONSULTAS"], response.dic1[7]["N_CONSULTAS"]);
            setPorcOperacionesAlineadas(porcAlineadasVarMensualValue);

            const porcActivasVarMensualValue = calculatePercentage(response.dic1[2]["N_CONSULTAS"], response.dic1[3]["N_CONSULTAS"]);
            setPorcOperacionesActivas(porcActivasVarMensualValue);
  
            function calculatePercentage(numerator, denominator) {
                // numerador = mesActual / denominador = mes anterior
                const percentage = denominator !== 0 ? ((numerator / denominator) - 1) * 100 : 
                numerator !== 0 ? 100 : 0;
                let percent = percentage.toFixed(2);
                return numerator - denominator
            }
                      
            handleSetDic1(response.dic1)
            handleSetDic2(response.dic2)
            handleSetDic3(response.dic3)
            handleSetDic4(response.dic4)
            handleSetDic5(response.dic5)
            
            setMostrar(true);
          } else {
            setErrorMsg("No se ha podido recuperar la información, por favor póngase en contacto con soporte.")
            setErrorFile(true)
            
          }             
          dispatch({ type: "SET_HIDDEN", payload: true });
      })
      }else{
        dispatch({ type: "SET_HIDDEN", payload: true });
        setErrorMsg("No se ha podido recuperar la información, por favor póngase en contacto con soporte.")
        setErrorFile(true)
      }
    
    }, [])


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// PREPARACION DE DATOS GRAFICO 1 DE OPERACIONES REALIZADAS DEL MES /////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const fechas = Object.values(dic2).map(item => new Date(item.F_CREACION));
//const fechaMaxima = new Date(Math.max(...fechas));
const fechaMaxima = new Date()
const dias = [];
const datosOrganizados = {};

for (let i = 1; i <= fechaMaxima.getDate(); i++) {
  dias.push(i);
}

const valores = dias.map(dia => {
  const fecha = new Date(fechaMaxima.getFullYear(), fechaMaxima.getMonth(), dia);
  let valor = 0;
  for (const key in dic2) {
    const item = dic2[key];
    if (new Date(item.F_CREACION).getDate() === dia) {
      valor = item.N_CONSULTAS;
      break;
    }
  }
  return valor;
});

const sumaValores = valores.reduce((acc, curr) => acc + curr, 0);

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// PREPARACION DE DATOS GRAFICO 2 DE Operaciones realizadas por Actividad /////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const fechasUnicas = [];
    var currentDate2 = new Date();
    var auxDate2 = new Date();
    var fechasFormateadas = [];
    const datosFormateados = [];

    if(dic3 != 0){

    fechasFormateadas.unshift(formatDateToYYYYMM(new Date()));
    for (var i = 0; i < mes ; i++) {
        var clonedDate = new Date(auxDate2);
        clonedDate.setDate(0); 
        fechasFormateadas.unshift(formatDateToYYYYMM(clonedDate));
        auxDate2.setMonth(auxDate2.getMonth() - 1);
    }
    
    currentDate2.setMonth(currentDate2.getMonth() - mes);
    
    /* while (currentDate2 <= fechaActual) { */
    while (formatDateToYYYYMMDD(currentDate2) <= formatDateToYYYYMMDD(fechaActual)) {
      const year = currentDate2.getFullYear();
      const month = currentDate2.getMonth();
      
      const ultimoDiaDelMes = new Date(year, month + 1, 0).getDate();
      const fechaFinDeMes = new Date(year, month, ultimoDiaDelMes);
      fechasUnicas.push(formatDateToYYYYMMDD(fechaFinDeMes));
      currentDate2.setMonth(currentDate2.getMonth() + 1);
    }

for (const key in dic3) {
  if (dic3.hasOwnProperty(key)) {
      const dato = dic3[key];
      if (!datosOrganizados[dato.ID_ACTIVIDAD]) {
          datosOrganizados[dato.ID_ACTIVIDAD] = {};
      }
      datosOrganizados[dato.ID_ACTIVIDAD][dato.F_CREACION] = dato.N_CONSULTAS;
  }
}

for (const idActividad in datosOrganizados) {
  if (datosOrganizados.hasOwnProperty(idActividad)) {
      const actividad = datosOrganizados[idActividad];
      const data = [];

      fechasUnicas.forEach((fecha) => {
          data.push(actividad[fecha] || 0);
      });

      datosFormateados.push({
          name: idActividad.toString(),
          data: data
      });      
  }

}
    }

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// PREPARACION DE DATOS GRAFICO 3 DE ALINEADAS VS NO ALINEADAS POR MES ////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

var fechasAlineadasVsNoAlineadas = [];
var finDeMeses = [];
var operacionesAlineadasComp = []
var operacionesNoAlineadasComp = []
var currentDate = new Date();
var auxDate = new Date();
const nMesesGraficoAlineamiento = mes

if(dic4 != 0){

    fechasAlineadasVsNoAlineadas.unshift(formatDateToYYYYMM(new Date()));
    for (var i = 0; i < nMesesGraficoAlineamiento ; i++) {
        var clonedDate = new Date(auxDate);
        clonedDate.setDate(0); 
        fechasAlineadasVsNoAlineadas.unshift(formatDateToYYYYMM(clonedDate));
        auxDate.setMonth(auxDate.getMonth() - 1);
    }
    
    currentDate.setMonth(currentDate.getMonth() - nMesesGraficoAlineamiento);
    
    /* while (currentDate <= fechaActual) { */
    while (formatDateToYYYYMMDD(currentDate) <= formatDateToYYYYMMDD(fechaActual)) {
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth();
      
      const ultimoDiaDelMes = new Date(year, month + 1, 0).getDate();
      const fechaFinDeMes = new Date(year, month, ultimoDiaDelMes);
      finDeMeses.push(formatDateToYYYYMMDD(fechaFinDeMes));
      currentDate.setMonth(currentDate.getMonth() + 1);
    }    
    
    operacionesAlineadasComp = finDeMeses.map(mes => {
      let valorAlineadas = 0;
      for (const key in dic4) {
        if (dic4[key].F_CREACION === mes) {
          valorAlineadas = dic4[key].OPERACIONES_ALINEADAS;      
          break;
        }
      }
      return valorAlineadas;
    });    
    
    operacionesNoAlineadasComp = finDeMeses.map(mes => {
      let valorNoAlineadas = 0;
      for (const key in dic4) {
        if (dic4[key].F_CREACION === mes) {
          valorNoAlineadas = dic4[key].OPERACIONES_NO_ALINEADAS;
          break;
        }
      }
      return valorNoAlineadas;
    });
}



    
    
    var graph1 = {
      series: [
      {
        name: "Operaciones",
        data: valores
      }
    ],
    options:{
      chart: {
      height: 350,
      type: 'line',
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      }
    },
    colors: ['#77B6EA'],
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'smooth'
    },
    title: {
      text: 'Evolución de las operaciones realizadas en el mes actual',
      align: 'left',
      offsetX: 0,
      style: {fontSize:  '14px', fontWeight:  '500', color:  '#83838d',},      
    },
    grid: {
      borderColor: '#e7e7e7',
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      },
    },
    markers: {
      size: 1
    },
    tooltip: {
      x: {
        show: true,
        formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
          let dia = w.globals.labels[dataPointIndex]; // Obtener el día del eje X
          return `Día: ${dia}<br>`; // Devolver el día junto con el valor del tooltip
        }
      }
    
    },
    xaxis: {
      categories: diasCompletos,
      title: {
        text: 'Día'
      }
    },
    yaxis: {
      title: {
        text: 'Operaciones'
      },
      min: 0,
      max: Math.ceil(Math.max(...valores) / 10) * 10, // Round up to the nearest multiple of 10
      
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      offsetY: -25,
      offsetX: -5
    }
  }
    }

    var graph2 = {          
      series: datosFormateados.map(i=>{
        let aux = {
          name:i.name,
          data:i.data.map(n=>{
            if(n>5000){
              return 5000
            }else{
              return n
            }
          })
        }
        return aux
      }),
      options: {
      colors:[
          '#001B45',
          '#334C77',
          '#6679A9',
          '#99A7DC',
          '#AED0F2',
          '#CCE5FF',          
          
      ],
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius:0, //10,
            borderRadiusApplication: 'end', // 'around', 'end'
            borderRadiusWhenStacked: 'last', // 'all', 'last'
            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: '13px',
                  fontWeight: 900
                },
                formatter: function (value, { seriesIndex, dataPointIndex, w }) {
                  let mes = w.globals.labels[dataPointIndex];              
                  let ind = fechasFormateadas.indexOf(mes)                   
                  let suma = 0
                  datosFormateados.forEach( i => {
                    suma = suma + i.data[ind]
                  })
                  return suma.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                },
              }              
            }
          },
        },
        yaxis: {
          title: {
            text: 'Operaciones'
          }
        },
        xaxis: {
          type: 'date',
          categories: fechasFormateadas,
          title: {
            text: 'Mes'
          }
        },
        legend: {
          position: 'right',
          offsetY: 40
        },
        title: {
          text: 'Evolución de las operaciones realizadas agrupadas por actividad económica',
          align: 'left',
          offsetX: 5,
          style: {fontSize:  '14px', fontWeight:  '500', color:  '#83838d',},      
        },
         tooltip: {
          y: {
            formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
              let mes = w.globals.labels[dataPointIndex];              
              let tipoEvaluacion = w.config.series[seriesIndex].name;
              let ind = fechasFormateadas.indexOf(mes)
              let val = datosFormateados.filter(i=> i.name === tipoEvaluacion)[0].data[ind] 
              

       
              return `${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} operaciones`;
            }
          },
          x: {
            show: true,
            formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
              let mes = w.globals.labels[dataPointIndex]; // Obtener el día del eje X
              return `Fecha: ${mes}<br>`; // Devolver el día junto con el valor del tooltip
            }
          }
          
        },
        fill: {
          opacity: 1
        },
        dataLabels: {
          enabled: true,
          formatter: function (val, { seriesIndex, dataPointIndex, w }) {
            let originalValue = datosFormateados[seriesIndex].data[dataPointIndex];
            return (originalValue > 5000? "> ":"") + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          },
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        }
      },      
    }

    var graph3 = {          
      series: [{
        name: 'Operaciones Alineadas',
        data: operacionesAlineadasComp.map(n=>{
          if(n>5000){
            return 5000
          }else{
            return n
          }
        })
      }, {
        name: 'Operaciones No alineadas',
        data: operacionesNoAlineadasComp.map(n=>{
          if(n>5000){
            return 5000
          }else{
            return n
          }
        })
      }],
      options: {
        chart: {
          type: 'bar',
          height: 350
        },
        colors: ['#cce5ff', '#6679A9'],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '75%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: 20,
          style: {
            fontSize: '13px',
            colors: ["#001B45"]
          }
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          type: 'date',
          categories: fechasAlineadasVsNoAlineadas,
          title: {
            text: 'Mes'
          }
        },
        yaxis: {
          title: {
            text: 'Operaciones'
          }
        },
        tooltip: {
          y: {
            formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
              let mes = w.globals.labels[dataPointIndex];    
              let serie = seriesIndex == 0 ? operacionesAlineadasComp : operacionesNoAlineadasComp              
              let ind = fechasFormateadas.indexOf(mes)
              let val = serie[ind]     
              return `${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} operaciones`;
            }
          },
          x: {
            show: true,
            formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
              let mes = w.globals.labels[dataPointIndex]; // Obtener el día del eje X
              return `Fecha: ${mes}<br>`; // Devolver el día junto con el valor del tooltip
            }
          }
        },
        fill: {
          opacity: 1
        },
        dataLabels: {
          enabled: true,
          formatter: function (value, { seriesIndex, dataPointIndex, w }) {
            let mes = w.globals.labels[dataPointIndex];    
            let serie = seriesIndex == 0 ? operacionesAlineadasComp : operacionesNoAlineadasComp              
            let ind = fechasFormateadas.indexOf(mes)
            let val = serie[ind]  
            return (val > 5000? "> " + "5.000" : val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."));
          },
          style: {
            fontSize: '12px',       
            maxwidth:'1rem',
            whiteSpace: 'normal', 
            wordWrap: 'break-word' ,
            overflowWrap: 'break-word',
            textAlign: 'center', 
            display: 'block',  
            colors: ["#304758"],
            cssClass: 'custom-data-label'            
          }
        }        
      },


    };

const colsKPIs = "col-xl-3 col-lg-6 col-md-6 col-sm-6"
  
    return (
      
      <React.Fragment>
        <ModalWarning header={<span className='section-title-modal'>Error</span>} message={errorMsg} show={errorFile} handle={handleErrorFile} onlyButtonContinue> </ModalWarning>
        {mostrar && (
          <>
            <div className='section-block first-block d-flex align-items-end'>
                <div className='principal-title'>                    
                    <span className='header-title d-inline'>Dashboard analítico</span>
                </div>
                <span className='dashboard-info'> Datos correspondientes al mes: {nombresMeses[mes]} de {año}</span>
            </div>

                <div style={{display:"flex",flexWrap:"wrap"}}>                    
                  <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12">
                      <div class= "card dashboardCard">
                        <div class= "card-body">
                          <div class="d-flex align-items-center">
                              <span class="card-badgeBg">
                                <BiRightArrow className='eye card-badge' />
                              </span>
                          </div>
                          <div class="flex-grow-1 overflow-hidden ms-4">
                            <p class="text-muted text-truncate font-size-1 mb-2"title="Operaciones Realizadas"> Operaciones Realizadas</p>
                            
                            <h3 class="fs-4 flex-grow-1  text-truncate">{operacionesRealizadas.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</h3>
                            <p class="text-muted mb-0 text-truncate d-flex">
                            <span class={"badge " + (parseInt(porcOperacionesRealizadas) > 0 ? "card-success" : parseInt(porcOperacionesRealizadas) == 0 ? "card-neutral" : "card-danger") + " font-size-12 fw-normal me-1"}>
                                <i class="mdi mdi-arrow-top-right"></i> 
                                {Math.abs(porcOperacionesRealizadas).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}  {porcOperacionesRealizadas > 0 ?  "▲" :porcOperacionesRealizadas == 0 ? 
                                <div class="card-neutral-icon">
                                  ▸
                                </div> :"▼"}
                              </span>
                              <span id="vsText" title="vs mes anterior">vs mes anterior</span>
                            </p>
                          </div>
                          <div class="flex-shrink-0 align-self-start">
                          <DropdownButton className='card-btn' align='start' drop='down-centered'
                            title={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16"><path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/></svg>} >
                              <Dropdown.Item className='dropdown-item-new-action fs-8' onClick={()=>{
                                const mensual = dic1[14]["N_CONSULTAS"];
                                const mensualPrev = dic1[15]["N_CONSULTAS"];
                                handleSetOperacionesRealizadas(mensual);
                                handleSetPorcOperacionesRealizadas(mensual - mensualPrev);
                                const vsTextElement = document.getElementById("vsText");
                                document.getElementById("vsText").innerText = "vs mes anterior";
                                vsTextElement.title = "vs mes anterior";
                                }}>Mensual</Dropdown.Item>
                                
                              <Dropdown.Item className='dropdown-item-new-action fs-8' onClick={()=>{
                                const anual = dic1[12]["N_CONSULTAS"];
                                const anualPrev = dic1[13]["N_CONSULTAS"];
                                handleSetOperacionesRealizadas(anual);
                                handleSetPorcOperacionesRealizadas(anual - anualPrev);
                                const vsTextElement = document.getElementById("vsText");
                                document.getElementById("vsText").innerText = "vs año anterior";
                                vsTextElement.title = "vs año anterior";
                                }}>Anual</Dropdown.Item>
                                
                          
                          </DropdownButton>
                          </div>
                        </div>
                      </div>
                  </div>
                                       
                  <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12">
                      <div class= "card dashboardCard MiddlelastDashboardCard">
                        <div class= "card-body">
                          <div class="d-flex align-items-center">
                              <span class="card-badgeBg">
                                <MdCancel className='color-fail card-badge card-dangerBadgeSize' />                                
                              </span>
                          </div>
                          <div class="flex-grow-1 overflow-hidden ms-4">
                            <p class="text-muted text-truncate font-size-15 mb-2"title="Operaciones No Alineadas"> Operaciones No Alineadas</p>
                            <h3 class="fs-4 flex-grow-1  text-truncate d-flex">{operacionesNoAlineadas.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</h3>
                            <p class="text-muted mb-0 text-truncate">
                            <span class={"badge " + (parseFloat(porcOperacionesNoAlineadas.toString().slice(0, -1)) > 0 ? "card-success" : parseFloat(porcOperacionesNoAlineadas.toString().slice(0, -1)) == 0 ? "card-neutral" : "card-danger") + " font-size-12 fw-normal me-1"}>
                                <i class="mdi mdi-arrow-top-right"></i> 
                                {Math.abs(porcOperacionesNoAlineadas).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} {porcOperacionesNoAlineadas > 0 ? "▲" :porcOperacionesNoAlineadas == 0 ? 
                                <div class="card-neutral-icon">
                                  ▸
                                </div> :"▼"}
                              </span>
                              <span id="vsText2" title="vs mes anterior">vs mes anterior</span>
                            </p>
                          </div>
                          <div class="flex-shrink-0 align-self-start">
                          <DropdownButton className='card-btn' align='right' drop='down-centered'
                            title={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16"><path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/></svg>} >
                               <Dropdown.Item className='dropdown-item-new-action fs-8'onClick={()=>{
                                const mensual = dic1[10]["N_CONSULTAS"];
                                const mensualPrev = dic1[11]["N_CONSULTAS"];
                                handleSetOperacionesNoAlineadas(mensual);
                                handleSetPorcOperacionesNoAlineadas(mensual - mensualPrev);
                                const vsText2Element = document.getElementById("vsText2");
                                document.getElementById("vsText2").innerText = "vs mes anterior";
                                vsText2Element.title = "vs mes anterior";}}>Mensual</Dropdown.Item>
                              <Dropdown.Item className='dropdown-item-new-action fs-8' onClick={()=>{
                                const anual = dic1[8]["N_CONSULTAS"];
                                const anualPrev = dic1[9]["N_CONSULTAS"];
                                handleSetOperacionesNoAlineadas(anual);
                                handleSetPorcOperacionesNoAlineadas(anual - anualPrev);
                                const vsText2Element = document.getElementById("vsText2");
                                document.getElementById("vsText2").innerText = "vs año anterior";
                                vsText2Element.title = "vs año anterior";}}>Anual</Dropdown.Item>
                              </DropdownButton>
                          </div>
                        </div>
                      </div>
                  </div>
                      
                  <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12">
                      <div class= "card dashboardCard">
                        <div class= "card-body">
                          <div class="d-flex align-items-center">
                              <span class="card-badgeBg">
                                <BsCheckCircleFill className='color-succes card-badge' />
                              </span>
                          </div>
                          <div class="flex-grow-1 overflow-hidden ms-4">
                            <p class="text-muted text-truncate font-size-15 mb-2"title="Operaciones Alineadas"> Operaciones Alineadas</p>
                            <h3 class="fs-4 flex-grow-1  text-truncate">{operacionesAlineadas.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</h3>
                            <p class="text-muted mb-0 text-truncate d-flex">
                            <span class={"badge " + (porcOperacionesAlineadas > 0 ? "card-success" : porcOperacionesAlineadas == 0 ? "card-neutral" : "card-danger") + " font-size-12 fw-normal me-1"}>
                                <i class="mdi mdi-arrow-top-right"></i>
                                {Math.abs(porcOperacionesAlineadas).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} {porcOperacionesAlineadas > 0 ? "▲" : porcOperacionesAlineadas == 0 ? 
                                <div class="card-neutral-icon">
                                  ▸
                                </div> :"▼"}
                              </span>
                              <span id="vsText3" title="vs mes anterior">vs mes anterior</span>
                            </p>
                          </div>
                          <div class="flex-shrink-0 align-self-start">
                          <DropdownButton className='card-btn' align='right' drop='down-centered'
                            title={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16"><path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/></svg>} >
                              <Dropdown.Item className='dropdown-item-new-action fs-8'onClick={()=>{
                                const mensual = dic1[6]["N_CONSULTAS"];
                                const mensualPrev = dic1[7]["N_CONSULTAS"];
                                handleSetOperacionesAlineadas(mensual);
                                handleSetPorcOperacionesAlineadas(mensual - mensualPrev);
                                const vsText3Element = document.getElementById("vsText3");
                                document.getElementById("vsText3").innerText = "vs mes anterior";
                                vsText3Element.title = "vs mes anterior";}}>Mensual</Dropdown.Item>
                              <Dropdown.Item className='dropdown-item-new-action fs-8' onClick={()=>{
                                const anual = dic1[4]["N_CONSULTAS"];
                                const anualPrev = dic1[5]["N_CONSULTAS"];
                                handleSetOperacionesAlineadas(anual);
                                handleSetPorcOperacionesAlineadas(anual - anualPrev);
                                const vsText3Element = document.getElementById("vsText3");
                                document.getElementById("vsText3").innerText = "vs año anterior";
                                vsText3Element.title = "vs año anterior";}}>Anual</Dropdown.Item>
                             </DropdownButton>
                          </div>
                        </div>
                      </div>
                  </div>

                  <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12">
                      <div class= "card dashboardCard lastDashboardCard">
                        <div class= "card-body">
                          <div class="d-flex align-items-center">
                              <span class="card-badgeBg">
                                <BsDashCircleFill className='color-pending card-badge' />
                              </span>
                          </div>
                          <div class="flex-grow-1 overflow-hidden ms-4">
                            <p class="text-muted text-truncate font-size-15 mb-2"title="Operaciones Activas"> Operaciones Activas</p>
                            <h3 class="fs-4 flex-grow-1  text-truncate">{operacionesActivas.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</h3>
                            <p class="text-muted mb-0 text-truncate d-flex">
                                <span class={"badge " + (porcOperacionesActivas > 0 ? "card-success" : porcOperacionesActivas == 0 ? "card-neutral" : "card-danger") + " font-size-12 fw-normal me-1"}>                                
                                  {Math.abs(porcOperacionesActivas).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} 
                                  {porcOperacionesActivas > 0 ?  "▲" :porcOperacionesActivas == 0 ? 
                                <div class="card-neutral-icon">
                                  ▸
                                </div>
                                   :"▼"}                                  
                              </span>
                              <span id="vsText4" title="vs mes anterior">vs mes anterior</span>
                            </p>
                          </div>
                          <div class="flex-shrink-0 align-self-start">
                          <DropdownButton className='card-btn' align='right' drop='down-centered'
                            title={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16"><path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/></svg>} >
                              <Dropdown.Item className='dropdown-item-new-action fs-8'onClick={()=>{
                                const mensual = dic1[2]["N_CONSULTAS"];
                                const mensualPrev = dic1[3]["N_CONSULTAS"];
                                handleSetOperacionesActivas(mensual);
                                handleSetPorcOperacionesActivas(mensual - mensualPrev);
                                const vsText4Element = document.getElementById("vsText4");
                                document.getElementById("vsText4").innerText = "vs mes anterior";
                                vsText4Element.title = "vs mes anterior";}}>Mensual</Dropdown.Item>
                              <Dropdown.Item className='dropdown-item-new-action fs-8' onClick={()=>{
                                const anual = dic1[0]["N_CONSULTAS"];
                                const anualPrev = dic1[1]["N_CONSULTAS"];
                                handleSetOperacionesActivas(anual);
                                handleSetPorcOperacionesActivas(anual - anualPrev);
                                const vsText4Element = document.getElementById("vsText4");
                                document.getElementById("vsText4").innerText = "vs año anterior";
                                vsText4Element.title = "vs año anterior";}}>Anual</Dropdown.Item>
                              </DropdownButton>
                          </div>
                        </div>
                      </div>                    
                      {/* Fin KPIs */}
                  </div>
                </div>

<div className='section-block'>
  <div className="section-content">      
    <div className='row'>
      <div className='col-xl-6 col-lg-7'>
        <div class="summaryAligment-titleContainer paddingLeftNone mb-2">
          <span class="summaryAligment-title"> Seguimiento de Evaluaciones Realizadas </span>
        </div>
      </div>
    </div>     
    <div className='row'>
      <div className='col-lg-12 paddingLeftNone'>
        <div style={{ position: 'relative' }}>
          <div style={{ position: 'absolute', top: '20px', right: '0', zIndex: '100', padding: '10px' }}>
            <span className='dashboard-info'>Total Operaciones Realizadas: {sumaValores}</span>
          </div>
          <Chart options={graph1.options} series={graph1.series} type="line" height={350} />
        </div>
      </div>                        
    </div>
  </div>
</div>

            
            <div className='section-block'>
                <div className="section-content">      
                <div className='row'>
                  <div className='col-xl-7'>
                    <div class="summaryAligment-titleContainer paddingLeftNone mb-2">
                      <span class="summaryAligment-title"> Seguimiento de Evaluaciones Realizadas por Actividad </span>
                    </div>
                  </div>
                </div>     
                    <div className='row'>
                        <div className='col-lg-12 paddingLeftNone'>
                        <Chart options={graph2.options} series={graph2.series} type="bar" height={350} />
                        </div>                        
                    </div>
                </div>
            </div> 

            <div className='section-block'>
                <div className="section-content">     
                <div className='row'>
                  <div className='col-xl-7'>
                    <div class="summaryAligment-titleContainer paddingLeftNone mb-2">
                      <span class="summaryAligment-title"> Monitorización Resultados de Alineamiento con la Taxonomía</span>
                    </div>
                  </div>
                </div>   
                  <div className='row'>
                    <div className='col-lg-8 paddingLeftNone'>
                      {/* <Chart options={graph3.options} series={graph3.series} type="line" height={350} /> */}
                      <Chart options={graph3.options} series={graph3.series} type="bar" height={350}/>
                    </div>
                    <div className='col-lg-4'>
                    <div className='dashboard-subtitle'>
                      Causas de no alineamiento mas comunes del mes actual
                    </div>
                      {/* <Chart options={graph1.options} series={graph1.series} type="donut" /> */}
                      <SimpleBar style={{ maxHeight: 300 }}>
                      {Object.values(dic5).sort(sortErrors).map((x,i) => (
                        <div className='dashboard-tableElement'>
                          <div title={x.DETALLE_RESULTADO} className='dashboard-tableElementTitle'>{i+1 +".- "+x.DETALLE_RESULTADO}</div> 
                          <div className='dashboard-tableElementTrend'>
                            {x.TREND == 1 && 
                              <MdTrendingUp 
                                title={x.N_ERRORES + (x.N_ERRORES === 1 ? " error respecto al mes anterior" : " errores respecto al mes anterior")} 
                                className='dashboard-tableElementTrendIcon text-success'
                              />
                            }
                            {x.TREND == 2 && 
                              <MdTrendingDown 
                                title={x.N_ERRORES + (x.N_ERRORES === 1 ? " error respecto al mes anterior" : " errores respecto al mes anterior")} 
                                className='dashboard-tableElementTrendIcon text-danger'
                              />
                            }
                            {x.TREND == 3 && 
                              <MdTrendingFlat 
                                title={x.N_ERRORES + (x.N_ERRORES === 1 ? " error respecto al mes anterior" : " errores respecto al mes anterior")} 
                                className='dashboard-tableElementTrendIcon text-pending'
                              />
                            }
                            <div className='dashboard-tableElementTrendText text-muted'>{x.N_ERRORES.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</div>                          
                          </div>
                        </div>
                      ))}
                      </SimpleBar>
                    </div>
                  </div>
                </div>
            </div>
                  
            </>)}
        </React.Fragment>)
      
        
}
