import React, { useState, useEffect } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import CloseIcon from '@mui/icons-material/Close';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { HiOutlineTrash } from 'react-icons/hi'
import { BiRightArrow } from 'react-icons/bi'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from "react-router-dom";
import { MdOutlineSummarize } from "react-icons/md";
import './table.css';
import { MdOutlineError } from 'react-icons/md'
import QuestionsEnded from '../../api/questionsEnded'
import QuestionsEnergyEnded from '../../api/questionsEnergyEnded'
import QuestionsTransportEnded from '../../api/questionsTransportEnded'
import PopOver from '../popOver/popOver'
import excel from '../../api/exportTable.xlsx'
import { MdOutlineRefresh, MdFilterListAlt } from "react-icons/md"
import { RiFileExcel2Fill } from "react-icons/ri"
import questionsTransportEnded from '../../api/questionsTransportEnded';
import SearchFilter from '../searchFilter/searchFilter'


function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function TableBatch(props) {

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ms-3">
            Mostrando de la {from} a la {to} de {size} evaluaciones
        </span>
    );

    const [windowWidth, setWindowWidth] = useState(getWindowDimensions().width);

    //Ancho de la ventana a partir del cual ocultamos columnas y las mostramos como filas
    const hiddingValues = { date: 700, opData: 0, sector: 1500, activity: 800, errorMsg: 1000, status: 400, actions: 0,aligment: 600}

    const [hideDate, setHideDate] = useState();
    const [hideOpData, setHideOpData] = useState();
    const [hideSector, setHideSector] = useState();
    const [hideActivity, setHideActivity] = useState();
    const [hideStatus, setHideStatus] = useState();
    const [hideErrMsg, setHideErrMsg] = useState();
    const [hideActions, setHideActions] = useState();
    const [hideAligment, setHideAligment] = useState();

    const [productsState, setProductsState] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [filter, setFilter] = useState(false)

    useEffect(() => {
        setProductsState(props.data)
        setFilteredData(props.data)
    }, [props.data])

    useEffect(() => {
        function handleResize() {
            setWindowWidth(getWindowDimensions().width);
        }

        setHideDate(getWindowDimensions().width < hiddingValues.date);
        setHideOpData(getWindowDimensions().width < hiddingValues.opData);
        setHideSector(getWindowDimensions().width < hiddingValues.sector);
        setHideActivity(getWindowDimensions().width < hiddingValues.activity);
        setHideStatus(getWindowDimensions().width < hiddingValues.status);
        setHideErrMsg(getWindowDimensions().width < hiddingValues.errorMsg);
        setHideActions(getWindowDimensions().width < hiddingValues.actions);
        setHideAligment(getWindowDimensions().width < hiddingValues.aligment);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [windowWidth]);

    const handleRefresh = () => {
        setFilter(false)
        props.handleRefresh()
    }

    const handleTableSearch = (filtered) => {
        setFilteredData(filtered)
    }

    let size = 10
    if (props.size !== undefined) {
        size = props.size
    }

    const pagination = paginationFactory({
        page: 1,
        sizePerPage: size,
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '3', value: 3
        }, {
            text: '5', value: 5
        }, {
            text: '10', value: 10
        }, {
            text: '25', value: 25
        }, {
            text: '50', value: 50
        }, {
            text: 'All', value: productsState.length
        }],
        lastPageText: '>>',
        firstPageText: '<<',
        nextPageText: '>',
        prePageText: '<',
        alwaysShowAllBtns: true,
    });

    function dropRow(event, param) {
        setProductsState(productsState.filter(item => item.id !== param.id))
        setFilteredData(filteredData.filter(item => item.id !== param.id))
    }

    const expandRow = {
        renderer: row => (
            <>
                {hideDate &&
                    <div className='data-table-initial'>
                        <div className='sentence-initial-table col-4'>
                            <strong>Fecha: </strong>
                        </div>
                        <div className='w-auto data-initial-table data-cell-breakWord'>
                            <span>{row.date}</span>
                        </div>
                    </div>
                }
                {hideOpData &&
                    <div className='data-table-initial'>
                        <div className='sentence-initial-table col-4'>
                            <strong>Datos Operación: </strong>
                        </div>
                        <div className='w-auto data-initial-table data-cell-breakWord'>
                            <span>{row.idOperation}{row.desc}{row.evaluationId}</span>
                        </div>
                    </div>}

                {hideSector &&
                    <div className='data-table-initial'>
                        <div className='sentence-initial-table col-4'>
                            <strong>Sector: </strong>
                        </div>
                        <div className='w-auto data-initial-table data-cell-breakWord'>
                            <span>{row.sector}</span>
                        </div>
                    </div>}
                {hideErrMsg &&
                    <div className='data-table-initial'>
                        <div className='sentence-initial-table col-4'>
                            <strong>Mensaje de error: </strong>
                        </div>
                        <div className='w-auto data-initial-table data-cell-breakWord'>
                            <span>{row.activty}</span>
                        </div>
                    </div>}
                {hideActivity &&
                    <div className='data-table-initial'>
                        <div className='sentence-initial-table col-4'>
                            <strong>Actividad: </strong>
                        </div>
                        <div className='w-auto data-initial-table data-cell-breakWord'>
                            <span>{row.activity}</span>
                        </div>
                    </div>}
                {hideStatus &&
                    <div className='data-table-initial'>
                        <div className='sentence-initial-table col-4'>
                            <strong>Estado: </strong>
                        </div>
                        <div className='w-auto data-initial-table data-cell-breakWord'>
                            <span>
                                {row.status === 3 ? 'Error' : row.status === 1 ? 'Activa' : row.status === 2 ? 'Finalizada ' : 'Inicial '}
                                {row.status === 3 ? <PopOver title={<MdOutlineError className='icon nErr' />} message={"Formato incorrecto del fichero"} /> :
                                    <span className={`badge ${row.status === 1 ? 'text-bg-pending' : row.status === 2 ? 'text-bg-success' : row.status === 3 ? 'text-bg-ko' : 'text-bg-initial'}`}>.</span>}
                            </span>
                        </div>
                    </div>}
                {hideActions &&
                    <div className='data-table-initial'>
                        <div className='sentence-initial-table col-4'>
                            <strong>Acciones: </strong>
                        </div>
                        <div className='w-auto data-initial-table'>
                            <span>
                                {row.registerStateId === 1 || row.registerStateId === 2 ? <Link className='play-button' to={`/evaluation/1/${row.sectorId}/${row.activityId}/${row.objectiveId}/${row.registerStateId}/${row.evaluationId}`} ><PopOver title={<BiRightArrow className='eye color-secondary' />} message={"Ver evaluación"} /></Link> :
                                    <BiRightArrow disabled className='eye color-gray cursor-not' />
                                }
                                <PopOver title={<button className='play-button' onClick={event => dropRow(event, row)}><HiOutlineTrash className='eye color-secondary' /></button>} message={"Eliminar"} />
                            </span>
                        </div>
                    </div>}
            </>
        ),
        showExpandColumn: windowWidth < Math.max(...Object.values(hiddingValues)),
        nonExpandable: windowWidth >= Math.max(...Object.values(hiddingValues)) ? (productsState.length ? [...Array(productsState[0].evaluationId + 1).keys()] : []) : [],
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            if (isAnyExpands) {
                return <b></b>;
            }
            return <b></b>;
        },
        expandColumnRenderer: ({ expanded }) => {
            if (expanded) {
                return (<b className='cursor-pointer'>-</b>);
            }
            return (
                <b className='cursor-pointer'>+</b>
            );
        }
    }

    const columns = [
        {
            dataField: 'evaluationId',
            text: 'Oculta',
            hidden: true,
            sort: false
        },
        {
            dataField: 'date',
            text: 'Fecha',
            style: { width: '6rem' },
            hidden: hideDate,
            sort: false
        },
        {
            dataField: 'desc',
            text: 'Datos Operación',
            sort: false,
            hidden: hideOpData,
            formatter: (value, row) => (
                <span>{row.idOperation}</span>
            )
        },
        {
            dataField: 'sector',
            text: 'Sector',
            hidden: hideSector,
            sort: false
        },
        {
            dataField: 'activity',
            text: 'Actividad',
            hidden: hideActivity,
            style: { maxWidth: '19rem', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"},
            sort: false,
            formatter: (value, row) => (
                <span title={value}>{value}</span>
            )
        },
        {
            dataField: 'errorMessage',
            text: 'Mensaje de Error',
            hidden: hideErrMsg,
            sort: false,
        },
        {
            dataField: 'status',
            text: 'Estado',
            sort: false,
            headerAlign: 'center',
            hidden: hideStatus,
            style: { width: '6.25rem' },
            formatter: (value, row) => (
                <span className='float-end'>
                    {row.stateDescription + " "}
                    <span className={`badge ${row.registerStateId === 2 ? 'text-bg-success' : row.registerStateId === 3 ? 'text-bg-ko' : 'text-bg-pending'}`}> { } </span>
                </span>
            )
        },
        {
            dataField: 'alignmentStatus',
            text: 'Resultado',
            hidden: hideAligment,
            sort: false,
            headerAlign: 'center',
            align: 'center',
            formatter: (value, row) => (
                <span>
                    {value === 2 ? 'No alineada' : value === 1 ? 'Alineada' : '--'}
                </span>
            )
        },
        {
            dataField: 'actions',
            text: '',
            sort: false,
            align: 'center',
            hidden: hideActions,
            style: { width: '6.25rem' },
            formatter: (value, row) => (
                <span>
                    {row.registerStateId === 1 || row.registerStateId === 2 ? <Link className='play-button' to={`/evaluation/1/${row.sectorId}/${row.activityId}/${row.objectiveId}/${row.registerStateId}/${row.evaluationId}`} ><PopOver title={<BiRightArrow className='eye color-secondary' />} message={"Ver evaluación"} /></Link> :
                        <BiRightArrow disabled className='eye color-gray cursor-not' />
                    }
                    {
                        (row.alignmentStatus !== null) ?
                        <Link className='play-button' to={`/summary/3/${row.sectorId}/${row.activityId}/${row.objectiveId}/${row.status}/${row.evaluationId}`} >
                            <PopOver 
                                title={
                                    <a className='play-button'>
                                        <MdOutlineSummarize className='eye color-secondary ms-2' />
                                    </a>} message={"Ver Resumen"}                                     
                            />
                        </Link>
                        :
                        <MdOutlineSummarize className='eye color-gray ms-2' />
                    }
                    <PopOver title={<button className='play-button' onClick={event => dropRow(event, row)}><HiOutlineTrash className='eye color-secondary' /></button>} message={"Eliminar"} />
                </span>
            )
        }
    ];

    const defaultSorted = [{
        dataField: 'date',
        order: 'desc'
    }];

    return (<>
        {filter && <SearchFilter data={filteredData} pDataType="0" handleTable={handleTableSearch} />}
        <div className='principal-title  my-2'>
            <span className='section-title d-inline'>
                {props.blockName ? `Desglose evaluaciones ${props.blockName} ` : 'Desglose evaluaciones'}
            </span>
            <div className='actions'>
                {/* <PopOver title={<div onClick={() => (setFilter(!filter))} className='circle-icon me-2'><MdFilterListAlt className='color-icon eye' /></div>} message={"Filtrar"} /> */}
                <PopOver title={<div onClick={() => handleRefresh()} className='circle-icon me-2'><MdOutlineRefresh className='color-icon eye' /></div>} message={"Actualizar"} />
                {/* <PopOver title={<div className='circle-icon me-2'><a href={excel} target="_blank" rel="noopener noreferrer" download="Excel_Multiples_Consultas"><RiFileExcel2Fill style={{ marginBottom: '0.15rem' }} className='color-icon eye' /></a></div>} message={"Exportar Excel"} /> */}

            </div>
        </div>
        {(filteredData && filteredData.length === 0) ?
            <div className='no-result'>No hay resultados para los criterios de búsqueda seleccionados</div> :
            <BootstrapTable responsive
                classes='table-basic table-hover table-borderless'
                bootstrap4
                keyField='evaluationId'
                data={filteredData}
                columns={columns}
                defaultSorted={defaultSorted}
                pagination={pagination}
                expandRow={expandRow} />
        }
    </>
    );
}


export default TableBatch;
