import ComponentFormNewAction from './componentForNewAction/componentFormNewAction'
import React, { useState, useEffect, useContext } from 'react';

export default function FormNewAction({ setOpenForm }) {
    return (
        <React.Fragment>
            <div id='new-action' className='section-block'>
                <div className="section-content">
                    <ComponentFormNewAction />
                </div>
            </div>
        </React.Fragment>)
}
