import React from "react";
import { useRoutes } from "react-router-dom";
import Home from "../pages/home/home";
import Search from "../pages/search/search";
import Dashboard from "../pages/dashboard/dashboard";
import Evaluation from "../pages/evaluation/evaluation";
import Summary from "../pages/evaluation/summary";
import BatchEvaluation from "../pages/batchEvaluation/batchEvaluation";
import Login from "../pages/login/login";
//import {UsuarioAutenticadoContext} from '../context/authenticatedUser'

export default function Router() {
  /*const { usuario } = useContext(UsuarioAutenticadoContext);*/
  /*let element = useRoutes([
      { path: '*', element: !user ? <Login />:<Home /> },
      { path: '/', element: !user ? <Login />:<Home /> },
      { path: '/newaction', element: !user ? <Login />:<NewAction /> },
      { path: '/evaluation', element: !user ? <Login />:<Evaluation /> },
      { path: '/evaluation/:tipo/:ambito/:actividad/:objetivo', element: !user ? <Login />:<Evaluation /> },
      { path: '/evaluation/:tipo/:cnae', element: !user ? <Login />:<Evaluation /> }
    ]);*/

    var initialRoutes = [
      { path: '*', element: <Home /> },
      { path: '/login', element: <Login /> },
      { path: '/', element: <Home /> },
      //{ path: '/newaction', element: <FormNewAction /> },
      { path: '/evaluation', element: <Evaluation /> },
      { path: '/evaluation/:tipo/:ambito/:actividad/:objetivo', element: <Evaluation /> },
      { path: '/evaluation/:tipo/:ambito/:actividad/:objetivo/:operacion', element: <Evaluation /> },
      { path: '/evaluation/:tipo/:ambito/:actividad/:objetivo/:status/:consulta', element: <Evaluation /> },
      { path: '/summary/:tipo/:ambito/:actividad/:objetivo/:status/:consulta', element: <Summary /> },
      // { path: '/evaluation/:tipo/:ambito/:actividad/:objetivo/:id/:date/:name/:desc', element: <Evaluation /> },
      { path: '/batchevaluation', element: <BatchEvaluation /> },
      // { path: '/evaluation/:tipo/:cnae', element: <Evaluation /> },
      { path: '/:param', element: <Home /> },
      { path: '/search', element: <Search /> },      
    ]

    const id = localStorage.getItem("PERMISO_VER_DASHBOARD")
    if(parseInt(id) > 0){
      initialRoutes.push({ path: '/dashboard', element: <Dashboard /> })
    }

  return useRoutes(initialRoutes);

  /*  const routes = {
    "/": () => <Home />,
    "/newaction": () => <NewAction />,
    "/evaluate": () => <Evaluate />
  };*/
}


