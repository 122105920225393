import React, { useState, useEffect, useContext } from 'react';
import { Context } from "../../commons/utils/store";
import ButtonNewAction from '../../commons/buttonNewAction/buttonNewAction';
import TableBatch from '../../commons/table/tableBatch'
import TableUpload from '../../commons/table/tableUpload'
import ButtonsHome from '../../commons/buttonsHome/buttonsHome';
import { Button } from 'react-bootstrap';
import ModalWarning from '../../commons/modals/modalWarning'
import UploadFile from '../../commons/uploadFile/uploadFile'
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import taxoApi from '../../api/taxoApi';

export default function BatchEvaluation(props) {

    const [lotes, setLotes] = useState([])
    const [newLote, setNewLote] = useState(false)
    const [blockName, setBlockName] = useState("PET_TEST_VIVIENDA2020_BLOQ10")
    const [singleSelectionsBlock, setSingleSelectionsBlock] = useState([])
    const [re, setRe] = useState(false)
    const [show, setShow] = useState(false)
    const [showWarning, setShowWar] = useState(false)
    const [succes, setSucces] = useState(false)
    const [sect, setSect] = useState('Construcción de edificios y promoción inmobiliaria')

    const tab = '\u00A0';
    var id = 1
    var nsec = 59
    // const dataAPIEvaluated = [
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ1`, id: id++, date: '31-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones.txt', nameId: 'dbLY+MNZyiEj!zrbh?m?%E(L3akg=d',   nReg: 300, nEval: 295, nErr: 5, result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ2`, id: id++, date: '29-05-2022', type: 'Transporte', nameFich: 'Operaciones2.json', nameId: 'd-Wr7Ja,VK+dpqhkcX?LJcq}@#xux]',                                         nReg: 320, nEval: 320, nErr: 0, result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ3`, id: id++, date: '28-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones3.json', nameId: 'phMY4cm:9JHMM5VBruJ2_4kaHXB5Kq', nReg: 40, nEval: 36, nErr: 4,   result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ4`, id: id++, date: '30-05-2022', type: 'Transporte', nameFich: 'Operaciones4.xlsx', nameId: '5VzSE$T*GtEaWqKJe8bWy$Bq$cgvyW',                                         nReg: 70, nEval: 65, nErr: 5,   result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ5`, id: id++, date: '27-05-2022', type: 'Transporte', nameFich: 'Operaciones5.txt', nameId: 'phMY4cm:9JHMM5VBruJ2_4kaHXB5Kq',                                          nReg: 95, nEval: 94, nErr: 1,   result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ6`, id: id++, date: '26-05-2022', type: 'Transporte', nameFich: 'Operaciones6.json', nameId: '5VzSE$T*GtEaWqKJe8bWy$Bq$cgvyW',                                         nReg: 450, nEval: 450, nErr: 0, result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ7`, id: id++, date: '25-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones7.txt', nameId: 'iw:L&*W+ruL-@L8*P_{]Ex(mZzeD/k',  nReg: 500, nEval: 477, nErr: 23,result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ8`, id: id++, date: '24-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones8.json', nameId: 'd-Wr7Ja,VK+dpqhkcX?LJcq}@#xux]', nReg: 237, nEval: 200, nErr: 37,result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ9`, id: id++, date: '23-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones9.xlsx', nameId: 'dbLY+MNZyiEj!zrbh?m?%E(L3akg=d', nReg: 167, nEval: 167, nErr: 0, result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    // ];

    // const dataAPIEvaluating = [
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ1`, id: id++, date: '31-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones.txt', nameId: 'dbLY+MNZyiEj!zrbh?m?%E(L3akg=d',   nReg: 300, nEval: 295, nErr: 5, result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ2`, id: id++, date: '29-05-2022', type: 'Transporte', nameFich: 'Operaciones2.json', nameId: 'd-Wr7Ja,VK+dpqhkcX?LJcq}@#xux]',                                         nReg: 320, nEval: 160, nErr: 0, result: "Ejecutando", percent: "50", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ3`, id: id++, date: '28-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones3.json', nameId: 'phMY4cm:9JHMM5VBruJ2_4kaHXB5Kq', nReg: 40, nEval: 26, nErr: 4,   result: "Ejecutando", percent: "75", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ4`, id: id++, date: '30-05-2022', type: 'Transporte', nameFich: 'Operaciones4.xlsx', nameId: '5VzSE$T*GtEaWqKJe8bWy$Bq$cgvyW',                                         nReg: 70, nEval: 60, nErr: 5,   result: "Ejecutando", percent: "93", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ5`, id: id++, date: '27-05-2022', type: 'Transporte', nameFich: 'Operaciones5.txt', nameId: 'phMY4cm:9JHMM5VBruJ2_4kaHXB5Kq',                                          nReg: 95, nEval: 94, nErr: 1,   result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ6`, id: id++, date: '26-05-2022', type: 'Transporte', nameFich: 'Operaciones6.json', nameId: '5VzSE$T*GtEaWqKJe8bWy$Bq$cgvyW',                                         nReg: 450, nEval: 400, nErr: 0, result: "Ejecutando", percent: "89", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ7`, id: id++, date: '25-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones7.txt', nameId: 'iw:L&*W+ruL-@L8*P_{]Ex(mZzeD/k',  nReg: 500, nEval: 327, nErr: 23,result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ8`, id: id++, date: '24-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones8.json', nameId: 'd-Wr7Ja,VK+dpqhkcX?LJcq}@#xux]', nReg: 237, nEval: 200, nErr: 37,result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ9`, id: id++, date: '23-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones9.xlsx', nameId: 'dbLY+MNZyiEj!zrbh?m?%E(L3akg=d', nReg: 167, nEval: 167, nErr: 0, result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    // ];

    // const dataStartEv = [
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ1`, id: id++, date: '31-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones.txt', nameId: 'dbLY+MNZyiEj!zrbh?m?%E(L3akg=d',   nReg: 300, nEval: 295, nErr: 5, result: "Completado",percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VEHICULOS2020_BLOQ1`, id: id++, date: '29-05-2022', type: 'Transporte', nameFich: 'Operaciones2.json', nameId: 'd-Wr7Ja,VK+dpqhkcX?LJcq}@#xux]',                                        nReg: 320, nEval: 0, nErr: 0,   result: "Ejecutando", percent: "0", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ2`, id: id++, date: '28-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones3.json', nameId: 'phMY4cm:9JHMM5VBruJ2_4kaHXB5Kq', nReg: 40, nEval: 0, nErr: 0,    result: "Ejecutando", percent: "0", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
    //     { name: `PET_TEST_VEHICULOS2020_BLOQ2`, id: id++, date: '30-05-2022', type: 'Transporte', nameFich: 'Operaciones4.xlsx', nameId: '5VzSE$T*GtEaWqKJe8bWy$Bq$cgvyW',                                        nReg: 70, nEval: 0, nErr: 0,    result: "Ejecutando", percent: "0", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
    //     { name: `PET_TEST_VEHICULOS2020_BLOQ3`, id: id++, date: '27-05-2022', type: 'Transporte', nameFich: 'Operaciones5.txt', nameId: 'phMY4cm:9JHMM5VBruJ2_4kaHXB5Kq',                                         nReg: 95, nEval: 94, nErr: 1,   result: "Completado",percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VEHICULOS2020_BLOQ4`, id: id++, date: '26-05-2022', type: 'Transporte', nameFich: 'Operaciones6.json', nameId: '5VzSE$T*GtEaWqKJe8bWy$Bq$cgvyW',                                        nReg: 450, nEval: 0, nErr: 0,   result: "Ejecutando", percent: "0", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ3`, id: id++, date: '25-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones7.txt', nameId: 'iw:L&*W+ruL-@L8*P_{]Ex(mZzeD/k',  nReg: 500, nEval: 327, nErr: 23,result: "Completado",percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ4`, id: id++, date: '24-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones8.json', nameId: 'd-Wr7Ja,VK+dpqhkcX?LJcq}@#xux]', nReg: 237, nEval: 200, nErr: 37,result: "Completado",percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ5`, id: id++, date: '23-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones9.xlsx', nameId: 'dbLY+MNZyiEj!zrbh?m?%E(L3akg=d', nReg: 167, nEval: 0, nErr: 0,   result: "Ejecutando", percent: "100",detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
    // ];


    // /////////////////////

    // const dataAPISingleEvaluated = [
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ1`, id: id++, date: '31-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones.txt', nameId: 'dbLY+MNZyiEj!zrbh?m?%E(L3akg=d',   nReg: 300, nEval: 295, nErr: 5, result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ2`, id: id++, date: '29-05-2022', type: 'Transporte', nameFich: 'Operaciones2.json', nameId: 'd-Wr7Ja,VK+dpqhkcX?LJcq}@#xux]',                                         nReg: 320, nEval: 320, nErr: 0, result: "Pendiente", percent: "0", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ3`, id: id++, date: '28-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones3.json', nameId: 'phMY4cm:9JHMM5VBruJ2_4kaHXB5Kq', nReg: 40, nEval: 36, nErr: 4,   result: "Pendiente", percent: "0", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ4`, id: id++, date: '30-05-2022', type: 'Transporte', nameFich: 'Operaciones4.xlsx', nameId: '5VzSE$T*GtEaWqKJe8bWy$Bq$cgvyW',                                         nReg: 70, nEval: 65, nErr: 5,   result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ5`, id: id++, date: '27-05-2022', type: 'Transporte', nameFich: 'Operaciones5.txt', nameId: 'phMY4cm:9JHMM5VBruJ2_4kaHXB5Kq',                                          nReg: 95, nEval: 94, nErr: 1,   result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ6`, id: id++, date: '26-05-2022', type: 'Transporte', nameFich: 'Operaciones6.json', nameId: '5VzSE$T*GtEaWqKJe8bWy$Bq$cgvyW',                                         nReg: 450, nEval: 450, nErr: 0, result: "Pendiente", percent: "0", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ7`, id: id++, date: '25-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones7.txt', nameId: 'iw:L&*W+ruL-@L8*P_{]Ex(mZzeD/k',  nReg: 500, nEval: 477, nErr: 23,result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ8`, id: id++, date: '24-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones8.json', nameId: 'd-Wr7Ja,VK+dpqhkcX?LJcq}@#xux]', nReg: 237, nEval: 200, nErr: 37,result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ9`, id: id++, date: '23-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones9.xlsx', nameId: 'dbLY+MNZyiEj!zrbh?m?%E(L3akg=d', nReg: 167, nEval: 167, nErr: 0, result: "Pendiente", percent: "0", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    // ];

    // const dataAPISingleEvaluating = [
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ1`, id: id++, date: '31-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones.txt', nameId: 'dbLY+MNZyiEj!zrbh?m?%E(L3akg=d',   nReg: 300, nEval: 295, nErr: 5, result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ2`, id: id++, date: '29-05-2022', type: 'Transporte', nameFich: 'Operaciones2.json', nameId: 'd-Wr7Ja,VK+dpqhkcX?LJcq}@#xux]',                                         nReg: 320, nEval: 160, nErr: 0, result: "Pendiente", percent: "0", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ3`, id: id++, date: '28-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones3.json', nameId: 'phMY4cm:9JHMM5VBruJ2_4kaHXB5Kq', nReg: 40, nEval: 26, nErr: 4,   result: "Pendiente", percent: "0", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ4`, id: id++, date: '30-05-2022', type: 'Transporte', nameFich: 'Operaciones4.xlsx', nameId: '5VzSE$T*GtEaWqKJe8bWy$Bq$cgvyW',                                         nReg: 70, nEval: 60, nErr: 5,   result: "Ejecutando", percent: "93", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ5`, id: id++, date: '27-05-2022', type: 'Transporte', nameFich: 'Operaciones5.txt', nameId: 'phMY4cm:9JHMM5VBruJ2_4kaHXB5Kq',                                          nReg: 95, nEval: 94, nErr: 1,   result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ6`, id: id++, date: '26-05-2022', type: 'Transporte', nameFich: 'Operaciones6.json', nameId: '5VzSE$T*GtEaWqKJe8bWy$Bq$cgvyW',                                         nReg: 450, nEval: 400, nErr: 0, result: "Pendiente", percent: "0", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ7`, id: id++, date: '25-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones7.txt', nameId: 'iw:L&*W+ruL-@L8*P_{]Ex(mZzeD/k',  nReg: 500, nEval: 327, nErr: 23,result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ8`, id: id++, date: '24-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones8.json', nameId: 'd-Wr7Ja,VK+dpqhkcX?LJcq}@#xux]', nReg: 237, nEval: 200, nErr: 37,result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ9`, id: id++, date: '23-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones9.xlsx', nameId: 'dbLY+MNZyiEj!zrbh?m?%E(L3akg=d', nReg: 167, nEval: 167, nErr: 0, result: "Pendiente", percent: "0", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    // ];

    // const dataStartSingleEv = [
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ1`, id: id++, date: '31-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones.txt', nameId: 'dbLY+MNZyiEj!zrbh?m?%E(L3akg=d',   nReg: 300, nEval: 295, nErr: 5, result: "Completado",percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VEHICULOS2020_BLOQ1`, id: id++, date: '29-05-2022', type: 'Transporte', nameFich: 'Operaciones2.json', nameId: 'd-Wr7Ja,VK+dpqhkcX?LJcq}@#xux]',                                        nReg: 320, nEval: 0, nErr: 0,   result: "Pendiente", percent: "0", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ2`, id: id++, date: '28-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones3.json', nameId: 'phMY4cm:9JHMM5VBruJ2_4kaHXB5Kq', nReg: 40, nEval: 0, nErr: 0,    result: "Pendiente", percent: "0", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
    //     { name: `PET_TEST_VEHICULOS2020_BLOQ2`, id: id++, date: '30-05-2022', type: 'Transporte', nameFich: 'Operaciones4.xlsx', nameId: '5VzSE$T*GtEaWqKJe8bWy$Bq$cgvyW',                                        nReg: 70, nEval: 0, nErr: 0,    result: "Ejecutando", percent: "0", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
    //     { name: `PET_TEST_VEHICULOS2020_BLOQ3`, id: id++, date: '27-05-2022', type: 'Transporte', nameFich: 'Operaciones5.txt', nameId: 'phMY4cm:9JHMM5VBruJ2_4kaHXB5Kq',                                         nReg: 95, nEval: 94, nErr: 1,   result: "Completado",percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VEHICULOS2020_BLOQ4`, id: id++, date: '26-05-2022', type: 'Transporte', nameFich: 'Operaciones6.json', nameId: '5VzSE$T*GtEaWqKJe8bWy$Bq$cgvyW',                                        nReg: 450, nEval: 0, nErr: 0,   result: "Pendiente", percent: "0", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ3`, id: id++, date: '25-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones7.txt', nameId: 'iw:L&*W+ruL-@L8*P_{]Ex(mZzeD/k',  nReg: 500, nEval: 327, nErr: 23,result: "Completado",percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ4`, id: id++, date: '24-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones8.json', nameId: 'd-Wr7Ja,VK+dpqhkcX?LJcq}@#xux]', nReg: 237, nEval: 200, nErr: 37,result: "Completado",percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ5`, id: id++, date: '23-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones9.xlsx', nameId: 'dbLY+MNZyiEj!zrbh?m?%E(L3akg=d', nReg: 167, nEval: 0, nErr: 0,   result: "Pendiente", percent: "0",detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
    // ];

    /////////////////////

    // const dataStart = [
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ1`, id: id++, date: '31-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones.txt', nameId: 'dbLY+MNZyiEj!zrbh?m?%E(L3akg=d',   nReg: 300, nEval: 295, nErr: 5, result: "Completado",percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VEHICULOS2020_BLOQ1`, id: id++, date: '29-05-2022', type: 'Transporte', nameFich: 'Operaciones2.json', nameId: 'd-Wr7Ja,VK+dpqhkcX?LJcq}@#xux]',                                        nReg: 320, nEval: 0, nErr: 0,   result: "Pendiente", percent: "0", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ2`, id: id++, date: '28-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones3.json', nameId: 'phMY4cm:9JHMM5VBruJ2_4kaHXB5Kq', nReg: 40, nEval: 0, nErr: 0,    result: "Pendiente", percent: "0", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
    //     { name: `PET_TEST_VEHICULOS2020_BLOQ2`, id: id++, date: '30-05-2022', type: 'Transporte', nameFich: 'Operaciones4.xlsx', nameId: '5VzSE$T*GtEaWqKJe8bWy$Bq$cgvyW',                                        nReg: 70, nEval: 0, nErr: 0,    result: "Pendiente", percent: "0", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
    //     { name: `PET_TEST_VEHICULOS2020_BLOQ3`, id: id++, date: '27-05-2022', type: 'Transporte', nameFich: 'Operaciones5.txt', nameId: 'phMY4cm:9JHMM5VBruJ2_4kaHXB5Kq',                                         nReg: 95, nEval: 94, nErr: 1,   result: "Completado",percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VEHICULOS2020_BLOQ4`, id: id++, date: '26-05-2022', type: 'Transporte', nameFich: 'Operaciones6.json', nameId: '5VzSE$T*GtEaWqKJe8bWy$Bq$cgvyW',                                        nReg: 450, nEval: 0, nErr: 0,   result: "Pendiente", percent: "0", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ3`, id: id++, date: '25-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones7.txt', nameId: 'iw:L&*W+ruL-@L8*P_{]Ex(mZzeD/k',  nReg: 500, nEval: 327, nErr: 23,result: "Completado",percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ4`, id: id++, date: '24-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones8.json', nameId: 'd-Wr7Ja,VK+dpqhkcX?LJcq}@#xux]', nReg: 237, nEval: 200, nErr: 37,result: "Completado",percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ5`, id: id++, date: '23-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones9.xlsx', nameId: 'dbLY+MNZyiEj!zrbh?m?%E(L3akg=d', nReg: 167, nEval: 0, nErr: 0,   result: "Pendiente", percent: "0",detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
    // ];

    const dataAPIEvaluated = [
        { name: `PET_TEST_VIVIENDA2020_BLOQ1`, id: id++, date: '31-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones.txt', nameId: 'dbLY+MNZyiEj!zrbh?m?%E(L3akg=d', nReg: 300, nEval: 295, nErr: 5, result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
        { name: `PET_TEST_VIVIENDA2020_BLOQ2`, id: id++, date: '29-05-2022', type: 'Transporte', nameFich: 'Operaciones2.json', nameId: 'd-Wr7Ja,VK+dpqhkcX?LJcq}@#xux]', nReg: 320, nEval: 160, nErr: 0, result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
        { name: `PET_TEST_VIVIENDA2020_BLOQ3`, id: id++, date: '28-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones3.json', nameId: 'phMY4cm:9JHMM5VBruJ2_4kaHXB5Kq', nReg: 40, nEval: 26, nErr: 4, result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
        { name: `PET_TEST_VIVIENDA2020_BLOQ4`, id: id++, date: '30-05-2022', type: 'Transporte', nameFich: 'Operaciones4.xlsx', nameId: '5VzSE$T*GtEaWqKJe8bWy$Bq$cgvyW', nReg: 70, nEval: 60, nErr: 5, result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
        { name: `PET_TEST_VIVIENDA2020_BLOQ5`, id: id++, date: '27-05-2022', type: 'Transporte', nameFich: 'Operaciones5.txt', nameId: 'phMY4cm:9JHMM5VBruJ2_4kaHXB5Kq', nReg: 95, nEval: 95, nErr: 0, result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
        { name: `PET_TEST_VIVIENDA2020_BLOQ6`, id: id++, date: '26-05-2022', type: 'Transporte', nameFich: 'Operaciones6.json', nameId: '5VzSE$T*GtEaWqKJe8bWy$Bq$cgvyW', nReg: 450, nEval: 450, nErr: 0, result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
        { name: `PET_TEST_VIVIENDA2020_BLOQ7`, id: id++, date: '25-05-2022', type: 'Energía', nameFich: 'Operaciones7.txt', nameId: 'iw:L&*W+ruL-@L8*P_{]Ex(mZzeD/k', nReg: 500, nEval: 477, nErr: 23, result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
        { name: `PET_TEST_VIVIENDA2020_BLOQ8`, id: id++, date: '24-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones8.json', nameId: 'd-Wr7Ja,VK+dpqhkcX?LJcq}@#xux]', nReg: 237, nEval: 200, nErr: 37, result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
        { name: `PET_TEST_VIVIENDA2020_BLOQ9`, id: id++, date: '23-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones9.xlsx', nameId: 'dbLY+MNZyiEj!zrbh?m?%E(L3akg=d', nReg: 167, nEval: 167, nErr: 0, result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    ];

    const dataAPI = [
        { bloque: `${blockName}`, id: id++, date: '31-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones.txt', nameId: 'dbLY+MNZyiEj!zrbh?m?%E(L3akg=d', nReg: 300, nEval: 0, nErr: 0, result: "Pendiente", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
        // { bloque: `${blockName}`, id: id++, date: '29-05-2022', type: 'Fichero JSON', nameFich: 'Operaciones2.json', nameId: 'd-Wr7Ja,VK+dpqhkcX?LJcq}@#xux]', nReg : 320, nEval : 0, nErr : 0, result: "Pendiente", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
        // { bloque: `${blockName}`, id: id++, date: '28-05-2022', type: 'Fichero JSON', nameFich: 'Operaciones3.json', nameId: 'phMY4cm:9JHMM5VBruJ2_4kaHXB5Kq', nReg : 40, nEval : 0, nErr : 0, result: "Pendiente", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
        // { bloque: `${blockName}`, id: id++, date: '30-05-2022', type: 'Excel', nameFich: 'Operaciones4.xlsx', nameId: '5VzSE$T*GtEaWqKJe8bWy$Bq$cgvyW', nReg : 70, nEval : 0, nErr : 0, result: "Pendiente", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
        // { bloque: `${blockName}`, id: id++, date: '27-05-2022', type: 'Texto Plano', nameFich: 'Operaciones5.txt', nameId: 'phMY4cm:9JHMM5VBruJ2_4kaHXB5Kq', nReg : 95, nEval : 0, nErr : 0, result: "Pendiente", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
        // { bloque: `${blockName}`, id: id++, date: '26-05-2022', type: 'Fichero JSON', nameFich: 'Operaciones6.json', nameId: '5VzSE$T*GtEaWqKJe8bWy$Bq$cgvyW', nReg : 450, nEval : 0, nErr : 0, result: "Pendiente", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
        // { bloque: `${blockName}`, id: id++, date: '25-05-2022', type: 'Texto Plano', nameFich: 'Operaciones7.txt', nameId: 'iw:L&*W+ruL-@L8*P_{]Ex(mZzeD/k', nReg : 500, nEval : 0, nErr : 0, result: "Pendiente", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
        // { bloque: `${blockName}`, id: id++, date: '24-05-2022', type: 'Fichero JSON', nameFich: 'Operaciones8.json', nameId: 'd-Wr7Ja,VK+dpqhkcX?LJcq}@#xux]', nReg : 237, nEval : 0, nErr : 0, result: "Pendiente", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
        // { bloque: `${blockName}`, id: id++, date: '23-05-2022', type: 'Excel', nameFich: 'Operaciones9.xlsx', nameId: 'dbLY+MNZyiEj!zrbh?m?%E(L3akg=d', nReg : 167, nEval : 0, nErr : 0, result: "Pendiente", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
    ];

    const dataAPISingleEvaluated = [
        { name: `PET_TEST_VIVIENDA2020_BLOQ1`, id: id++, date: '31-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones.txt', nameId: 'dbLY+MNZyiEj!zrbh?m?%E(L3akg=d', nReg: 300, nEval: 295, nErr: 5, result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
        { name: `PET_TEST_VEHICULOS2020_BLOQ1`, id: id++, date: '29-05-2022', type: 'Transporte', nameFich: 'Operaciones2.json', nameId: 'd-Wr7Ja,VK+dpqhkcX?LJcq}@#xux]', nReg: 320, nEval: 0, nErr: 0, result: "Pendiente", percent: "0", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
        { name: `PET_TEST_VIVIENDA2020_BLOQ2`, id: id++, date: '28-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones3.json', nameId: 'phMY4cm:9JHMM5VBruJ2_4kaHXB5Kq', nReg: 40, nEval: 0, nErr: 0, result: "Pendiente", percent: "0", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
        { name: `PET_TEST_VEHICULOS2020_BLOQ2`, id: id++, date: '30-05-2022', type: 'Transporte', nameFich: 'Operaciones4.xlsx', nameId: '5VzSE$T*GtEaWqKJe8bWy$Bq$cgvyW', nReg: 70, nEval: 60, nErr: 5, result: "Completado", percent: "100", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
        { name: `PET_TEST_VEHICULOS2020_BLOQ3`, id: id++, date: '27-05-2022', type: 'Transporte', nameFich: 'Operaciones5.txt', nameId: 'phMY4cm:9JHMM5VBruJ2_4kaHXB5Kq', nReg: 95, nEval: 95, nErr: 0, result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
        { name: `PET_TEST_VEHICULOS2020_BLOQ4`, id: id++, date: '26-05-2022', type: 'Transporte', nameFich: 'Operaciones6.json', nameId: '5VzSE$T*GtEaWqKJe8bWy$Bq$cgvyW', nReg: 450, nEval: 0, nErr: 0, result: "Pendiente", percent: "0", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
        { name: `PET_TEST_ENERGIA2020_BLOQ1`, id: id++, date: '25-05-2022', type: 'Energía', nameFich: 'Operaciones7.txt', nameId: 'iw:L&*W+ruL-@L8*P_{]Ex(mZzeD/k', nReg: 500, nEval: 477, nErr: 23, result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
        { name: `PET_TEST_VIVIENDA2020_BLOQ3`, id: id++, date: '24-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones8.json', nameId: 'd-Wr7Ja,VK+dpqhkcX?LJcq}@#xux]', nReg: 237, nEval: 200, nErr: 37, result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
        { name: `PET_TEST_VIVIENDA2020_BLOQ4`, id: id++, date: '23-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones9.xlsx', nameId: 'dbLY+MNZyiEj!zrbh?m?%E(L3akg=d', nReg: 167, nEval: 0, nErr: 0, result: "Pendiente", percent: "0", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
    ];

    const dataAPISingleEvaluating = [
        { name: `PET_TEST_VIVIENDA2020_BLOQ1`, id: id++, date: '31-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones.txt', nameId: 'dbLY+MNZyiEj!zrbh?m?%E(L3akg=d', nReg: 300, nEval: 295, nErr: 5, result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
        { name: `PET_TEST_VEHICULOS2020_BLOQ1`, id: id++, date: '29-05-2022', type: 'Transporte', nameFich: 'Operaciones2.json', nameId: 'd-Wr7Ja,VK+dpqhkcX?LJcq}@#xux]', nReg: 320, nEval: 0, nErr: 0, result: "Pendiente", percent: "0", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
        { name: `PET_TEST_VIVIENDA2020_BLOQ2`, id: id++, date: '28-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones3.json', nameId: 'phMY4cm:9JHMM5VBruJ2_4kaHXB5Kq', nReg: 40, nEval: 0, nErr: 0, result: "Pendiente", percent: "0", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
        { name: `PET_TEST_VEHICULOS2020_BLOQ2`, id: id++, date: '30-05-2022', type: 'Transporte', nameFich: 'Operaciones4.xlsx', nameId: '5VzSE$T*GtEaWqKJe8bWy$Bq$cgvyW', nReg: 70, nEval: 0, nErr: 0, result: "Ejecutando", percent: "93", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
        { name: `PET_TEST_VEHICULOS2020_BLOQ3`, id: id++, date: '27-05-2022', type: 'Transporte', nameFich: 'Operaciones5.txt', nameId: 'phMY4cm:9JHMM5VBruJ2_4kaHXB5Kq', nReg: 95, nEval: 95, nErr: 0, result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
        { name: `PET_TEST_VEHICULOS2020_BLOQ4`, id: id++, date: '26-05-2022', type: 'Transporte', nameFich: 'Operaciones6.json', nameId: '5VzSE$T*GtEaWqKJe8bWy$Bq$cgvyW', nReg: 450, nEval: 0, nErr: 0, result: "Pendiente", percent: "0", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
        { name: `PET_TEST_ENERGIA2020_BLOQ1`, id: id++, date: '25-05-2022', type: 'Energía', nameFich: 'Operaciones7.txt', nameId: 'iw:L&*W+ruL-@L8*P_{]Ex(mZzeD/k', nReg: 500, nEval: 477, nErr: 23, result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
        { name: `PET_TEST_VIVIENDA2020_BLOQ3`, id: id++, date: '24-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones8.json', nameId: 'd-Wr7Ja,VK+dpqhkcX?LJcq}@#xux]', nReg: 237, nEval: 200, nErr: 37, result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
        { name: `PET_TEST_VIVIENDA2020_BLOQ4`, id: id++, date: '23-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones9.xlsx', nameId: 'dbLY+MNZyiEj!zrbh?m?%E(L3akg=d', nReg: 167, nEval: 0, nErr: 0, result: "Pendiente", percent: "0", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
    ];

    const dataAPIEvaluating = [
        { name: `PET_TEST_VIVIENDA2020_BLOQ1`, id: id++, date: '31-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones.txt', nameId: 'dbLY+MNZyiEj!zrbh?m?%E(L3akg=d', nReg: 300, nEval: 295, nErr: 5, result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
        { name: `PET_TEST_VEHICULOS2020_BLOQ1`, id: id++, date: '29-05-2022', type: 'Transporte', nameFich: 'Operaciones2.json', nameId: 'd-Wr7Ja,VK+dpqhkcX?LJcq}@#xux]', nReg: 320, nEval: 0, nErr: 0, result: "Ejecutando", percent: "50", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
        { name: `PET_TEST_VIVIENDA2020_BLOQ2`, id: id++, date: '28-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones3.json', nameId: 'phMY4cm:9JHMM5VBruJ2_4kaHXB5Kq', nReg: 40, nEval: 0, nErr: 0, result: "Ejecutando", percent: "75", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
        { name: `PET_TEST_VEHICULOS2020_BLOQ2`, id: id++, date: '30-05-2022', type: 'Transporte', nameFich: 'Operaciones4.xlsx', nameId: '5VzSE$T*GtEaWqKJe8bWy$Bq$cgvyW', nReg: 70, nEval: 0, nErr: 0, result: "Ejecutando", percent: "83", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
        { name: `PET_TEST_VEHICULOS2020_BLOQ3`, id: id++, date: '27-05-2022', type: 'Transporte', nameFich: 'Operaciones5.txt', nameId: 'phMY4cm:9JHMM5VBruJ2_4kaHXB5Kq', nReg: 95, nEval: 95, nErr: 0, result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
        { name: `PET_TEST_VEHICULOS2020_BLOQ4`, id: id++, date: '26-05-2022', type: 'Transporte', nameFich: 'Operaciones6.json', nameId: '5VzSE$T*GtEaWqKJe8bWy$Bq$cgvyW', nReg: 450, nEval: 0, nErr: 0, result: "Ejecutando", percent: "79", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
        { name: `PET_TEST_ENERGIA2020_BLOQ1`, id: id++, date: '25-05-2022', type: 'Energía', nameFich: 'Operaciones7.txt', nameId: 'iw:L&*W+ruL-@L8*P_{]Ex(mZzeD/k', nReg: 500, nEval: 477, nErr: 23, result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
        { name: `PET_TEST_VIVIENDA2020_BLOQ3`, id: id++, date: '24-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones8.json', nameId: 'd-Wr7Ja,VK+dpqhkcX?LJcq}@#xux]', nReg: 237, nEval: 200, nErr: 37, result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
        { name: `PET_TEST_VIVIENDA2020_BLOQ4`, id: id++, date: '23-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones9.xlsx', nameId: 'dbLY+MNZyiEj!zrbh?m?%E(L3akg=d', nReg: 167, nEval: 0, nErr: 0, result: "Ejecutando", percent: "0", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
    ];

    const dataStartSingleEv = [
        { name: `PET_TEST_VIVIENDA2020_BLOQ1`, id: id++, date: '31-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones.txt', nameId: 'dbLY+MNZyiEj!zrbh?m?%E(L3akg=d', nReg: 300, nEval: 295, nErr: 5, result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
        { name: `PET_TEST_VEHICULOS2020_BLOQ1`, id: id++, date: '29-05-2022', type: 'Transporte', nameFich: 'Operaciones2.json', nameId: 'd-Wr7Ja,VK+dpqhkcX?LJcq}@#xux]', nReg: 320, nEval: 0, nErr: 0, result: "Pendiente", percent: "0", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
        { name: `PET_TEST_VIVIENDA2020_BLOQ2`, id: id++, date: '28-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones3.json', nameId: 'phMY4cm:9JHMM5VBruJ2_4kaHXB5Kq', nReg: 40, nEval: 0, nErr: 0, result: "Pendiente", percent: "0", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
        { name: `PET_TEST_VEHICULOS2020_BLOQ2`, id: id++, date: '30-05-2022', type: 'Transporte', nameFich: 'Operaciones4.xlsx', nameId: '5VzSE$T*GtEaWqKJe8bWy$Bq$cgvyW', nReg: 70, nEval: 0, nErr: 0, result: "Ejecutando", percent: "0", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
        { name: `PET_TEST_VEHICULOS2020_BLOQ3`, id: id++, date: '27-05-2022', type: 'Transporte', nameFich: 'Operaciones5.txt', nameId: 'phMY4cm:9JHMM5VBruJ2_4kaHXB5Kq', nReg: 95, nEval: 95, nErr: 0, result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
        { name: `PET_TEST_VEHICULOS2020_BLOQ4`, id: id++, date: '26-05-2022', type: 'Transporte', nameFich: 'Operaciones6.json', nameId: '5VzSE$T*GtEaWqKJe8bWy$Bq$cgvyW', nReg: 450, nEval: 0, nErr: 0, result: "Pendiente", percent: "0", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
        { name: `PET_TEST_ENERGIA2020_BLOQ1`, id: id++, date: '25-05-2022', type: 'Energía', nameFich: 'Operaciones7.txt', nameId: 'iw:L&*W+ruL-@L8*P_{]Ex(mZzeD/k', nReg: 500, nEval: 477, nErr: 23, result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
        { name: `PET_TEST_VIVIENDA2020_BLOQ3`, id: id++, date: '24-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones8.json', nameId: 'd-Wr7Ja,VK+dpqhkcX?LJcq}@#xux]', nReg: 237, nEval: 200, nErr: 37, result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
        { name: `PET_TEST_VIVIENDA2020_BLOQ4`, id: id++, date: '23-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones9.xlsx', nameId: 'dbLY+MNZyiEj!zrbh?m?%E(L3akg=d', nReg: 167, nEval: 0, nErr: 0, result: "Pendiente", percent: "0", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
    ];

    const dataStartEv = [
        { name: `PET_TEST_VIVIENDA2020_BLOQ1`, id: id++, date: '31-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones.txt', nameId: 'dbLY+MNZyiEj!zrbh?m?%E(L3akg=d', nReg: 300, nEval: 295, nErr: 5, result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
        { name: `PET_TEST_VEHICULOS2020_BLOQ1`, id: id++, date: '29-05-2022', type: 'Transporte', nameFich: 'Operaciones2.json', nameId: 'd-Wr7Ja,VK+dpqhkcX?LJcq}@#xux]', nReg: 320, nEval: 0, nErr: 0, result: "Ejecutando", percent: "0", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
        { name: `PET_TEST_VIVIENDA2020_BLOQ2`, id: id++, date: '28-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones3.json', nameId: 'phMY4cm:9JHMM5VBruJ2_4kaHXB5Kq', nReg: 40, nEval: 0, nErr: 0, result: "Ejecutando", percent: "0", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
        { name: `PET_TEST_VEHICULOS2020_BLOQ2`, id: id++, date: '30-05-2022', type: 'Transporte', nameFich: 'Operaciones4.xlsx', nameId: '5VzSE$T*GtEaWqKJe8bWy$Bq$cgvyW', nReg: 70, nEval: 0, nErr: 0, result: "Ejecutando", percent: "0", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
        { name: `PET_TEST_VEHICULOS2020_BLOQ3`, id: id++, date: '27-05-2022', type: 'Transporte', nameFich: 'Operaciones5.txt', nameId: 'phMY4cm:9JHMM5VBruJ2_4kaHXB5Kq', nReg: 95, nEval: 95, nErr: 0, result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
        { name: `PET_TEST_VEHICULOS2020_BLOQ4`, id: id++, date: '26-05-2022', type: 'Transporte', nameFich: 'Operaciones6.json', nameId: '5VzSE$T*GtEaWqKJe8bWy$Bq$cgvyW', nReg: 450, nEval: 0, nErr: 0, result: "Ejecutando", percent: "0", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
        { name: `PET_TEST_ENERGIA2020_BLOQ1`, id: id++, date: '25-05-2022', type: 'Energía', nameFich: 'Operaciones7.txt', nameId: 'iw:L&*W+ruL-@L8*P_{]Ex(mZzeD/k', nReg: 500, nEval: 477, nErr: 23, result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
        { name: `PET_TEST_VIVIENDA2020_BLOQ3`, id: id++, date: '24-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones8.json', nameId: 'd-Wr7Ja,VK+dpqhkcX?LJcq}@#xux]', nReg: 237, nEval: 200, nErr: 37, result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
        { name: `PET_TEST_VIVIENDA2020_BLOQ4`, id: id++, date: '23-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones9.xlsx', nameId: 'dbLY+MNZyiEj!zrbh?m?%E(L3akg=d', nReg: 167, nEval: 0, nErr: 0, result: "Ejecutando", percent: "0", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
    ];

    // const dataStart = [
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ1`, id: id++, date: '31-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones.txt', nameId: 'dbLY+MNZyiEj!zrbh?m?%E(L3akg=d', nReg: 300, nEval: 295, nErr: 5, result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VEHICULOS2020_BLOQ1`, id: id++, date: '29-05-2022', type: 'Transporte', nameFich: 'Operaciones2.json', nameId: 'd-Wr7Ja,VK+dpqhkcX?LJcq}@#xux]', nReg: 320, nEval: 0, nErr: 0, result: "Pendiente", percent: "0", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ2`, id: id++, date: '28-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones3.json', nameId: 'phMY4cm:9JHMM5VBruJ2_4kaHXB5Kq', nReg: 40, nEval: 0, nErr: 0, result: "Pendiente", percent: "0", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
    //     { name: `PET_TEST_VEHICULOS2020_BLOQ2`, id: id++, date: '30-05-2022', type: 'Transporte', nameFich: 'Operaciones4.xlsx', nameId: '5VzSE$T*GtEaWqKJe8bWy$Bq$cgvyW', nReg: 70, nEval: 0, nErr: 0, result: "Pendiente", percent: "0", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
    //     { name: `PET_TEST_VEHICULOS2020_BLOQ3`, id: id++, date: '27-05-2022', type: 'Transporte', nameFich: 'Operaciones5.txt', nameId: 'phMY4cm:9JHMM5VBruJ2_4kaHXB5Kq', nReg: 95, nEval: 95, nErr: 0, result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VEHICULOS2020_BLOQ4`, id: id++, date: '26-05-2022', type: 'Transporte', nameFich: 'Operaciones6.json', nameId: '5VzSE$T*GtEaWqKJe8bWy$Bq$cgvyW', nReg: 450, nEval: 0, nErr: 0, result: "Pendiente", percent: "0", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
    //     { name: `PET_TEST_ENERGIA2020_BLOQ1`, id: id++, date: '25-05-2022', type: 'Energía', nameFich: 'Operaciones7.txt', nameId: 'iw:L&*W+ruL-@L8*P_{]Ex(mZzeD/k', nReg: 500, nEval: 477, nErr: 23, result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ3`, id: id++, date: '24-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones8.json', nameId: 'd-Wr7Ja,VK+dpqhkcX?LJcq}@#xux]', nReg: 237, nEval: 200, nErr: 37, result: "Completado", percent: "100", detail: "Evaluación Completa", status: 'Finalizada', color: 1 },
    //     { name: `PET_TEST_VIVIENDA2020_BLOQ4`, id: id++, date: '23-05-2022', type: 'Construcción de edificios y promoción inmobiliaria', nameFich: 'Operaciones9.xlsx', nameId: 'dbLY+MNZyiEj!zrbh?m?%E(L3akg=d', nReg: 167, nEval: 0, nErr: 0, result: "Pendiente", percent: "0", detail: "Evaluación Pendiente", status: 'Activa', color: 1 },
    // ];


    const tableAux = [
        { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234560 ' + tab + ' Sec. - 003', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
        { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234567 ' + tab + ' Sec. - 001', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
        { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234561 ' + tab + ' Sec. - 002', sector: sect, objective: 'Mitigación cambio climático', mError: "Formato incorrecto del Json", status: 'Error', color: 1 },
        { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234560 ' + tab + ' Sec. - 003', sector: sect, objective: 'Mitigación cambio climático', mError: "Formato incorrecto del Json", status: 'Error', color: 1 },
        { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234563 ' + tab + ' Sec. - 009', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
        { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234565 ' + tab + ' Sec. - 002', sector: sect, objective: 'Mitigación cambio climático', mError: "Formato incorrecto del Json", status: 'Error', color: 1 },
        { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234566 ' + tab + ' Sec. - 001', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
        { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234560 ' + tab + ' Sec. - 003', sector: sect, objective: 'Mitigación cambio climático', mError: "Formato incorrecto del Json", status: 'Error', color: 1 },
        { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234566 ' + tab + ' Sec. - 001', sector: sect, objective: 'Mitigación cambio climático', mError: "Formato incorrecto del Json", status: 'Error', color: 1 },
        { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234560 ' + tab + ' Sec. - 003', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
        { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234567 ' + tab + ' Sec. - 001', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
        { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234561 ' + tab + ' Sec. - 002', sector: sect, objective: 'Mitigación cambio climático', mError: "Formato incorrecto del Json", status: 'Error', color: 1 },
        { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234560 ' + tab + ' Sec. - 003', sector: sect, objective: 'Mitigación cambio climático', mError: "Formato incorrecto del Json", status: 'Error', color: 1 },
        { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234563 ' + tab + ' Sec. - 009', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
        { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234565 ' + tab + ' Sec. - 002', sector: sect, objective: 'Mitigación cambio climático', mError: "Formato incorrecto del Json", status: 'Error', color: 1 },
        { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234566 ' + tab + ' Sec. - 001', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
        { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234560 ' + tab + ' Sec. - 003', sector: sect, objective: 'Mitigación cambio climático', mError: "Formato incorrecto del Json", status: 'Error', color: 1 },
        { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234566 ' + tab + ' Sec. - 001', sector: sect, objective: 'Mitigación cambio climático', mError: "Formato incorrecto del Json", status: 'Error', color: 1 },
        { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234560 ' + tab + ' Sec. - 003', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
        { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234567 ' + tab + ' Sec. - 001', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
        { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234561 ' + tab + ' Sec. - 002', sector: sect, objective: 'Mitigación cambio climático', mError: "Formato incorrecto del Json", status: 'Error', color: 1 },
        { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234560 ' + tab + ' Sec. - 003', sector: sect, objective: 'Mitigación cambio climático', mError: "Formato incorrecto del Json", status: 'Error', color: 1 },
        { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234563 ' + tab + ' Sec. - 009', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
        { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234565 ' + tab + ' Sec. - 002', sector: sect, objective: 'Mitigación cambio climático', mError: "Formato incorrecto del Json", status: 'Error', color: 1 },
        { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234566 ' + tab + ' Sec. - 001', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
        { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234560 ' + tab + ' Sec. - 003', sector: sect, objective: 'Mitigación cambio climático', mError: "Formato incorrecto del Json", status: 'Error', color: 1 },
        { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234566 ' + tab + ' Sec. - 001', sector: sect, objective: 'Mitigación cambio climático', mError: "Formato incorrecto del Json", status: 'Error', color: 1 },
        { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234560 ' + tab + ' Sec. - 003', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
        { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234567 ' + tab + ' Sec. - 001', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
        { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234561 ' + tab + ' Sec. - 002', sector: sect, objective: 'Mitigación cambio climático', mError: "Formato incorrecto del Json", status: 'Error', color: 1 },
        { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234560 ' + tab + ' Sec. - 003', sector: sect, objective: 'Mitigación cambio climático', mError: "Formato incorrecto del Json", status: 'Error', color: 1 },
        { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234563 ' + tab + ' Sec. - 009', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
        { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234565 ' + tab + ' Sec. - 002', sector: sect, objective: 'Mitigación cambio climático', mError: "Formato incorrecto del Json", status: 'Error', color: 1 },
        { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234566 ' + tab + ' Sec. - 001', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
        { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234560 ' + tab + ' Sec. - 003', sector: sect, objective: 'Mitigación cambio climático', mError: "Formato incorrecto del Json", status: 'Error', color: 1 },
        { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234566 ' + tab + ' Sec. - 001', sector: sect, objective: 'Mitigación cambio climático', mError: "Formato incorrecto del Json", status: 'Error', color: 1 },

    ];

    // const tableSucces = [
    //     { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234560 ' + tab + ' Sec. - 003', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
    //     { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234567 ' + tab + ' Sec. - 001', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
    //     { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234561 ' + tab + ' Sec. - 002', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
    //     { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234560 ' + tab + ' Sec. - 003', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
    //     { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234563 ' + tab + ' Sec. - 009', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
    //     { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234565 ' + tab + ' Sec. - 002', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
    //     { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234566 ' + tab + ' Sec. - 001', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
    //     { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234560 ' + tab + ' Sec. - 003', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
    //     { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234566 ' + tab + ' Sec. - 001', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
    //     { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234560 ' + tab + ' Sec. - 003', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
    //     { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234567 ' + tab + ' Sec. - 001', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
    //     { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234561 ' + tab + ' Sec. - 002', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
    //     { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234560 ' + tab + ' Sec. - 003', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
    //     { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234563 ' + tab + ' Sec. - 009', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
    //     { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234565 ' + tab + ' Sec. - 002', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
    //     { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234566 ' + tab + ' Sec. - 001', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
    //     { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234560 ' + tab + ' Sec. - 003', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
    //     { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234566 ' + tab + ' Sec. - 001', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
    //     { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234560 ' + tab + ' Sec. - 003', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
    //     { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234567 ' + tab + ' Sec. - 001', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
    //     { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234561 ' + tab + ' Sec. - 002', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
    //     { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234560 ' + tab + ' Sec. - 003', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
    //     { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234563 ' + tab + ' Sec. - 009', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
    //     { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234565 ' + tab + ' Sec. - 002', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
    //     { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234566 ' + tab + ' Sec. - 001', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
    //     { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234560 ' + tab + ' Sec. - 003', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
    //     { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234566 ' + tab + ' Sec. - 001', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
    //     { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234560 ' + tab + ' Sec. - 003', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
    //     { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234567 ' + tab + ' Sec. - 001', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
    //     { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234561 ' + tab + ' Sec. - 002', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
    //     { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234560 ' + tab + ' Sec. - 003', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
    //     { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234563 ' + tab + ' Sec. - 009', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
    //     { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234565 ' + tab + ' Sec. - 002', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
    //     { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234566 ' + tab + ' Sec. - 001', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
    //     { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234560 ' + tab + ' Sec. - 003', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },
    //     { id: id++, date: '31-05-2022', name: 'ABA-00023', desc: 'Operación-1234566 ' + tab + ' Sec. - 001', sector: sect, objective: 'Mitigación cambio climático', mError: "Correcto", status: 'Finalizada', color: 1 },

    // ];

    //const [dataStart, setDataStart] = useState([]);
    const [products, setproducts] = useState([])
    const [productsAux, setproductsAux] = useState([])
    const [scroll, setScroll] = useState(false)
    const [tableSucces, settableSucces] = useState([])
    var queries = []
    var aux = []

    const handleRefreshBlocks = () => {
        dispatch({ type: "SET_HIDDEN", payload: false });
        taxoApi.getTaxoBloques().then((response, navigate) => {
            queries = []
            response.data.forEach(item => {
                queries.push(item)
            })
            setproducts(queries)
            setproductsAux(queries)
            queries = []
            dispatch({ type: "SET_HIDDEN", payload: true });
        })

    }

    useEffect(() => {
        dispatch({ type: "SET_HIDDEN", payload: false });
        taxoApi.getTaxoBloques().then((response, navigate) => {
            queries = []
            response.data.forEach(item => {
                // aux = {name : item.blockName, id: item.blockId, date: item.uploadDate, type: item.sectorName, nameFich: item.fileName, nameId: "0", nReg: item.numRegisters, nEval: item.numOk, nErr: item.numKO, result: item.stateDescription, percent: "0", detail: item.stateDescription, status: item.stateDescription, color: 1}
                queries.push(item)
                // queries.push(aux)
            })
            setproducts(queries)
            setproductsAux(queries)

            queries = []
            dispatch({ type: "SET_HIDDEN", payload: true });
        })
    }, [])

    useEffect(() => {
        if (scroll) {
            var tabContainer = document.querySelector('.aux-scroll')
            var top = tabContainer?.querySelector('.scroll')?.offsetTop

            var prueba = document.getElementsByTagName('html')[0]
            prueba.scrollTop = top
            setScroll(false)
        }
    }, [show])

    useEffect(() => {
        if (newLote) {
            setproducts([...products, ...dataAPI])
            setproductsAux([...products, ...dataAPI])
        }
        setNewLote(false)
    }, [newLote])

    useEffect(() => {
        if (singleSelectionsBlock && singleSelectionsBlock.length > 0 && singleSelectionsBlock[0].name != '') {
            var productsTemp = []
            productsTemp = products.filter((n) => {
                return n.name === singleSelectionsBlock[0].name
            });
            setproductsAux(productsTemp)
        } else {
            setproductsAux(products)
        }

    }, [singleSelectionsBlock])


    const [time, setTime] = useState(0)

    function doEval(newData) {
        setproducts(newData)
        setproductsAux(newData)
    }

    const [evv, setEvv] = useState(1)
    const handleEvv = (v) => {
        if (ff === true || evv2 === true) {
            setEvv(v)
        }
    }
    const [evv2, setEvv2] = useState(false)

    useEffect(() => {
        if (evv2 === false && ff === true) {
            if (evv === 2) {
                doEval(dataAPIEvaluating)
            } else if (evv === 3) {
                doEval(dataAPIEvaluated)
                setff(false)
                setEvv(1)
            }
        }
        if (evv2 === true) {
            if (evv === 2) {
                doEval(dataAPISingleEvaluating)
            } else if (evv === 3) {
                doEval(dataAPISingleEvaluated)
                setEvv2(false)
                setEvv(1)
            }
        }
    }, [evv])

    const handleSingleEv = (v) => {
        doEval(dataStartSingleEv)
        setEvv2(true)
        // setTimeout(doEval,5000,dataAPISingleEvaluating)
        // setTimeout(doEval,10000,dataAPISingleEvaluated)
    }

    const [ff, setff] = useState(false)

    const handleEv = (v) => {
        // var newData = products.map((i) => {
        //     return { ...i, result: 'Completado', detail: "Evaluación Completa", status: 'Finalizada' }
        // })

        // doEval(dataStartEv)
        // setff(true)
        // setTimeout(doEval,5000,dataAPIEvaluating)
        // setTimeout(doEval,10000,dataAPIEvaluated)

        // setSingleSelectionsBlock([])
        // setBlockAux(blockName)
        // setBlockName("")
        // setShow(false)
        // setRe(true)
    }

    // const [blockId, handleBlockId] = useState()

    const [vshow, setvshow] = useState()
    const [auxshow, setAuxshow] = useState()
    const [block, setBlock] = useState()
    const [blockAux, setBlockAux] = useState('')


    const handleShow = (v, aux, blockId) => {
        setBlockAux(v)
        setShow(true)
        setSucces(aux)
        setScroll(true)
        dispatch({ type: "SET_HIDDEN", payload: false });
        taxoApi.getTaxoConsultasBloque(blockId).then((response, navigate) => {
            queries = []
            response.data.forEach(item => {
                queries.push(item)
            })
            settableSucces(queries)
            queries = []
            dispatch({ type: "SET_HIDDEN", payload: true });
        })
        setvshow(v)
        setAuxshow(aux)
        setBlock(blockId)
    }

    const handleShowModal = (v) => {
        setShowWar(v)
    }

    const handleSect = (v) => {
        setSect(v)
    }

    const handleCheckDetail = (blockId) => {
        if (block === blockId) {
            setShow(false)
        }
    }

    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }
    const [page, setPage] = useState(-1)
    const [dat, setDat] = useState([])
    const [stateEvaluation, setStateEvaluation] = useState(1)
    const [clickState, setClick] = useState(false)
    const [state, dispatch] = useContext(Context);
    const [fich, setFich] = useState()
    const handle = (v) => {
        setFich(v)
    }


    const navigate = useNavigate();
    let params = useParams();

    const handleImportCont = () => {
        //navigate('/newaction');
        setShowWar(true);
        //A FUTURO: recojo fichero que me ha dicho
    }

    const handleUpdateState = (stateEvalutaion) => {
        setStateEvaluation(stateEvalutaion)
        dat[0].state = stateEvalutaion
        setDat(dat)
    }

    const handleClick = (redir, page) => {
        setClick(redir);
        setPage(page)
    }

    const handleRe = (v) => setRe(v)
    const handlePend = (v) => setNewLote(v)
    const handleBlockName = (v) => setBlockName(v)
    const handleLotes = (v) => setLotes(v)
    const handleSelectionsBlock = (v) => setSingleSelectionsBlock(v)

    const handleRefresh = () => {
        handleShow(vshow, auxshow, block)
    }

    return (
        <React.Fragment>
            <div className='section-block first-block eva'>
                <div className='principal-title'>
                    <span className='header-title d-inline'>Evaluaciones en bloque</span>
                    <div className='d-inline eva' >
                        <div className='section d-none d-md-inline'>
                            <ButtonNewAction act={params.actividad} handleClick={handleClick} isBatchEvaluation={true} handleImportCont={handleImportCont} state={stateEvaluation} onHandleStateEvaluation={handleUpdateState} handleRefreshBlocks={handleRefreshBlocks} />
                        </div>
                    </div>
                </div>
                <ModalWarning block={fich} input header={<span className='section-title-modal'>Nueva Evaluación en bloque</span>} message='Se ha cargado el fichero correctamente' show={showWarning} handle={handleShowModal}></ModalWarning>
            </div>
            <div className='section-block'>
                <div className='aux-scroll'>
                    {products && products.length > 0 && <TableUpload data={productsAux} blockName={blockName} handleEv={handleEv} handleSelectionsBlock={handleSelectionsBlock} handleSingleEv={handleSingleEv} handleShow={handleShow} handleSect={handleSect} handleHideDetailActive={handleCheckDetail} handleEvv={handleEvv} />}
                    {/* <TableUpload data={productsAux} blockName={blockName} handleEv={handleEv} /> */}
                    <div className='scroll'>
                        {show &&
                            <div className='mb-3'>
                                <TableBatch data={tableSucces} sect={sect} blockName={blockAux} handleRefresh={handleRefresh} />
                            </div>}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}