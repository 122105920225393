import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom'
import { Tab, Tabs } from 'react-bootstrap';
import taxoApi from '../../api/taxoApi';
import Table from '../../commons/table/table'
import ButtonsHome from '../../commons/buttonsHome/buttonsHome'
import FormNewAction from "../../pages/formNewAction/formNewAction";
import PopOver from '../../commons/popOver/popOver'
import { MdOutlineRefresh, MdFilterListAlt } from "react-icons/md"
import SearchFilter from '../../commons/searchFilter/searchFilter';
import { Context } from "../../commons/utils/store";
import '../../App-media.css';
import { useNavigate } from 'react-router-dom';



export default function Home(props) {

    let params = useParams();
    const navigate = useNavigate();
    
    const tab = '\u00A0';
    var id = 1

    const [filter, setFilter] = useState(false)

    const [state, dispatch] = useContext(Context);
    const [evaluations, setEvaluations] = useState();
    const [informsData, setInforms] = useState();
    const [filteredData, setFilteredData] = useState([]);
    const [filteredDataReport, setFilteredDataReport] = useState([]);

    var queries = []
    var informs = []
    var aux = []
    var auxInform = []
    var idAux = []
    
    useEffect(() => {
        handleObtainTable();
    }, [])

    const handleTableSearch = (filtered) => {
        setFilteredData(filtered)
    }

    const handleObtainTable = () => {
        dispatch({ type: "SET_HIDDEN", payload: false });
        setFilter(false)
        taxoApi.getSaveQueries().then((response, navigate) => {
            queries = []
            idAux = 0
            response.data.forEach(item => {
                var idOperation = (item.idOperation ? item.idOperation : 'ABA-00016')
                var idDesc = (item.idOperation ? '' : '') //DESC 
                var itemStatus = item.status
                aux = { id: idAux, date: item.date, idOperation: idOperation, desc: idDesc + ' ' + tab + ' Sec. - ', secIdOperation: item.secIdOperation, evaluationId: item.evaluationId, sectorId: item.sectorId, sector: item.sectorName, activityId: item.activityId, activty: item.activityName, objectiveId: item.objectiveId, objective: item.nameObjective, status: itemStatus, color: 1, numDocuments: item.numDocuments, scoring: item.scoring, filePath: item.filePath, alignmentStatus: item.alignmentStatus }
                queries.push(aux)
                if (item.status && item.status === 2) {
                    var alignmentStatus = item.alignmentStatus
                    auxInform = { id: idAux++, date: item.date, idOperation: idOperation, desc: idDesc + ' ' + tab + ' Sec. - ',secIdOperation: item.secIdOperation, evaluationId: item.evaluationId, sectorId: item.sectorId, sector: item.sectorName, activityId: item.activityId, activty: item.activityName, objectiveId: item.objectiveId, objective: item.nameObjective, status: alignmentStatus, color: 1, numDocuments: item.numDocuments, scoring: item.scoring, fileName: item.filename, filePath: item.filePath}
                    informs.push(auxInform)
                }
            })            
            setEvaluations(queries)
            setFilteredData(queries)
            queries = []
            setInforms(informs)
            setFilteredDataReport(informs)
            informs = []
            dispatch({ type: "SET_HIDDEN", payload: true });
        })
    }

    return (<React.Fragment>
        <div className='section-block first-block'>
            <div className='principal-title'>
            {(params.param !== 'newaction' ?
                <span className='header-title d-inline'>Evaluaciones individuales</span>
                :  <span className='header-title d-inline pb-1'>Evaluación individual</span>
                )}
                { params.param !== 'newaction' ? <ButtonsHome /> : <></>}
            </div>
        </div>

        {params.param === 'newaction' ?
            <>
                <FormNewAction /> 
            </> :
            <div className='section-block position-relative tabs-home'>
                <Tabs style={{ marginLeft: '-13px' }} defaultActiveKey="evalList" transition={true} id="noanim-tab-example">
                    <Tab className="evalList" eventKey="evalList" title={<div className='decoration list-eval'>Resumen</div>}>
                        <div className='actions actions-home'>
                            <PopOver title={<div onClick={() => (setFilter(old => !old))} className='circle-icon me-2'><MdFilterListAlt className='color-icon eye' /></div>} message={"Filtrar"} />
                            <PopOver title={<div onClick={() => handleObtainTable()} className='circle-icon me-2'><MdOutlineRefresh className='color-icon eye' /></div>} message={"Actualizar"} />                            
                        </div>
                        <div style={{ marginLeft: '-13px' }} className="section-content">
                            {filter && <SearchFilter data={evaluations} pDataType="0" handleTable={handleTableSearch} />}
                            <Table data={filteredData} newAction={false} size={10} />
                        </div>
                    </Tab>                    
                </Tabs>
            </div>
        }

    </React.Fragment>)
}
