import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import Table from '../../commons/table/tableSearch'
import TableReport from '../../commons/table/tableReport'
import ButtonNewAction from '../../commons/buttonNewAction/buttonNewAction'
import FormNewAction from "../../pages/formNewAction/formNewAction";
import SearchFilter from '../../commons/searchFilter/searchFilterAll';
import ButtonsHome from '../../commons/buttonsHome/buttonsHome'
import PopOver from '../../commons/popOver/popOver'
import { MdOutlineRefresh, MdFilterListAlt } from "react-icons/md"
import { RiFileExcel2Fill } from "react-icons/ri"
import excel from '../../api/exportTable.xlsx'

export default function Search(props) {
    let params = useParams();

    const tab = '\u00A0';
    var id = 1
    const products = [];

    const [filtrar, setFiltrar] = useState(false);
    const [showTable, setShowTable] = useState(false);
    const [table, setTable] = useState(products)

    const handleShow = (v) => {
        setShowTable(v)
    }

    const search = <svg className="col-md lens-image" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 485.104 485.104" >
        <g>
            <path d="M110.028,115.171c-4.76-4.767-12.483-4.752-17.227,0c-32.314,32.33-32.314,84.898-0.016,117.197
        c2.38,2.379,5.487,3.569,8.614,3.569c3.123,0,6.234-1.19,8.613-3.569c4.76-4.76,4.76-12.469,0-17.228
        c-22.795-22.803-22.795-59.923,0.016-82.742C114.788,127.64,114.788,119.923,110.028,115.171z"/>
            <path d="M471.481,405.861L324.842,259.23c37.405-66.25,28.109-151.948-28.217-208.317C263.787,18.075,220.133,0,173.718,0
        C127.287,0,83.633,18.075,50.81,50.913c-67.717,67.74-67.701,177.979,0.02,245.738c32.85,32.823,76.488,50.897,122.919,50.897
        c30.489,0,59.708-7.939,85.518-22.595L405.824,471.51c18.113,18.121,47.493,18.129,65.641,0
        c8.706-8.71,13.593-20.512,13.608-32.823C485.073,426.37,480.171,414.567,471.481,405.861z M85.28,262.191
        c-48.729-48.756-48.729-128.079-0.016-176.828c23.62-23.627,55.029-36.634,88.453-36.634c33.407,0,64.816,13.007,88.451,36.627
        c48.715,48.756,48.699,128.094-0.015,176.85c-23.62,23.612-55.014,36.612-88.406,36.612
        C140.341,298.818,108.919,285.811,85.28,262.191z"/>
        </g>
    </svg>

    const handleTable = (newTable) => {
        setTable(newTable)
    }

    return (
        <React.Fragment>
            <div className='section-block first-block'>
                <div className='principal-title'>
                    {/* <span className='header-title d-inline'>Análisis de alineación con la taxonomía de actividades económicas</span>    */}
                    <span className='header-title d-inline'>Búsqueda</span>
                    {/* <ButtonNewAction /> */}
                </div>
            </div>
            <div className='section-block'>
                <div className="section-content">
                    <SearchFilter table={products} handleTable={handleTable} handleShow={handleShow} />
                </div>
            </div>
            {showTable &&
                <div className='section-block'>

                    <div className="section-content">
                        <Table data={table} size={7} />
                    </div>
                </div>
            }
        </React.Fragment>)
}
