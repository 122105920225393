import React from 'react';
import './footer.css'

export default function Footer() {
  //var imageName = require('../../favicon.ico')
  var imageName = require('../../logoAlamo.png')

  return (
    <footer className='bottom-0 bg-primary color-white'>
      <div className='d-flex footer container-fluid'>
        <img
          className='align-self-center justify-content-lg-start'
          alt="logo"
          src={imageName}
          height="20px"
        />
        <div className='col d-flex justify-content-end align-self-center'>
          <small className="contacto">&copy; ÁlamoConsulting, 2024</small>
        </div>
      </div>
    </footer>
  );
}