import React, { useEffect, useState, useContext } from 'react';
import ComponentSteps from './componentSteps/componentSteps'
import { useParams } from "react-router-dom";
import { Tab,Tabs, Button } from 'react-bootstrap';
import taxoApi from '../../api/taxoApi';
import './evaluation.css'
import { Context } from "../../commons/utils/store";
import ButtonNewAction from '../../commons/buttonNewAction/buttonNewAction';
import ModalWarning from '../../commons/modals/modalWarning';
import ObjectiveImage from '../../commons/objectiveImage/objectiveImage'
import PopOver from '../../commons/popOver/popOver'
import { BsCheckCircleFill, BsFileEarmarkCheckFill } from "react-icons/bs"
import { MdCancel } from "react-icons/md"
import { BiPlus } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';
import filterFactory from 'react-bootstrap-table2-filter';

export default function Summary() {
  let params = useParams();
  

  const navigate = useNavigate();
  const [stateEvaluation, setStateEvaluation] = useState(0)
  const [scoringEvaluation, setScoringEvaluation] = useState(0)
  const [esBloque,setEsBloque] = useState(0);
  const [info, setInfo] = useState([])
  const [summary, setSummary] = useState([])
  const [state, dispatch] = useContext(Context);
  const [error, setError] = useState(false)
  const [prevPage,setPrevPage] = useState(1)


  const [dnshResult,setDnshResult] = useState("")
  const [dnshDetail,setDnshDetail] = useState("")
  const [dnshTotalDetail,setDnshTotalDetail] = useState("")

  const handleError = (click) => {
    setError(!click)
    navigate("/home")
  }


  const checkBloque = (evaluationId) => {
    taxoApi.getTaxoCheckBloque(evaluationId,navigate).then((response) => {
      if (response.response === 1) {
          setEsBloque(1)
      }
    })
  }

  useEffect(() => {
    dispatch({ type: "SET_HIDDEN", payload: false });
    let tipo = params.tipo
    let ruta = ""
    switch(tipo){
      case "1": 
        ruta = "/home";
        break
      case "2": 
        ruta = "/search";
        break
      case "3": 
        ruta = "/batchevaluation";
        break
      default: 
        ruta = "/home";
        break
    }

    setPrevPage(ruta)

    if (params.consulta !== undefined) { // esta finalizada
      checkBloque(params.consulta)
      taxoApi.getTaxoSummaryEvaluation(params.consulta,navigate).then((response, navigate) => {
        if(response.status == "OK"){
          setStateEvaluation(response.data[0].stateEvaluation)
          setScoringEvaluation(response.data[0].scoring)
          setInfo(response.data[0])
          let summary = response.summary
          setSummary(summary)
          setDnshResult(summary.some(e => e.evaluationType == "DNSH" && e.result == "No cumple")?"No Cumple":"Cumple")
          let detalles = summary.filter(e => e.evaluationType == "DNSH" && e.result == "No cumple")
          let detallesComp = summary.filter(e => e.evaluationType == "DNSH")
          setDnshDetail(detalles)
          setDnshTotalDetail(detallesComp)
        }else{
          setError(true)
        }
        
        dispatch({ type: "SET_HIDDEN", payload: true });
      })
    }
    else {
      // no esta finalizada
    }
  }, [])

  return (
    <React.Fragment>
    {(info && summary && summary[0]) &&
    <>
        <div className='section-block first-block eva'>
          <div className='principal-title'>
            <span className='header-title d-inline'>Resumen de Evaluación</span>
            {/* 
            <div className='state-end'>
              {(stateEvaluation === '0' || stateEvaluation === 0) ?
                <Button className='button-state width-8rem mid-button align-items-center'><span className='badge text-bg-initial me-2'>1</span><span className='me-2 d-none d-sm-inline'>Inicial</span></Button> :
                (stateEvaluation === '1' || stateEvaluation === 1) ?
                  <Button className='button-state width-7rem mid-button align-items-center'><span className='badge text-bg-pending me-2'>1</span><span className='me-2 d-none d-sm-inline'>Activa</span></Button> :
                  (stateEvaluation === '2' || stateEvaluation === 2) ?
                    <Button className='button-state width-8rem mid-button align-items-center'><span className='badge text-bg-success me-2'>1</span><span className='me-2 d-none d-sm-inline'>Finalizada</span></Button> :
                    <Button className='button-state width-8rem mid-button align-items-center'><span className='badge text-bg-ko me-2'>1</span><span className='me-2'>Error</span></Button>
              }                     
              {esBloque === 0 && <ButtonNewAction act={params.actividad} nChanges={0} isSummaryPage={true} state={2} evaluationId={params.consulta} handleErrorDownload={handleErrorDownload}/>}    
            </div>
            */}
          </div>
        </div>        

        <div id='evaluation' className='section-block'>
          <div className="section-content tabs-home">
            <Tabs className='' defaultActiveKey="Resumen" transition={true} id="noanim-tab-example">
              <Tab eventKey={"Resumen"} className="colorBlack" title={<div className='decoration list-eval'>Datos de la Operación</div>}>
                <div className='row'>
                    <div className='col-sm-3 col-md-2 col-primary py-1'>
                        Operación
                    </div>
                    <div className='col-sm-9 col-md-10 col-secondary py-1'>
                        {info.date} {info.idOperation}
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-3 col-md-2 col-primary py-1'>
                        Objetivo
                    </div>
                    <div className='col-sm-9 col-md-10 col-secondary py-1'><span>
                        <ObjectiveImage objectiveId={info.idObjective} block={'block-size'} /> {info.nameObjective}
                    </span>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-3 col-md-2 col-primary py-1'>
                        Sector
                    </div>
                    <div className='col-sm-9 col-md-10 col-secondary py-1'>
                        {info.idSector}-{info.nameSector}
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-3 col-md-2 col-primary-end py-1'>
                        Actividad
                    </div>
                    <div className='col-sm-9 col-md-10 col-secondary-end py-1'>
                        <div>
                            {info.activityCode}. {info.nameActivity}
                            <div className='float-end color-secondary cursor-pointer'>
                                <PopOver title={'Descripción'} message={info.activityDesc} position={'auto'} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='special-block result-block aligmentResults-block'>
                  <div className='bg-white special-content'>

                    <div className='row' style={{marginBottom:"1rem"}}>
                      <div className='col-sm-6 col-md-6 py-1 '>
                        <div className='summaryAligment-titleContainer'>
                          <span className='summaryAligment-title'> Alineamiento Actividad </span>
                        </div>
                      </div>
                      <div className='col-sm-6 col-md-6 py-1 summaryAligment-detail state-end pr'>                        
                        {stateEvaluation == 1 ?
                        <Button className='button-state align-items-center width-10rem summaryAligment-badge'>
                          <BsCheckCircleFill className='color-succes pb-0 me-2 text-bg' />
                          <span className='d-none d-sm-inline'>Alineada</span>
                        </Button> :
                        <Button className='button-state align-items-center width-10rem summaryAligment-badge'>
                          <MdCancel className='color-fail pb-0 me-2 text-bg' />
                          <span className='d-none d-sm-inline'>No alineada</span>
                        </Button> 
                        } 
                        {/* <div className='col-sm-3 col-md-2 py-1 summaryAligment-detail'>
                          {stateEvaluation == 1 ? 
                          <>
                            <BsCheckCircleFill style={{marginBottom:"0rem !importan"}} className='color-succes pb-0 me-2' />
                            <span>Alineada</span>
                          </>
                          :
                          <>
                            <MdCancel style={{marginBottom:"0rem !importan"}} className='color-fail pb-0 me-2' />
                            <span>No alineada</span>
                          </>
                          } 
                        </div>  */}
                        <span className='summaryAligment-subtitle'> Scoring </span>
                        <span> {scoringEvaluation + "%"} </span>                      
                      </div>
                    </div>
                    <div className='summary-block'>
                    {
                    (summary && summary[0] && summary.length !== 0) &&
                    <>
                      <div className='row'>
                        <div className='col-sm-8 col-md-6 col-primary py-2'>
                            <span className='summaryResult-title'>Contribución Sustancial</span>
                        </div>
                        <div className='col-sm-4 col-md-6 col-secondary py-2'>
                            <span>{summary.filter(e => e.evaluationType == "CS")[0]["result"]}</span>
                        </div>
                      </div>
                      {
                        (summary.filter(e => e.evaluationType == "CS")[0]["detail"] != "") &&
                        <div className='row'>
                          <div className='col-secondary py-2'>
                            <span className='summaryResult-detail'>{summary.filter(e => e.evaluationType == "CS")[0]["detail"]}</span>
                          </div>
                        </div>
                      }
                      <div className='row'>
                        <div className='col-sm-8 col-md-6 col-primary py-2'>
                            <span className='summaryResult-title'>No Causar Daño no Significativo</span>
                        </div>
                        <div className='col-sm-4 col-md-6 col-secondary py-2'>
                            <span>{dnshResult}</span>
                        </div>
                      </div>
                      {
                        dnshTotalDetail.map(e => (
                          <div className='row'>
                            <div className='col-sm-8 col-md-6 col-secondary py-2'>
                                <span className='summaryResult-subtitle'>{e.objetiveName}</span>
                            </div>
                            <div className='col-sm-4 col-md-6 col-secondary py-2'>
                                <span>{e.result}</span>
                            </div>
                          </div>
                        ))
                      }
                      {
                        (dnshResult === "No Cumple") &&
                        <div className='row'>
                          <div className='col-secondary py-2'>
                          {dnshDetail[0] && dnshDetail.map(e => (<span className='summaryResult-Dnshdetail'>{"- " + e.detail}</span>))}
                          </div>
                        </div>
                      }
                      <div className='row'>
                        <div className='col-sm-8 col-md-6 col-primary-end py-2'>
                            <span className='summaryResult-title'>Mínimas Salvaguardas</span>
                        </div>
                        <div className='col-sm-4 col-md-6 col-secondary-end py-2'>
                            <span>{summary.filter(e => e.evaluationType == "SALV")[0]["result"]}</span>
                        </div>
                      </div>
                      {
                        (summary.filter(e => e.evaluationType == "SALV")[0]["detail"] != "") &&
                        <div className='row'>
                          <div className='col-secondary-end py-2'>
                            <span className='summaryResult-detail'>{summary.filter(e => e.evaluationType == "SALV")[0]["detail"]}</span>
                          </div>
                        </div>
                      }     
                    </>
                    }
                  </div>
                  </div>    
                </div>

              </Tab>
            </Tabs>
          </div>
        </div>

        <div className='section-block d-flex justify-content-end special-content' >
            <Button className='btn btn-secondary color-white ms-4 mid-button' onClick={() => navigate(prevPage)}>Volver</Button>
        </div>

        </>
      }
      <ModalWarning header={<span className='section-title-modal'>Error</span>} message={"La operacion debe estar finalizada"} show={error} handle={handleError} onlyButtonContinue> </ModalWarning>
    </React.Fragment>);
}
