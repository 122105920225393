import React from 'react';
import { Modal } from 'react-bootstrap';
import { HiMail } from "react-icons/hi"
import './modalWarning.css';
import './modalMessage.css';

export default function ModalMessage({show, message, label, header, handle}) {
    const handleClose = () => {
        handle()
        //setErrorGetComments(false)
    }


    return (
        <Modal show={show} onHide={handleClose} centered size="sm-down">

            <Modal.Header className='section-title-modal' closeButton>
                <div className='col-5'>{header}</div>
            </Modal.Header>

            <Modal.Body>
                {message && message.length > 0 ?
                    <><p className='mb-2'> {label} </p> <HiMail className='color-black home-icon'></HiMail><b> {message} </b></>           
                    : <></>
                }
            </Modal.Body>
        </Modal>
    )
}