import React, { useState } from 'react';


export default function ObjectiveImage(props) {
    return (
        <>
            {
                props.objectiveId !== undefined ?
                    props.objectiveId === "ADPT" ?
                        <svg className={props.block ? props.block : 'objective-image'} version="1.1" id="Capa_3" x="0px" y="0px" viewBox="0 0 512 512">
                            <g>
                                <g>
                                    <path d="M420.487,352.01H307.539v-35.567l-32.487,43.027l-32.507,43.02l32.507,43.027l32.487,43.035v-24.616l60.198-2.275
                c53.211-3.215,65.931-25.188,65.931-25.188l59.783-111.085C471.414,356.118,420.487,352.01,420.487,352.01z"></path>
                                    <path d="M451.526,192.181l-96.347,55.964l48.167,83.509c49.089,4.577,64.209-10.561,64.209-10.561
                c39.335-22.249,17.459-71.586,17.459-71.586L451.526,192.181z"></path>
                                    <path d="M66.956,425.68c-14.868-34.753,15.083-76.187,15.083-76.187l58.553-96.576l30.396,18.444l-19.966-50.102l-19.918-50.093
                l-53.647,5.485l-53.645,5.498l21.028,12.737l-29.22,52.668c-24.84,47.157-12.608,69.435-12.608,69.435L66.956,425.68z"></path>
                                    <path d="M83.707,408.618c-1.347,45.192,52.169,52.021,52.169,52.021l66.432,1.099l2.072-111.424l-96.383-2.076
                C78.622,387.831,83.707,408.618,83.707,408.618z"></path>
                                    <path d="M324.365,181.026l53.285,8.152l22.395-49.049l22.43-49.027l-21.639,11.685l-30.604-51.862
                C342.119,5.628,316.715,4.899,316.715,4.899L190.573,5.062c37.53,4.774,58.099,51.547,58.099,51.547l53.657,99.358l-31.273,16.901
                L324.365,181.026z"></path>
                                    <path d="M236.691,75.568c-19.295-45.371-39.812-51.511-39.812-51.511c-38.274-24.011-71.256,18.677-71.256,18.677L91.066,99.459
                l95.033,58.174L236.691,75.568z"></path>
                                </g>
                            </g>
                        </svg> :
                        props.objectiveId === "AGUA" ?
                            <svg className={props.block ? props.block : 'objective-image'} id="Capa_4" enableBackground="new 0 0 510 510" viewBox="0 0 510 510"><g>
                                <path d="m459.57 374.835c-14.085-19.092-39.57-46.642-39.57-46.642s-25.485 27.55-39.57 46.643c-20.476 27.753-30.43 49.069-30.43 65.164 0 38.598 31.402 70 70 70s70-31.402 70-70c0-16.095-9.954-37.411-30.43-65.165z"></path><g><path d="m334.525 149.525-5.062.053c-6.52.068-12.651-2.416-17.282-6.997-4.631-4.582-7.181-10.693-7.181-17.207v-50.374h-45v-30h75v-30h-75v-15h-30v15h-75v30h75v30h-45v46.664c0 15.625-12.711 28.336-28.336 28.336h-37.944c.828 4.411 1.28 8.952 1.28 13.599v77.802c0 4.647-.453 9.188-1.28 13.599h216.28c16.542 0 30 13.458 30 30v15h105v-15c0-74.701-60.774-135.475-135.475-135.475z"></path>
                                    <path d="m46.401 120h-26.401v165h26.401c24.04 0 43.599-19.559 43.599-43.599v-77.802c0-24.04-19.559-43.599-43.599-43.599z"></path></g></g>
                            </svg> :
                            props.objectiveId === "BIOD" ?
                                <svg className={props.block ? props.block : 'objective-image'} version="1.1" id="Capa_6" x="0px" y="0px" viewBox="0 0 978.673 978.673">
                                    <g>
                                        <path d="M64.126,214.123c35.6,82.801,95.7,164.7,179.8,200.001c9.6,4,20.5,7,31.7,9.5c22.4-33.4,48.1-60.501,78.2-82.7
                                    c25.899-19,53-33.9,80.899-45.601c-8-5.6-16.399-10.7-25.2-15.3c-35-18.3-72.899-34.4-109.8-51.7c34.7,9.4,74.5,12.9,107.7,25
                                    c20.7,7.601,39.8,18.101,57.6,30.601c53.101-18.101,108.601-26.801,164-33.4c-44.899-74.6-140.7-134.5-212.9-161.6
                                    c-75.4-28.301-160.8-31.801-244.7-40.5c-51.4-5.301-107.3-24.5-148.2-46.9c-9.3-5.1-19.2,3.4-16,13.7
                                    C27.326,79.523,37.526,152.323,64.126,214.123z"></path>
                                        <path d="M950.627,204.323c-94.601,51.4-203.101,57.7-309.4,69.9c-51.899,6-103.3,13.399-152.201,28.2
                                    c-10.6,3.199-21.199,6.8-31.6,10.8c-30.9,11.8-60.7,27.3-88.8,48c-26.4,19.399-48,42.4-66.2,67.7
                                    c-29,40.399-49.2,86.899-65.8,135.2c-30.3,87.699-44.2,180.899-41.5,273.6c1.1,37.6,1.7,76.7,11,113.4
                                    c2.1,8.1,4.1,18.399,10.4,24.399c8.2,7.9,22.9-0.2,19.6-11.7c-23.8-81.1-9-175.899,23.9-253.399
                                    c37.8-89.101,110.8-142.101,200-174.4c23.7-8.6,47.901-16.1,72.5-23.2c0.5-0.199,1-0.199,1.5-0.199c4.9,0,7,7.199,1.9,9.5
                                    c-29,13.1-57.8,26.3-85.201,40.6c-134.2,70-192.8,233-142.5,233c4.101,0,8.9-1.1,14.4-3.4c77.6-32.1,169.8-32.399,255.1-48.199
                                    c30-5.5,59.2-13,86.601-24.4c108.8-45.4,186.6-134.2,232.899-241c34.5-79.7,47.9-173.8,74-256.601c3.4-10.6-4.1-19.699-13.3-19.699
                                    C955.627,202.323,953.127,202.924,950.627,204.323z"></path>
                                    </g>
                                </svg> :
                                props.objectiveId === "CIRC" ?
                                    <svg className={props.block ? props.block : 'objective-image'} id="Capa_5" enableBackground="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><g>
                                        <path d="m120 362h-88v90h31v-29.13c47.997 55.616 118.041 89.13 192.781 89.13 21.563 0 43.477-2.695 64.219-8.027l-7.5-29.063c-18.252 4.702-37.676 7.09-56.719 7.09-70.959 0-137.584-33.975-179.81-90h44.029z"></path>
                                        <path d="m255.781 30c70.972 0 137.582 33.977 179.804 90h-44.585v30h88v-90h-31v29.028c-47.997-55.56-117.534-89.028-192.219-89.028-21.562 0-44.039 2.695-64.781 8.027l7.5 29.063c18.252-4.702 38.238-7.09 57.281-7.09z"></path>
                                        <path d="m482 256c0 70.968-33.979 136.799-90 179.007v-44.007h-30v88h90v-31h-28.943c55.495-47.99 88.943-117.35 88.943-192 0-21.533-2.695-43.243-8.027-64l-29.063 7.471c4.717 18.31 7.09 37.515 7.09 56.529z"></path>
                                        <path d="m0 256c0 21.533 2.695 43.243 8.027 64l29.063-7.471c-4.717-18.31-7.09-37.515-7.09-56.529 0-70.975 33.979-137.606 90-179.821v44.821h30v-87.979h-90v30.979h29.165c-55.64 47.994-89.165 117.249-89.165 192z"></path>
                                        <path d="m255 121c-74.439 0-135 60.561-135 135s60.561 135 135 135 135-60.561 135-135-60.561-135-135-135zm0 120c24.813 0 45 20.187 45 45 0 19.525-12.503 36.178-29.92 42.393v32.607h-30v-32.553c-17.5-6.17-30.08-22.863-30.08-42.447h30c0 8.271 6.729 15 15 15s15-6.729 15-15-6.729-15-15-15c-24.813 0-45-20.187-45-45 0-19.584 12.58-36.277 30.08-42.447v-32.553h30v32.607c17.417 6.215 29.92 22.868 29.92 42.393h-30c0-8.271-6.729-15-15-15s-15 6.729-15 15 6.729 15 15 15z"></path></g>
                                    </svg> :
                                    props.objectiveId === "CONT" ?
                                        <svg className={props.block ? props.block : 'objective-image'} id="Capa_1" enableBackground="new 0 0 511.226 511.226" viewBox="0 0 511.226 511.226" xmlns="http://www.w3.org/2000/svg"><g>
                                            <path d="m25.178 195.613-25.178 314.72h147.796l-33.57-314.72z"></path><path d="m31.178 120.613-3.6 45h83.448l-4.8-45z"></path>
                                            <path d="m470.156 210.613-80.337 97.121v-97.121h-41.044l-84.685 101.797v-101.797h-41.087l-68.178 82.762 10.372 97.238h46.029v30h-42.829l9.6 90h333.229v-300zm-168.93 210h-60v-30h60zm90 0h-60v-30h60zm90 0h-60v-30h60z"></path><path d="m133.196 90.613 10.099 94.679c15.558-1.388 30.38-7.317 42.579-16.962 13.696 7.981 29.403 12.283 45.351 12.283 24.818 0 48.319-10.251 65.181-27.959 10.783 5.236 22.605 7.959 34.819 7.959 17.581 0 34.645-5.858 48.486-16.39 11.564 7.41 25.024 11.39 39.014 11.39 39.977 0 72.5-32.523 72.5-72.5s-32.523-72.5-72.5-72.5c-12.471 0-24.67 3.229-35.428 9.258-14.39-12.363-32.752-19.258-52.072-19.258-17.536 0-34.544 5.82-48.36 16.291-15.044-10.581-32.949-16.291-51.64-16.291-25.879 0-50.145 11.03-67.101 30.027-8.891-3.31-18.349-5.027-27.899-5.027-38.984 0-71.526 28.036-78.569 65z"></path></g></svg> :
                                        props.objectiveId === "MITG" ?
                                            <svg className={props.block ? props.block : 'objective-image'} version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512">
                                                <g>
                                                    <g>
                                                        <path d="M341.333,288.593V85.333C341.333,38.205,303.128,0,256,0s-85.333,38.205-85.333,85.333v203.259
                                                C144.48,312.03,128,346.091,128,384c0,70.693,57.308,128,128,128s128-57.307,128-128C384,346.091,367.52,312.03,341.333,288.593z
                                                M256,469.333c-47.128,0-85.333-38.205-85.333-85.333c0-24.637,10.441-47.492,28.455-63.615l14.212-12.72V85.333
                                                c0-23.564,19.103-42.667,42.667-42.667s42.667,19.102,42.667,42.667v222.332l14.212,12.72
                                                c18.014,16.123,28.455,38.977,28.455,63.615C341.333,431.128,303.128,469.333,256,469.333z"></path>
                                                    </g>
                                                </g>
                                                <g>
                                                    <g>
                                                        <rect x="234.667" y="170.667" width="42.667" height="256"></rect>
                                                    </g>
                                                </g>
                                                <g>
                                                    <g>
                                                        <circle cx="256" cy="384" r="64"></circle>
                                                    </g>
                                                </g>
                                            </svg> :
                                            <></>
                    : <></>
            }
        </>)
}