import React, { useEffect, useState, useContext } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, numberFilter } from 'react-bootstrap-table2-filter';
import CloseIcon from '@mui/icons-material/Close';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { HiOutlineTrash } from 'react-icons/hi'
import { BiRightArrow } from 'react-icons/bi'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from "react-router-dom";
import { Button, Form } from 'react-bootstrap';
import { AiFillEye } from "react-icons/ai"
import { MdOutlineRefresh, MdFilterListAlt } from "react-icons/md"
import { RiFileExcel2Fill } from "react-icons/ri"
import { FaRegFileAlt, FaFileImport, FaFileExport } from "react-icons/fa"
import { TbFileImport, TbFileExport } from "react-icons/tb"
import Questions from '../../api/questions'
import QuestionsEnded from '../../api/questionsEnded'
import PopOver from '../popOver/popOver'
//import excel from '../../api/exportExcel.xlsx'
import excel from '../../api/PETICION_TEST_VIVIENDA_2020_BLOQ1_20220927_01.csv'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css'
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css'
import { Typeahead } from 'react-bootstrap-typeahead';
import SearchFilter from '../searchFilter/searchFilter'
import SearchFilterBlock from '../searchFilter/searchFilterBlock'
import './table.css';
import { BsFileEarmarkArrowUp, BsFileEarmarkArrowDown } from "react-icons/bs";
import taxoApi from '../../api/taxoApi';
import ModalWarning from '../../commons/modals/modalWarning'
import { Context } from "../../commons/utils/store";
import { useNavigate } from 'react-router-dom';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function TableUpload({ data, sizePage, blockName, handleEv, handleShow, handleSect, handleSingleEv, handleSelectionsBlock, handleHideDetailActive, handleEvv }) {

    const [state, dispatch] = useContext(Context);
    const [clickState, setClick] = useState(false)
    const [clickState2, setClick2] = useState(false)
    const [errorDownload, setErrorDownload] = useState(false)
    const handleErrorDownload = (click) => setErrorDownload(click)
    const navigate = useNavigate();

    const handleClickState = (click) => setClick(click)
    const handleClickState2 = (click) => setClick2(click)

    const [datos, setDatos] = useState(data);
    const [filteredData, setFilteredData] = useState(data);
    const [filter, setFilter] = useState(false)

    const [windowWidth, setWindowWidth] = useState(getWindowDimensions().width);

    //Ancho de la ventana a partir del cual ocultamos columnas y las mostramos como filas
    const hiddingValues = { name: 0, status: 675, id: 600,actividad:1000, sector: 1100, date: 800, nReg: 750, actions: 0 }

    const [hideName, setHideName] = useState();
    const [hideStatus, setHideStatus] = useState();
    const [hideId, setHideId] = useState();
    const [hideSector, setHideSector] = useState();
    const [hideActivity, setHideActivity] = useState()
    const [hideDate, setHideDate] = useState();
    const [hideNReg, setHideNreg] = useState();
    const [hideActions, setHideActions] = useState();
    const [bloquesEnviados, setBloquesEnviados] = useState([])

    useEffect(() => {
        setDatos(data)
        setFilteredData(data)
    }, [data])

    useEffect(() => {

        function handleResize() {
            setWindowWidth(getWindowDimensions().width);
        }
        setHideName(windowWidth < hiddingValues.name);
        setHideStatus(windowWidth < hiddingValues.status);
        setHideId(windowWidth < hiddingValues.id);
        setHideSector(windowWidth < hiddingValues.sector);
        setHideActivity(windowWidth < hiddingValues.actividad)
        setHideDate(windowWidth < hiddingValues.date);
        setHideNreg(windowWidth < hiddingValues.nReg);
        setHideActions(windowWidth < hiddingValues.actions);
        // setHideName(getWindowDimensions().width < hiddingValues.name);
        // setHideStatus(getWindowDimensions().width < hiddingValues.status);
        // setHideId(getWindowDimensions().width < hiddingValues.id);
        // setHideSector(getWindowDimensions().width < hiddingValues.sector);
        // setHideDate(getWindowDimensions().width < hiddingValues.date);
        // setHideNreg(getWindowDimensions().width < hiddingValues.nReg);
        // setHideActions(getWindowDimensions().width < hiddingValues.actions);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [windowWidth]);

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ms-3">
            Mostrando del {from} al {to} de {size} bloques
        </span>
    );

    let size = 7
    if (sizePage !== undefined) {
        size = sizePage
    }
    const pagination = paginationFactory({
        page: 1,
        sizePerPage: size,
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '3', value: 3
        }, {
            text: '7', value: 7
        }, {
            text: '10', value: 10
        }, {
            text: '25', value: 25
        }, {
            text: '50', value: 50
        }, {
            text: 'All', value: filteredData.length
        }],
        lastPageText: '>>',
        firstPageText: '<<',
        nextPageText: '>',
        prePageText: '<',
        alwaysShowAllBtns: true,
        onPageChange: function (page, sizePerPage) {
        },
        onSizePerPageChange: function (page, sizePerPage) {
        }
    });

    const handleEval = (e) => {
        e.preventDefault();
        handleEv()
    }

    const handleTableSearch = (filtered) => {
        setFilteredData(filtered)
    }

    const columns = [
        {
            dataField: 'numRegisters',
            text: 'Oculta',
            hidden: true,
            sort: false
        },
        {
            dataField: 'date',
            headerClasses: 'header-table',
            text: <span>Fecha</span>,
            style: { width: '6rem' },
            hidden: hideDate,
            align: 'left',
            sort: false,
        },
        {
            dataField: 'blockName',
            headerClasses: 'header-table',
            text: <span>Nombre Bloque</span>,
            hidden: hideName,
            sort: false,
            style: hideSector && hideDate && hideNReg && hideId && hideStatus ? { 'width': '9.5rem', 'max-width': '9.5rem' } : {},
            formatter: (value, row) => (
                <>
                    <span className='cell-breakWord'> {value.replace(".csv","")} </span>
                </>
            ),
        },
        {
            dataField: 'sectorName',
            headerClasses: 'header-table',
            text: <span>Sector</span>,
            hidden: hideSector,
            sort: false,
        },
        {
            dataField: 'activityName',
            headerClasses: 'header-table',
            text: <span>Actividad</span>,
            hidden: hideActivity,
            sort: false,
        },
        {
            dataField: 'stateDescription',
            headerClasses: 'header-table',
            text: <span>Estado</span>,
            hidden: hideStatus,
            sort: false,
            formatter: (value, row) => (
                <>
                    <span className={
                        row.status === 3 && row.numKO === 0 ? 'succes me-2' : 
                        ((row.status === 3 && row.numKO > 0) || (row.status === 4))? 'nErr me-2' :
                        (row.status === 5)? 'processing me-2' : 'me-2'
                        }>
                    {row.stateDescription}</span>
                    {(row.status === 0 || row.status === 10 || row.status === 2) &&
                        <span className='pending'> {row.progress === null ? 0 : row.progress}% </span>}
                </>
            ),

        },
        {
            dataField: 'numRegisters',
            headerClasses: 'header-table',
            text: <span>Nº Registros</span>,
            hidden: hideNReg,
            sort: false,
        },
        {
            dataField: 'actions',
            headerClasses: 'header-table',
            text: <span></span>,
            sort: false,
            align: 'center',
            style: { 'width': '8rem', 'min-width': '8rem' },
            hidden: hideActions,
            formatter: (value, row) => (
                <span>
                    <PopOver title={<TbFileImport className='me-2 eye color-secondary play-button cursor-pointer' onClick={() => (handleDownload(row.importFilePath))} />} message={"Fichero de datos de entrada"} />
                    {(row.status === 1 && bloquesEnviados && bloquesEnviados.indexOf(row.blockId) === -1) ?
                        <PopOver title={
                            <button className='play-button btn-actions' onClick={() => evaluateBlock(row.blockId)}>
                                <BiRightArrow className='eye color-secondary' />
                            </button>} message={"Evaluar lote"} /> :
                        <BiRightArrow className='eye color-gray cursor-not' />
                    }
                    <PopOver title={
                        <button className='play-button' onClick={() => handleDelete(row.blockId, row)}>
                            <HiOutlineTrash className='eye color-secondary' />
                        </button>} message={"Eliminar lote"} />
                    {row.status === 2 ?
                        <AiFillEye disabled={true} className='eye color-gray play-button cursor-not' /> :
                        <PopOver title={<AiFillEye className='eye color-secondary play-button cursor-pointer' onClick={() => { handleShow(row.blockName, true, row.blockId); handleSect(row.sectorName) }} />} message={"Mostrar actividades evaluadas"} />}

                    {((row.resultFilePath !== null && row.resultFilePath !== '') && (row.status === 3 || row.status === 4)) ? //Completado correctamente o con errores
                        <PopOver style={{ marginLeft: "5px" }} title={<TbFileExport style={{ marginLeft: "5px" }} className='me-2 eye color-secondary play-button cursor-pointer' onClick={() => (handleDownload(row.resultFilePath))} />} message={"Fichero de datos de salida"} /> :
                        <TbFileExport style={{ marginLeft: "5px" }} disabled={true} className='me-2 eye color-gray play-button cursor-not' />}
                </span>
            )

        },
    ];

    const [block, setBlock] = useState();
    const [row, setRow] = useState();

    const handleDownload = (path) => {
        try {
            // endpoint points to Lambda that generates workbook and presigned URL
            dispatch({ type: "SET_HIDDEN", payload: false });
            taxoApi.getTaxoDownloadFile(path,"",0,navigate).then((response) => {
                if (response.status === '200') {
                    window.location.assign(response.data)
                } else {
                    setErrorDownload(true)
                }
                dispatch({ type: "SET_HIDDEN", payload: true });
            })
        }
        catch (err) {
            console.error(err)
        }
    }

    const deleteBlock = () => {
        dispatch({ type: "SET_HIDDEN", payload: false });
        taxoApi.getTaxoDeleteBatch(block).then((response) => {
            if (response.data.status === '200') {
                setDatos(datos.filter(item => item.blockId !== row.blockId))
                setFilteredData(filteredData.filter(item => item.blockId !== row.blockId))
                handleHideDetailActive(row.blockId)
            }
            else {
                setClick2(true)
            }
            dispatch({ type: "SET_HIDDEN", payload: true });
        })
    }
    const handleDelete = (block, param) => {
        setBlock(block);
        setRow(param);
        setClick(true);
    }

    const evaluateBlock = (idBloque) => {
        let bloquesBloqued = bloquesEnviados
        bloquesBloqued.push(idBloque)
        setBloquesEnviados(bloquesBloqued)

        let aux = datos
        bloquesBloqued.forEach(blq=>{
            let ind = aux.indexOf(datos.filter(e=>e.blockId === blq)[0])
            let aux2 = datos.filter(e=>e.blockId === blq)
            let aux3 = {...aux2[0],status: 5}
            aux3 = {...aux3,stateDescription: "Procesando"}
            aux[ind] = aux3 
        })
        setDatos(aux)
        setFilteredData(aux)

        dispatch({ type: "SET_HIDDEN", payload: false });
        dispatch({ type: "SET_HIDDEN", payload: true });
        /* taxoApi.getTaxoDisableEvaluation(idBloque).then((response) => {
            console.log("miresponse",response)
        }) */

        taxoApi.evaluateBlock(idBloque).then((response) => {
            handleRefresh()
            setBloquesEnviados([])
        }) 
    }

    const defaultSorted = [{
        dataField: 'date',
        order: 'desc'
    }];

    const expandRow = {
        renderer: row => (
            <div className='expandBox'>
                <div className='data-table-initial'>
                    <div className='sentence-initial-table col-5'>
                        <strong>Nombre: </strong>
                    </div>
                    <div className='w-auto data-initial-table data-cell-breakWord expandDataValue'> {row.blockName} </div>
                </div>
                <div className='data-table-initial'>
                    <div className='sentence-initial-table col-5'>
                        <strong>Fecha inicio: </strong>
                    </div>
                    <div className='w-auto data-initial-table expandDataValue'>
                        {row.beginDate}
                    </div>
                </div>
                <div className='data-table-initial'>
                    <div className='sentence-initial-table col-5'>
                        <strong>Fecha fin: </strong>
                    </div>
                    <div className='w-auto data-initial-table expandDataValue'>
                        {row.endDate}
                    </div>
                </div>
                <div className='data-table-initial'>
                    <div className='sentence-initial-table col-5'>
                        <strong>Duración: </strong>
                    </div>
                    <div className='w-auto data-initial-table expandDataValue'>
                        {row.durationSecs} {row.durationSecs !== null ? 'sg' : ''}
                    </div>
                </div>
                <div className='data-table-initial'>
                    <div className='sentence-initial-table col-5'>
                        <strong>Log: </strong>
                    </div>
                    <div className='w-auto data-initial-table data-cell-breakWord expandDataValue'>
                        {(row.status === 3 || row.status === 2) && <span className='me-2'>Se {row.numOk + row.numKO === 1 ? 'ha ' : 'han '}evaluado {row.numOk + row.numKO} registros, {row.numOk} se {row.numOk === 1 ? 'ha ' : 'han '}realizado correctamente y  <span className='nErr'>{row.numKO}</span> {row.numKO === 1 ? 'ha ' : 'han '}tenido error</span>}
                        {(row.status !== 3 && row.status !== 2) && <span className='me-2'>Se {row.numOk + row.numKO === 1 ? 'ha ' : 'han '}evaluado {row.numOk + row.numKO} registros</span>}
                    </div>
                </div>
                {hideStatus &&
                    <div className='data-table-initial'>
                        <div className='sentence-initial-table col-5'>
                            <strong>Estado: </strong>
                        </div>
                        <div className='w-auto data-initial-table data-cell-breakWord expandDataValue'>
                        <>
                            <span className={
                                row.status === 3 && row.numKO === 0 ? 'succes me-2' : 
                                ((row.status === 3 && row.numKO > 0) || (row.status === 4))? 'nErr me-2' :
                                (row.status === 5)? 'processing me-2' : 'me-2'
                                }>
                            {row.stateDescription}</span>
                            {(row.status === 0 || row.status === 10 || row.status === 2) &&
                                <span className='pending'> {row.progress === null ? 0 : row.progress}% </span>}
                        </>
                        </div>
                    </div>}
                {hideSector &&
                    <div className='data-table-initial'>
                        <div className='sentence-initial-table col-5'>
                            <strong>Sector: </strong>
                        </div>
                        <div className='w-auto data-initial-table data-cell-breakWord expandDataValue'>
                            <span>{row.sectorName}</span>
                        </div>
                    </div>}
                {hideActivity &&
                    <div className='data-table-initial'>
                        <div className='sentence-initial-table col-5'>
                            <strong>Actividad: </strong>
                        </div>
                        <div className='w-auto data-initial-table data-cell-breakWord expandDataValue'>
                            <span>{row.activityName}</span>
                        </div>
                    </div>}
                {hideDate &&
                    <div className='data-table-initial'>
                        <div className='sentence-initial-table col-5'>
                            <strong>Fecha alta: </strong>
                        </div>
                        <div className='w-auto data-initial-table data-cell-breakWord expandDataValue'>
                            <span>{row.date}</span>
                        </div>
                    </div>}
                {hideNReg &&
                    <div className='data-table-initial'>
                        <div className='sentence-initial-table col-5'>
                            <strong>Nº Registros: </strong>
                        </div>
                        <div className='w-auto data-initial-table data-cell-breakWord expandDataValue'>
                            <span>{row.numRegisters}</span>
                        </div>
                    </div>}
                {hideActions &&
                    <div className='data-table-initial'>
                        <div className='sentence-initial-table col-5'>
                            <strong>Acciones: </strong>
                        </div>
                        <div className='w-auto data-initial-table data-cell-breakWord expandDataValue'>
                            <span>
                                <PopOver title={<TbFileImport className='me-2 eye color-secondary play-button cursor-pointer' onClick={() => (handleDownload(row.importFilePath))} />} message={"Fichero de datos de entrada"} />
                                {(
                                    (row.status === 1)
                                )?
                                    <PopOver title={
                                        <button className='play-button btn-actions' onClick={() => evaluateBlock(row.blockId)}>
                                            <BiRightArrow className='eye color-secondary' />
                                        </button>} message={"Evaluar lote"} /> 
                                        :
                                    <BiRightArrow className='eye color-gray cursor-not' />
                                }
                                <PopOver title={
                                    <button className='play-button' onClick={() => handleDelete(row.blockId, row)}>
                                        <HiOutlineTrash className='eye color-secondary' />
                                    </button>} message={"Eliminar lote"} />
                                {row.status === 2 ?
                                    <AiFillEye disabled={true} className='eye color-gray play-button cursor-not' /> :
                                    <PopOver title={<AiFillEye className='eye color-secondary play-button cursor-pointer' onClick={() => { handleShow(row.blockName, true, row.blockId); handleSect(row.sectorName) }} />} message={"Mostrar actividades evaluadas"} />}

                                {row.status === 3 || row.status === 4 ? //Completado correctamente o con errores
                                    <PopOver style={{ marginLeft: "5px" }} title={<TbFileExport style={{ marginLeft: "5px" }} className='me-2 eye color-secondary play-button cursor-pointer' onClick={() => (handleDownload(row.resultFilePath))} />} message={"Fichero de datos de salida"} /> :
                                    <TbFileExport style={{ marginLeft: "5px" }} disabled={true} className='me-2 eye color-gray play-button cursor-not' />}
                            </span>
                        </div>
                    </div>}

            </div>
        ),
        showExpandColumn: true,
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            if (isAnyExpands) {
                return <b></b>;
            }
            return <b></b>;
        },
        expandColumnRenderer: ({ expanded }) => {
            if (expanded) {
                return (
                    <b className='cursor-pointer'>-</b>
                );
            }
            return (
                <b className='cursor-pointer'>+</b>
            );
        }
    };

    var queries = []
    const handleRefresh = () => {
        dispatch({ type: "SET_HIDDEN", payload: false });
        setFilter(false)
        taxoApi.getTaxoBloques().then((response, navigate) => {
            queries = []
            response.data.forEach(item => {
                if(item.status === 1 && bloquesEnviados && bloquesEnviados.indexOf(item.blockId) !== -1){
                    let aux = {...item,status: 5}
                    aux = {...aux,stateDescription: "Procesando"}
                    item = aux
                }
                queries.push(item)
            })
            setDatos(queries)
            setFilteredData(queries)
            queries = []
            dispatch({ type: "SET_HIDDEN", payload: true });
        })

    }
    return (
        <>
            <div className='principal-title'>
                <span className='section-title d-inline'>{blockName && blockName != "" && filteredData.length < 10 ? `Resumen ` : 'Resumen '}</span>
                <div className='actions'>
                    <PopOver title={<div onClick={() => (setFilter(old => !old))} className='circle-icon me-2'><MdFilterListAlt className='color-icon eye' /></div>} message={"Filtrar"} />
                    <PopOver title={<div onClick={() => handleRefresh()} className='circle-icon me-2'><MdOutlineRefresh className='color-icon eye' /></div>} message={"Actualizar"} />
                    {/* <PopOver title={<div className='circle-icon me-2'><PlayArrowIcon onClick={handleEval} className='color-icon' /></div>} message={"Evaluar todos"} /> */}
                    {/* <PopOver title={<div className='circle-icon me-2'><a href={excel} target="_blank" rel="noopener noreferrer" download="Excel_Multiples_Consultas"><RiFileExcel2Fill style={{ marginBottom: '0.15rem' }} className='color-icon eye' /></a></div>} message={"Exportar Excel"} /> */}
                </div>
            </div>

            {filter && <SearchFilterBlock data={datos} pDataType="1" handleTable={handleTableSearch} />}

            <div className="section-content">
                {
                    (hideSector === null || hideSector === undefined) ? '' :
                        (filteredData && filteredData.length === 0) ? <div className='no-result'>No hay resultados para los criterios de búsqueda seleccionados</div> :
                            <BootstrapTable responsive
                                //id='batch-table'
                                classes='table-basic table-hover table-borderless'
                                bootstrap4
                                keyField='blockId'
                                data={filteredData}
                                columns={columns}
                                // defaultSorted={defaultSorted}
                                pagination={pagination}
                                expandRow={expandRow}
                            />
                }
                <ModalWarning header={<span className='section-title-modal'>Eliminar bloque</span>} message={"¿Está seguro de que quiere eliminar el bloque?"} show={clickState} handle={handleClickState} handleContinue={deleteBlock} > </ModalWarning>
                <ModalWarning header={<span className='section-title-modal'>Error</span>} message={"No se ha podido borrar el bloque"} show={clickState2} handle={handleClickState2} onlyButtonContinue> </ModalWarning>
                <ModalWarning header={<span className='section-title-modal'>Error</span>} message={"Generando fichero de salida"} show={errorDownload} handle={handleErrorDownload} onlyButtonContinue> </ModalWarning>
            </div>
        </>
    );
}


export default TableUpload;