import React, { useEffect, useState, useContext } from 'react';
import ComponentSteps from './componentSteps/componentSteps'
import { useParams } from "react-router-dom";
import taxoApi from '../../api/taxoApi';
import Questions from '../../api/questions'
import QuestionsEnded from '../../api/questionsEnded'
import QuestionsEnergy from '../../api/questionsEnergy'
import QuestionsEnergyEnded from '../../api/questionsEnergyEnded'
import QuestionsTransport from '../../api/questionsTransport'
import QuestionsTransportEnded from '../../api/questionsTransportEnded'
import Responses from '../../api/responses'
import ResponsesPdf from '../../api/responsesPdf'
import ResponsesActive from '../../api/responsesActive'
import './evaluation.css'
import { Context } from "../../commons/utils/store";
import ButtonNewAction from '../../commons/buttonNewAction/buttonNewAction';
import ModalWarning from '../../commons/modals/modalWarning';
import { MdCancel } from "react-icons/md"
import InputDataEvaluation from '../../api/inputDataEvaluation'
import InputDataEvaluationA from '../../api/inputDataEvaluationA'
import InputDataEvaluationTransport from '../../api/inputDataEvaluationTransport'
import InputDataEvaluationEnergy from '../../api/inputDataEvaluationEnergy'
import InputDataEvaluationConstruction from '../../api/inputDataEvaluationConstruction'
import InputDataEvaluationConstructionM from '../../api/inputDataEvaluationConstructionM'
import { Button } from 'react-bootstrap';
import { BsCheckCircleFill } from "react-icons/bs"
import { GiSandsOfTime } from "react-icons/gi";
import { useNavigate } from 'react-router-dom';

export default function Evaluation() {
  let params = useParams();


  const navigate = useNavigate();

  const [state, dispatch] = useContext(Context);
  const [esBloque,setEsBloque] = useState(0);
  const [nChanges, setNChanges] = useState([])
  const [clickState, setClick] = useState(false)
  const [page, setPage] = useState(-1)
  const [evaluationId, setEvaluationId] = useState("")
  const [errorDownload, setErrorDownload] = useState(false)
  const handleErrorDownload = (click) => setErrorDownload(click)
  const [aligmentResult,setAligmentResult] = useState("")

  const handleSetEvaluationId = (click) => setEvaluationId(click)

  const handleSave = (pIdEvaluationType, v) => {
    if (v) {
      if (Object.values(nChanges).length > 0 && pIdEvaluationType in nChanges) { // 
        //let newChangesArray = nChanges.filter(item => item.idEvaluationType !== pIdEvaluationType);
        //delete nChanges[pIdEvaluationType]
        const { [pIdEvaluationType]: { }, ...rest } = nChanges
        setNChanges(rest);
      }
    } else {
      setNChanges([]);
    }
  }

  const handleChanges = (sumar, pIdEvaluationType) => {
    let increment = 0;
    if (nChanges[pIdEvaluationType] != undefined) {
      increment = nChanges[pIdEvaluationType].num + sumar;
      let x = { num: increment, idEvaluationType: pIdEvaluationType }
      setNChanges({ ...nChanges, [pIdEvaluationType]: x })
    } else {
      let x = { [pIdEvaluationType]: { num: sumar, idEvaluationType: pIdEvaluationType } }
      setNChanges({ ...nChanges, [pIdEvaluationType]: { num: sumar, idEvaluationType: pIdEvaluationType } })
    }
  }

  const handleClick = (redir, page) => {
    setClick(redir);
    setPage(page)
  }
  const handleClickState = (click) => setClick(click)

  const [dat, setDat] = useState([])
  let datos = []
  const [resp, setResp] = useState([])
  let responses = []
  const [stateEvaluation, setStateEvaluation] = useState(0)

  const handleUpdateState = (stateEvalutaion) => {
    setStateEvaluation(stateEvalutaion)
    dat[0].state = stateEvalutaion
    setDat(dat)
  }

  const handlerReopenEvaluation = () => {
    taxoApi.getTaxoReopenEvaluation(dat[0])
      .then((response) => {
        if (response.status === 'OK') {
          setStateEvaluation(1)
          dat[0].state = 1
          setDat(dat)
        }
      })
  }

  const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  const checkBloque = (evaluationId) => {
      taxoApi.getTaxoCheckBloque(evaluationId,navigate).then((response) => {
        if (response.response === 1) {
            setEsBloque(1)
        }
    })
  }

  const calculateAligment = (data) => {    
    if(data["evaluationTypes"]){
      let cs = data["evaluationTypes"]["CS"]["resultCode"] == "OK"
      let dnshaux = Object.values(data["evaluationTypes"]["DNSH"]["evaluationAux"])
      let dnsh = dnshaux.some(e => e["resultCode"] == "KO")
      let salv = data["evaluationTypes"]["SALV"]["resultCode"] == "OK"
      setAligmentResult(cs && !dnsh && salv) 
    }
  }

  useEffect(() => {
    dispatch({ type: "SET_HIDDEN", payload: false });
    if (params.consulta !== undefined) {
      checkBloque(params.consulta)
      taxoApi.getObjectiveActivityResponse(params.actividad, params.objetivo, params.consulta, navigate)
        .then((response) => {
          let state = -1
          response.data.forEach(item => {
            datos.push(item)
            setStateEvaluation(item.state)
            state = item.state
          })

          if (Object.entries(response.data2).length > 0) {
            response.data2.forEach(item => {
              responses.push(item)
            })
          }
          setDat(datos)
          setResp(responses)
          if(state == 2){
            /* let cs = response.data2[0]["evaluationTypes"]["CS"]["resultCode"] == "OK"
            let dnshaux = Object.values(response.data2[0]["evaluationTypes"]["DNSH"]["evaluationAux"])
            let dnsh = dnshaux.some(e => e["resultCode"] == "KO")
            let salv = response.data2[0]["evaluationTypes"]["SALV"]["resultCode"] == "OK"
            setAligmentResult(cs && !dnsh && salv)  */
            calculateAligment(response.data2[0])
          }

          dispatch({ type: "SET_HIDDEN", payload: true });
        })
    }
    else {
      taxoApi.getObjectiveActivity(params.actividad, params.objetivo, params.operacion, navigate)
      .then((response) => {   
        response.data.forEach(item => {
          datos.push(item)
          setStateEvaluation(item.state)
        })
        
          let data = datos[0]
          if(data.idObjective == "MITG" && (data.idActivity == 7007 || data.idActivity == 6005)){
              // 1000 1001 1002
              let salv = Object.values(data.evaluationTypes).filter(e=>e.idEvaluationType == "SALV")[0]
              let pregunta1 = { ...salv.questionList[1000], response: `SI` }
              let pregunta2 = { ...salv.questionList[1001], response: `SI` }
              let pregunta3 = { ...salv.questionList[1002], response: `SI` }
              let preguntas = { ...salv.questionList, [1000]: pregunta1 }
              preguntas = { ...preguntas, [1001]: pregunta2 }
              preguntas = { ...preguntas, [1002]: pregunta3 }
              salv = {...salv,questionList: preguntas}
              let evaTypes = {...data.evaluationTypes,SALV:salv}
              datos[0] = {...data,evaluationTypes:evaTypes}
              //defaultValue={((props.idAct == 7007 || props.idAct == 6005) && props.tab == "SALV")?"SI":""}
              
          }


          setDat(datos)
          dispatch({ type: "SET_HIDDEN", payload: true });
        })
    }    

  }, [])

  return (
    <React.Fragment>
      <div>
        <div className='section-block first-block eva'>
          <div className='principal-title'>
            <span className='header-title d-inline evaluationTitle-minwidth'>Evaluación individual</span>            
            <div className='state-end'>
              {(stateEvaluation === '0' || stateEvaluation === 0) ?
                <Button className='button-state width-10rem mid-button align-items-center evaluationAligment-badge'><span className='badge text-bg-initial me-2'>1</span><span className='me-2 d-none d-sm-inline'>Inicial</span></Button> :
                (stateEvaluation === '1' || stateEvaluation === 1) ?
                  <Button className='button-state width-10rem mid-button align-items-center evaluationAligment-badge'><span className='badge text-bg-pending me-2'>1</span><span className='me-2 d-none d-sm-inline'>Activa</span></Button> :
                  (stateEvaluation === '2' || stateEvaluation === 2) ?
                    <>
                      <Button className='button-state width-10rem mid-button align-items-center evaluationAligment-badge'><span className='badge text-bg-success me-2'>1</span><span className='me-2 d-none d-sm-inline'>Finalizada</span></Button>
                      {/* <Button className='button-state width-8rem mid-button align-items-center'><span className='d-none d-sm-inline'>{aligmentResult ? "Alineada":"No alineada"}</span></Button> */}
                      {aligmentResult ?
                          <Button className='button-state align-items-center width-10rem evaluationAligment-badge'>
                            <BsCheckCircleFill className='color-succes pb-0 me-2 text-bg' />
                            <span className='d-none d-sm-inline'>Alineada</span>
                          </Button> :
                          <Button className='button-state align-items-center width-10rem evaluationAligment-badge'>
                            <MdCancel className='color-fail pb-0 me-2 text-bg' />
                            <span className='d-none d-sm-inline'>No alineada</span>
                          </Button> 
                      }
                    </>
                    :
                    <Button className='button-state width-8rem mid-button align-items-center'><span className='badge text-bg-ko me-2'>1</span><span className='me-2'>Error</span></Button>
              }
                        
              {esBloque === 0 && <ButtonNewAction act={params.actividad} nChanges={Object.values(nChanges)} handleClick={handleClick} isEvaluationPage={true} state={stateEvaluation} onHandleStateEvaluation={handlerReopenEvaluation} evaluationId={params.consulta !== undefined?params.consulta:evaluationId} handleErrorDownload={handleErrorDownload}/>}
            </div>
          </div>
        </div>
        {/* <div className='w-100 h-100 d-flex'><Spinner hidden={hideSpinner} animation="border" variant="primary" /></div> */}
        {((dat && dat[0] && dat.length !== 0)) &&
          <>
            <ModalWarning header={<span className='section-title-modal'>Cambios sin guardar</span>} message={"Hay cambios sin guardar, si continúa se perderán."} show={clickState} url={page} handle={handleClickState}></ModalWarning>
            <ComponentSteps data={dat[0]} data2={resp[0]} dateOp={params.date} nameOp={params.name} descOp={params.desc} onHandleChanges={handleChanges} onHandleClickState={handleClickState} onHandleStateEvaluation={handleUpdateState} onHandleSave={handleSave} handleSetEvaluationId={handleSetEvaluationId} evaluationId={params.consulta !== undefined?params.consulta:evaluationId} handleErrorDownload={handleErrorDownload} esBloque={esBloque} calculateAligment={calculateAligment}/>
            <ModalWarning header={<span className='section-title-modal'>Aviso</span>} message={"No se ha podido descargar el fichero"} show={errorDownload} handle={handleErrorDownload} onlyButtonContinue> </ModalWarning>
          </>
        }
      </div>
    </React.Fragment>);
}
